import {DriverInfo} from "./response.model";

export class CustomerEditRequest {
  policyNumber: string;
  insuredDetails: InsuredDetails;
  driverDetails: DriverDetails;
}

export class InsuredDetails {
  phoneNumber: string;
  email: string;
  dateOfBirth: string;
  sameInsuredAddress: boolean;
  insureType: string;
  taxIdNumber: string;
  taxBranchCode: string;
  billingInsuredType: string;
  billingTaxIdNumber: string;
  billingTaxBranchCode: string;
  postAddress: PostAddressRequest;
  billingAddress: BillingAddressRequest;
}

export class DriverDetails {
  mainDriver: DriverInfo;
  additionalDriver: DriverInfo;
  driver3: DriverInfo;
  driver4: DriverInfo;
  driver5: DriverInfo;
}

export class PostAddressRequest {
  tumbon: string;
  tumbonName: string;
  address1: string;
  address2: string;
  district: string;
  districtCode: string;
  province: Province;
  provinceCode: string;
  postalCode: string;
}

export  class BillingAddressRequest {
  salutation: string;
  name: string;
  tumbon: string;
  tumbonName: string;
  address1: string;
  address2: string;
  district: string;
  districtCode: string;
  province: Province;
  provinceCode: string;
  postalCode: String;
  taxIdNumber: string;
  taxBranchCode: string;

}

export class Province {
  id: string;
  region: string
  nameEng: string;
  tidCode: string;
  nameThai: string;
  aegisCode: string;
  provinceCode: string;
}

export class UpdatePhoneNumberRequest {
  idCard: string;
  passport: string;
  companyTaxId: string;
  vehicleNumber: string;
  phoneNumber: string;
}

export class UpdateOtherDetailsRequest {
  idCard: string;
  passport: string;
  companyTaxId: string;
  policyReceiveMethod: string;
  coverageType: string;
  policyNumber: string;
}

export class RenwalQuotationRequest {
  expiringPolicyNumber: String;
	customerEmail: String;
	agentCode: String;
}
