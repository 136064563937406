import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {environment} from 'src/environments/environment';
import {StorageType} from '../../enum/storage-type.enum';
import {AppConstants} from '../../constants/app-constants.model';
import {StorageService} from '../storage/storage.service';
import {map} from 'rxjs/operators';
import {
  CustomerAuthenticatedResponse,
  CustomerEditResponse,
  OfferResponse,
  OTPResponse, PaymentResponse, PaymentStatus,
  PoliciesResponse, UpdateOtherDetailsResponse, UpdatePhoneNumberResponse,
  ValidateCustomerResponse,
  VerifyOTPResponse, Status
} from '../../models/response.model';
import {CustomerEditRequest, UpdateOtherDetailsRequest, UpdatePhoneNumberRequest, RenwalQuotationRequest} from "../../models/request.model";

@Injectable({
  providedIn: 'root'
})
export class PortalApiService {

  private apiBaseUrl: string = environment.apiBaseUrl;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService
  ) {
  }

  private validateCustomerResponse = new BehaviorSubject<ValidateCustomerResponse>(new ValidateCustomerResponse());


  updateValidateCustomerResponse(validateCustomerResponse: ValidateCustomerResponse): void {
    this.validateCustomerResponse.next(validateCustomerResponse);
  }

  validateCaptcha(verifyToken: string): Observable<boolean> {
    // const request = {
    //   verifyToken,
    //   source: AppConstants.CAPTCHA_SOURCE
    // };

    const request = {
      response: verifyToken
    };

    return this.httpClient.post<boolean>
    (`${this.apiBaseUrl}${AppConstants.VALIDATE_CAPTCHA_ENDPOINT}`, request);
  }

  authenticateUser(idCard: string, passport: string, companyTaxId: string): Observable<ValidateCustomerResponse> {
    const httpOptions = {
      observe: 'response' as 'response'
    };
    const request = {
      idCard,
      passport,
      companyTaxId
    };

    return this.httpClient.post<ValidateCustomerResponse>
    (`${this.apiBaseUrl}${AppConstants.AUTHENTICATE_ENDPOINT}`, request, httpOptions)
      .pipe(
        map((httpResponse: HttpResponse<ValidateCustomerResponse>) => {
          if (httpResponse.body.status === 'Success') {
            const token = httpResponse.headers.get('authorization');
            const xsrf_token = httpResponse.headers.get('xsrf-token');
            this.updateValidateCustomerResponse(httpResponse.body);
            this.storageService.store(StorageType.SESSION, AppConstants.STORAGE_KEY_JWT_TOKEN, token);
            this.storageService.store(StorageType.SESSION, AppConstants.STORAGE_KEY_IDCARD, request.idCard);
            this.storageService.store(StorageType.SESSION, AppConstants.STORAGE_KEY_PASSPORT, passport);
            this.storageService.store(StorageType.SESSION, AppConstants.STORAGE_KEY_COMPANY_TAX_ID, companyTaxId);
            this.storageService.store(StorageType.SESSION, AppConstants.STORAGE_KEY_XSRF_TOKEN, xsrf_token);
            this.storageService.store(StorageType.SESSION, AppConstants.STORAGE_CUSTOMER_NAME, httpResponse.body?.customer?.name);
            this.storageService.store(StorageType.SESSION, AppConstants.STORAGE_CUSTOMER_EMAIL, httpResponse.body?.customer?.email);
            this.storageService.store(StorageType.SESSION, AppConstants.STORAGE_CUSTOMER_PHONE_NUMBER, httpResponse.body?.customer?.phoneNumber);
          }
          return httpResponse.body;
        }));
  }

  sendOtp(idCard: string, passport: string, reference: string, companyTaxId: string): Observable<OTPResponse> {
    const httpOptions = {
      observe: 'response' as 'response'
    };
    const request = {
      idCard,
      passport,
      reference,
      companyTaxId
    };

    return this.httpClient.post<OTPResponse>
    (`${this.apiBaseUrl}${AppConstants.OTP_SEND}`, request, httpOptions)
      .pipe(
        map((httpResponse: HttpResponse<OTPResponse>) => {
          return httpResponse.body;
        }));
  }

  verifyOtp(idCard: string, passport: string, companyTaxId: string, otp: string): Observable<VerifyOTPResponse> {
    const httpOptions = {
      observe: 'response' as 'response'
    };
    const request = {
      idCard,
      passport,
      companyTaxId,
      otp
    };

    return this.httpClient.post<VerifyOTPResponse>
    (`${this.apiBaseUrl}${AppConstants.OTP_VERIFY}`, request, httpOptions)
      .pipe(
        map((httpResponse: HttpResponse<VerifyOTPResponse>) => {
          return httpResponse.body;
        }));
  }

  policies(idCard: string, passport: string, companyTaxId: string): Observable<PoliciesResponse> {
    const httpOptions = {
      observe: 'response' as 'response'
    };
    const request = {
      idCard,
      passport,
      companyTaxId
    };

    return this.httpClient.post<PoliciesResponse>
    (`${this.apiBaseUrl}${AppConstants.POLICIES}`, request, httpOptions)
      .pipe(
        map((httpResponse: HttpResponse<PoliciesResponse>) => {
          return httpResponse.body;
        }));
  }

  offer(idCard: string, passport: string, companyTaxId: string, expiringPolicyNumber: string): Observable<OfferResponse> {
    const httpOptions = {
      observe: 'response' as 'response'
    };
    const request = {
      idCard,
      passport,
      companyTaxId,
      expiringPolicyNumber
    };

    return this.httpClient.post<OfferResponse>
    (`${this.apiBaseUrl}${AppConstants.OFFER}`, request, httpOptions)
      .pipe(
        map((httpResponse: HttpResponse<OfferResponse>) => {
          return httpResponse.body;
        }));
  }

  paymentStatus(idCard: string, passport: string,companyTaxId: string, paymentUuid: string): Observable<PaymentStatus> {
    const httpOptions = {
      observe: 'response' as 'response'
    };
    const request = {
      idCard,
      passport,
      companyTaxId,
      paymentUuid
    };

    return this.httpClient.post<PaymentStatus>
    (`${this.apiBaseUrl}${AppConstants.PAYMENT_STATUS}`, request, httpOptions)
      .pipe(
        map((httpResponse: HttpResponse<PaymentStatus>) => {
          return httpResponse.body;
        }));
  }

  initiatePayment(idCard: string, passport: string, companyTaxId: string,
                  policyNumber: string, isSpecialOffer: string, paymentOption: string): Observable<PaymentResponse> {
    const httpOptions = {
      observe: 'response' as 'response'
    };

    const request = {
      idCard,
      passport,
      companyTaxId,
      policyNumber,
      isSpecialOffer,
      paymentOption
    };

    return this.httpClient.post<PaymentResponse>
    (`${this.apiBaseUrl}${AppConstants.PAYMENT_TRANSACTION}`, request, httpOptions)
      .pipe(
        map((httpResponse: HttpResponse<PaymentResponse>) => {
          return httpResponse.body;
        }));
  }

  editCustomer (request: CustomerEditRequest) {
    const httpOptions = {
      observe: 'response' as 'response'
    };

    return this.httpClient.put<CustomerEditResponse>
    (`${this.apiBaseUrl}${AppConstants.EDIT_CUSTOMER}`, request, httpOptions)
      .pipe(
        map((httpResponse: HttpResponse<CustomerEditResponse>) => {
          return httpResponse.body;
        }));
  }

  updatePhoneNumber (request: UpdatePhoneNumberRequest) {
    const httpOptions = {
      observe: 'response' as 'response'
    };

    return this.httpClient.post<UpdatePhoneNumberResponse>
    (`${this.apiBaseUrl}${AppConstants.UPDATE_PHONE_NUMBER}`, request, httpOptions)
      .pipe(
        map((httpResponse: HttpResponse<UpdatePhoneNumberResponse>) => {
          return httpResponse.body;
        }));
  }

  updateOtherDetails (request: UpdateOtherDetailsRequest) {
    const httpOptions = {
      observe: 'response' as 'response'
    };

    return this.httpClient.post<UpdateOtherDetailsResponse>
    (`${this.apiBaseUrl}${AppConstants.UPDATE_OTHERS}`, request, httpOptions)
      .pipe(
        map((httpResponse: HttpResponse<UpdateOtherDetailsResponse>) => {
          return httpResponse.body;
        }));
  }

  sendRenewalQuotationEmail (request: RenwalQuotationRequest) {
    const httpOptions = {
      observe: 'response' as 'response'
    };
    
    return this.httpClient.post<Status>
    (`${this.apiBaseUrl}${AppConstants.RENEWAL_QUOTATION}`, request, httpOptions)
      .pipe(
        map((httpResponse: HttpResponse<Status>) => {
          return httpResponse.body;
        }));
  }

  checkCustomerAuthenticated(idCard: string, passport: string, companyTaxId: string) : Promise<boolean>{
    const httpOptions = {
      observe: 'response' as 'response'
    };
    const request = {
      idCard,
      passport,
      companyTaxId
    };

    return new Promise((resolve, reject) => {
      this.httpClient.post<CustomerAuthenticatedResponse>
      (`${this.apiBaseUrl}${AppConstants.CHECK_CUSTOMER_AUTHENTICATED}`, request, httpOptions)
        .pipe(
          map((httpResponse: HttpResponse<CustomerAuthenticatedResponse>) => {
            return httpResponse.body;
          }))
        .subscribe((response) => {
            console.log('CHECK_CUSTOMER_AUTHENTICATED response ', response);
            if (response?.statusCode === 'true') {
              console.log('come true');
              resolve(true);

            } else {
              console.log('come false');
              resolve(false);
            }
          },
          err => {
            console.log('come error');
            resolve(false);
          });
    });
  }

  updateCustomerAuthenticated(idCard: string, passport: string, companyTaxId: string, authenticated: boolean) : Promise<boolean>{
    const httpOptions = {
      observe: 'response' as 'response'
    };
    const request = {
      idCard,
      passport,
      companyTaxId,
      authenticated
    };

    return new Promise((resolve, reject) => {
      this.httpClient.post<CustomerAuthenticatedResponse>
      (`${this.apiBaseUrl}${AppConstants.UPDATE_CUSTOMER_AUTHENTICATED}`, request, httpOptions)
        .pipe(
          map((httpResponse: HttpResponse<CustomerAuthenticatedResponse>) => {
            return httpResponse.body;
          }))
        .subscribe((response) => {
            if (response) {
              resolve(true);

            } else {
              resolve(false);
            }
          },
          err => {
            resolve(false);
          });
    });
  }

  updateCustomerAuthenticatedAsync(idCard: string, passport: string, companyTaxId: string, authenticated: boolean){
    const httpOptions = {
      observe: 'response' as 'response'
    };
    const request = {
      idCard,
      passport,
      companyTaxId,
      authenticated
    };

    return this.httpClient.post<CustomerAuthenticatedResponse>
    (`${this.apiBaseUrl}${AppConstants.UPDATE_CUSTOMER_AUTHENTICATED}`, request, httpOptions)
      .pipe(
        map((httpResponse: HttpResponse<CustomerAuthenticatedResponse>) => {
          return httpResponse.body;
        }));
  }

}
