<app-header></app-header>
<div *ngIf="!isLoading, else spinner">
  <div class="wrapper" [class.Font-Thai]="!isEnglishSelected">
    <figure class="mb-4 margin-left-right-zero">
      <img alt="Tesla Cars" src="assets/tesla-cars-banner.png" class="img-fluid">
    </figure>

    <div class="row margin-left-right-zero">
      <section class="mt-4 mb-3 col-10 col-sm-4 col-md-4 col-lg-4 mx-sm-auto">

        <div class="text-center mx-1 mx-sm-auto">
          <div>
            <h5 class="my-3 ls-1">{{ 'customer.hi' | translate }} {{name}}</h5>
          </div>
        </div>
        <div class="text-center mx-1 mx-sm-auto">
          <div>
            <p class=" my-3 ls-1">{{ 'customer.description' | translate }}</p>
          </div>
        </div>
      </section>
    </div>
    <div class="row margin-left-right-zero">
      <section class="mb-4 mb-3 col-10 col-sm-10 col-md-10 col-lg-8 mx-sm-auto px-0">
        <div class="text-center mx-1 mx-sm-auto mx-0">
          <form #customerForm="ngForm" autocomplete="off">
            <div class="text-center mx-1 mx-sm-auto">
              <div *ngIf="showAlert" class="mt-3 mb-4">
                <ngb-alert [type]="'custom'" class="alert-home-custom">
                  <ng-container>
                    {{ errMsg }}
                  </ng-container>
                </ngb-alert>
              </div>
            </div>
            <div>
              <p class="text-start font-weight-bold">{{ 'customer.insuredDetail' | translate}}</p>
            </div>
            <div class="customer-detail-block col-12 col-md-12 form-group text-left border rounded margin-left-right-zero">
              <!--   <div class="select-toggle p-relative">
                   <p class="text-start text-title">{{ 'customer.salutation' | translate}}</p>
                 </div>
                 <div class="select-toggle p-relative">
                   <p class="text-start">{{ salutation}}</p>
                 </div>-->
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.insuredName' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start">{{ salutation}} {{ insuredName }}</p>
              </div>
              <ng-container *ngIf="!isCompany">
                <div class="select-toggle p-relative">
                  <p class="text-start text-title">{{ 'customer.identification' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <p class="text-start">{{ identificationNumber | translate}}</p>
                </div>
              </ng-container>
              <ng-container *ngIf="isCompany">
                <div class="select-toggle p-relative">
                  <p class="text-start text-title">{{ 'customer.companyTaxId' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <p class="text-start">{{ identificationNumber | translate}}</p>
                </div>
              </ng-container>
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.phone' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start">{{ phoneNumber }}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.email' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start text-break ">{{ email | translate}}</p>
              </div>
              <ng-container *ngIf="!isCompany">
               <!-- <div class="select-toggle p-relative">
                  <p class="text-start text-title">{{ 'customer.occupation' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <p class="text-start">{{ occupation }}</p>
                </div>-->
                <div class="select-toggle p-relative">
                  <p class="text-start text-title">{{ 'customer.dateOfBirth' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <p class="text-start">{{dateOfBirth}}</p>
                </div>
              </ng-container>
              <!--<div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.beneficiary' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start">{{ beneficiary}}</p>
              </div>-->
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.insuredAddress' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start text-break">{{ insuredAddress}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.billingAddress' | translate}}</p>
              </div>
              <div *ngIf="isBillingAddress" class="select-toggle p-relative">
                <p class="text-start text-break m-0">{{ billingAddress}}</p>
              </div>
              <div *ngIf="!isBillingAddress" class="select-toggle p-relative">
                <p class="text-start text-break m-0">{{ salutation}} {{ insuredName }} {{ insuredAddress}}</p>
              </div>
          </div>
            <div>
              <p class="text-start font-weight-bold">{{ 'customer.driverDetail' | translate}}</p>
            </div>

           <div class="row margin-left-right-zero">
              <div class="center-itself col-3 col-sm-3 col-md-3 col-lg-3 form-group">
                <div class="select-toggle p-relative">
                  <p class="text-center">{{ 'customer.driver' | translate}}</p>
                </div>
              </div>
              <div class="col-9 col-sm-9 col-md-9 col-lg-9 form-group p-0">
                <div class="select-toggle p-relative">
                  <mat-select [(ngModel)]="driverName" class="form-control text-left" id="drivers" name="drivers">
                    <mat-option [id]="'driver' + i" *ngFor="let driver of driverList; index as i;"
                    value="{{driver.name}}" (click)="updateDriverDetails(driver)">
                      {{driver.driverType}}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
            </div>

            <div class="customer-detail-block col-12 col-md-12 form-group text-left border rounded margin-left-right-zero">
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.driverName' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start">{{ driverName}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.dateOfBirth' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start">{{ driverDateOfBirth}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.gender' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start">{{!isEng ? driverGender?.nameThai : driverGender?.nameEng}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.idCard_Passport' | translate}}</p>
              </div>
              <ng-container *ngIf="driverIdentificationType === 'PASSPORT'">
                <div class="select-toggle p-relative">
                  <p class="text-start">{{ driverPassportNumber}}</p>
                </div>
              </ng-container>
              <ng-container *ngIf="driverIdentificationType === 'ID_CARD'">
                <div class="select-toggle p-relative">
                  <p class="text-start">{{ driverIdCardNumber}}</p>
                </div>
              </ng-container>
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.drivingLicenceNo' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start">{{ driverLicenseNumber}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.occupation' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start m-0">{{!isEng ? driverOccupation?.nameThai : driverOccupation?.nameEng}}</p>
              </div>
            </div>

            <div>
              <p class="text-start font-weight-bold">{{ 'customer.carDetail' | translate}}</p>
            </div>
            <div class="customer-detail-block col-12 col-md-12 form-group text-left border rounded margin-left-right-zero">
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.brand' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start">{{ brand }}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.model' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start">{{ model }}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.subModel' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start">{{ subModel }}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.registeredProvince' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start">{{ isEng ? licenseProvinceEn : licenseProvinceTh}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.vin' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start">{{ chassisNumber }}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.engineCapacity' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start">{{ engineSize }}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.yearOfManufacturing' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start">{{ year }}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.color' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start">{{ color }}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.addOn' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start">{{ vehicleAdditionalEquipment }}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.accessory' | translate}}</p>
              </div>
              <div class="select-toggle p-relative">
                <p class="text-start m-0">{{ vehicleAccessories }}</p>
              </div>
            </div>


            <div>
              <p class="text-start font-weight-bold">{{ 'customer.batteryDetail' | translate}}</p>
            </div>
            <div class="customer-detail-block col-12 col-md-12 form-group text-left border rounded margin-left-right-zero">
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.baterryYear' | translate}}</p>
              </div>
              <ng-container *ngIf="baterryYear && baterryYear !== ''">
                <div class="select-toggle p-relative">
                  <p class="text-start">{{baterryYear}}</p>
                </div>
              </ng-container>
              <ng-container *ngIf="!(baterryYear && baterryYear !== '')">
              <div class="select-toggle p-relative">
                <p class="text-start">N/A</p>
              </div>
             </ng-container>
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.batterySerialNumber' | translate}}</p>
              </div>
              <ng-container *ngIf="batterySerialNumber && batterySerialNumber !== ''">
                <div class="select-toggle p-relative">
                  <p class="text-start">{{batterySerialNumber}}</p>
                </div>
              </ng-container>
              <ng-container *ngIf="!(batterySerialNumber && batterySerialNumber !== '')">
              <div class="select-toggle p-relative">
                <p class="text-start">N/A</p>
              </div>
              </ng-container>
              <div class="select-toggle p-relative">
                <p class="text-start text-title">{{ 'customer.wallChargerSerialNumber' | translate}}</p>
              </div>
              <ng-container *ngIf="wallChargerSerialNumber && wallChargerSerialNumber !== ''">
                <div class="select-toggle p-relative">
                  <p class="text-start m-0">{{wallChargerSerialNumber}}</p>
                </div>
              </ng-container>
              <ng-container *ngIf="!(wallChargerSerialNumber && wallChargerSerialNumber !== '')">
              <div class="select-toggle p-relative">
                <p class="text-start m-0">N/A</p>
              </div>
              </ng-container>
            </div>

          <!--  <div>
              <p class="text-start font-weight-bold">{{ 'customer.renewalOffering' | translate}}</p>
            </div>
           <div class="row renewal-item customer-detail-block col-12 col-md-12 form-group text-left border rounded margin-left-right-zero">
              <div class="col-6 col-md-6">
                <div class="select-toggle p-relative font-weight-bold">
                  <p class="text-start ">{{ 'customer.generalDetails' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <p class="text-start ml-3">{{ 'customer.coverage' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <p class="text-start ml-3">{{ 'customer.policyType' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <p class="text-start ml-3">{{ 'customer.garageType' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <p class="text-start ml-3">{{ 'customer.usage' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <p class="text-start">{{ 'customer.grandTotalPremium' | translate}}</p>
                </div>
              </div>
              <div class="col-6 col-md-6">
                <div class="select-toggle p-relative font-weight-bold">
                  <p class="text-start text-center">{{ 'customer.expiringPolicy' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <p class="text-start text-center">{{ 'customer.voluntary' | translate}}</p>
                </div>
                <ng-container *ngIf="policyType==='AV1'">
                  <div class="select-toggle p-relative">
                    <p class="text-start text-center">{{'policy.coverage.policyType1' | translate}}</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="policyType==='AV2'">
                  <div class="select-toggle p-relative">
                    <p class="text-start text-center">{{'policy.coverage.policyType2' | translate}}</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="policyType==='AV3'">
                  <div class="select-toggle p-relative">
                    <p class="text-start text-center">{{'policy.coverage.policyType3' | translate}}</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="policyType==='AV5'">
                  <div class="select-toggle p-relative">
                    <p class="text-start text-center">{{'policy.coverage.policyType5' | translate}}</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="garageType==='Dealer'">
                  <div class="select-toggle p-relative">
                    <p class="text-start text-center">{{ 'policy.coverage.dealerGarage' | translate }}</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="garageType!=='Dealer'">
                  <div class="select-toggle p-relative">
                    <p class="text-start text-center">{{ garageType }}</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="usage==='Personal'">
                  <div class="select-toggle p-relative">
                    <p class="text-start text-center">{{ 'policy.coverage.personalUse' | translate }}</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="usage==='Commercial'">
                  <div class="select-toggle p-relative">
                    <p class="text-start text-center">{{ 'policy.coverage.commercialUse' | translate }}</p>
                  </div>
                </ng-container>
                <div class="select-toggle p-relative">
                  <p class="text-start text-center">
                    {{ grandTotalPremium || 0 | number:'1.2-2' }}
                  </p>
                </div>
              </div>
            </div>-->

            <div>
              <p class="text-start required font-weight-bold">{{ 'customer.postMailReceiveMethod' | translate}}</p>
            </div>
            <div class="row renewal-item customer-detail-block col-12 col-md-12 form-group text-left border rounded margin-left-right-zero">
              <mat-radio-group class="radio-group font-weight-bold" [(ngModel)]="postEmailSelected" name="postEmailSelected">
                <mat-radio-button class="radio-button" value="EMAIL">
                  {{ 'customer.ePolicy' | translate}}
                </mat-radio-button>
                <mat-radio-button class="radio-button font-weight-bold" value="EMAIL/POSTMAIL">
                  {{ 'customer.ePolicyAndPostmail' | translate}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="margin-left-right-zero">
              <div>
                <p class="text-start required font-weight-bold">{{ 'customer.paymentMethod' | translate}}</p>
              </div>
              <div class="row renewal-item customer-detail-block col-12 col-md-12 form-group text-left border rounded">
                <mat-radio-group class="radio-group" [(ngModel)]="paymentOption" name="paymentType">
                  <mat-radio-button class="radio-button font-weight-bold" value="CARD">
                    {{ 'customer.creditCard' | translate}}
                  </mat-radio-button>
                  <mat-radio-button class="radio-button font-weight-bold" value="BANK">
                    {{ 'customer.qrCode' | translate}}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

            <div class="policy-price margin-left-right-zero">
              <div class="row renewal-item customer-detail-block col-12 col-md-12 col-sm-12 col-lg-12 form-group text-left border rounded">
                <div class="select-toggle p-relative ps-0">
                  <p class="text-start text-title font-weight-bold">{{ 'customer.renewalPolicyPrice' | translate}}</p>
                </div>
                <div class="select-toggle p-relative ps-0">
                  <ng-container *ngIf="renewalType==='AVAC'">
                    <div class="renewal-policy-price">
                      <p class="renewal-policy-price-header">{{ 'customer.voluntary&compulsory' | translate}}</p>
                      <p class="renewal-policy-price-content">{{ grandTotalPremium || 0 | number:'1.2-2' }} {{ 'customer.baht' | translate}}</p>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="renewalType==='AV'">
                    <div class="renewal-policy-price">
                      <p class="renewal-policy-price-header">{{ 'customer.voluntary' | translate}}</p>
                      <p class="renewal-policy-price-content">{{ offerResponse?.policyData?.coverageDetails?.premiumBreakdown?.grossPremium || 0 | number }} {{ 'customer.baht' | translate}}</p>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          <div class="row mt-4 mobile-button-group">
            <div class="text-center d-flex align-items-center justify-content-center">
              <button (click)="editData(customerForm)" type="button" id="edit_btn-mobile"
                class="btn btn-outline-primary button br-10 disable-button mr-1" style="flex-basis: 0; flex-grow: 1;">
                {{ 'common.button.edit' | translate }}
              </button>

              <button (click)="submit(customerForm)" type="submit" id="submit_btn-mobile"
                class="btn btn-primary button br-10 disable-button" style="flex-basis: 0; flex-grow: 1;">
                {{ 'common.button.payment' | translate }}
              </button>
            </div>
            <div class="text-center d-flex align-items-center justify-content-center mt-3">
              <button (click)="backToPreviousPage()"
                      type="button" id="back_btn-mobile"
                      class="btn btn-light button br-10 disable-button flex-grow-1">
                  <i class="fa fa-angle-left"></i>
                  {{ ' Back to Policy Coverage' | translate }}
              </button>
            </div>
          </div>

            <div class="row mt-4 desktop-button-group margin-left-right-zero">
              <div class="text-center d-flex align-items-center justify-content-end pr-0">
                <button (click)="backToPreviousPage()"
                        type="button" id="back_btn-desktop"
                        class="btn btn-light button br-10 disable-button mr-1">
                  <i class="fa fa-angle-left"></i>
                  {{ 'common.button.back' | translate }}
                </button>

                <button (click)="editData(customerForm)"
                        type="button" id="edit_btn-desktop"
                        class="btn btn-outline-primary button br-10 disable-button submit_button mr-1">
                  {{ 'common.button.edit' | translate }}
                </button>

                <button (click)="submit(customerForm)"
                        type="submit" id="submit_btn-desktop"
                        class="btn btn-primary button br-10 disable-button submit_button_desktop">
                  {{ 'common.button.payment' | translate }}
                </button>
              </div>
            </div>

          </form>
        </div>
      </section>
    </div>
    <!--<loading *ngIf="isLoading">{{ isLoading }}</loading>-->
  </div>
</div>
<ng-template #spinner>
  <ngx-spinner type="ball-spin-clockwise-fade-rotating" size="medium"></ngx-spinner>
</ng-template>
