import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppConstants } from '../constants/app-constants.model';

@Pipe({
  name: 'dateFormatter'
})
export class DateFormatterPipe extends DatePipe implements PipeTransform {

  transform(value: Date | string, outputFormat: string, inputFormat?: string): any {
    let dateToFormat: Date;

    if (typeof value === 'string') {
      switch (inputFormat) {
        case AppConstants.DATEFORMAT_DDMMYYYY:
          dateToFormat = new Date(+value.substr(4, 4), +value.substr(2, 2) - 1, +value.substr(0, 2));
          break;
        default:
          throw new Error(`Unknown Input Date Format: ${inputFormat}`);
      }
    } else {
      dateToFormat = value;
    }
    return super.transform(dateToFormat, outputFormat);
  }

}
