import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { StorageType } from '../../enum/storage-type.enum';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private sessionStorage: SessionStorageService,
    private localStorage: LocalStorageService
  ) { }

  store(storageType: StorageType, storageKey: string, storageValue: any): void {
    switch (storageType) {
      case StorageType.LOCAL:
        this.localStorage.store(storageKey, storageValue);
        break;
      case StorageType.SESSION:
        this.sessionStorage.store(storageKey, storageValue);
        break;
      default:
        break;
    }
  }

  retrieve(storageType: StorageType, storageKey: string): any {
    switch (storageType) {
      case StorageType.LOCAL:
        return this.localStorage.retrieve(storageKey);
      case StorageType.SESSION:
        return this.sessionStorage.retrieve(storageKey);
      default:
        return null;
    }
  }

  isAvailable(storageType: StorageType, storageKey: string): boolean {
    switch (storageType) {
      case StorageType.LOCAL:
        return this.localStorage.retrieve(storageKey) ? true : false;
      case StorageType.SESSION:
        return this.sessionStorage.retrieve(storageKey) ? true : false;
      default:
        return false;
    }
  }

  clear(storageType: StorageType, storageKey: string): void {
    switch (storageType) {
      case StorageType.LOCAL:
        this.localStorage.clear(storageKey);
        break;
      case StorageType.SESSION:
        this.sessionStorage.clear(storageKey);
        break;
      default:
        break;
    }
  }

  clearAll(storageType: StorageType): void {
    switch (storageType) {
      case StorageType.LOCAL:
        this.localStorage.clear();
        break;
      case StorageType.SESSION:
        this.sessionStorage.clear();
        break;
      default:
        break;
    }
  }

  clearStorage(): void {
    this.localStorage.clear();
    this.sessionStorage.clear();
  }
}
