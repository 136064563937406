import {ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Subscription} from "rxjs";
import {Alert} from "../../../interfaces/alert";
import {CustomerEditResponse, DriverInfo, OfferResponse, PaymentResponse} from "../../../models/response.model";
import {ActivatedRoute, Router} from "@angular/router";
import {CommonService} from "../../../services/common/common.service";
import {GoogleCaptchaService} from "../../../services/google/google-captcha.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ValidationService} from "../../../services/validation/validation.service";
import {AuthService} from "../../../services/auth/auth.service";
import {StorageService} from "../../../services/storage/storage.service";
import {PortalApiService} from "../../../services/rest-api/portal-api.service";
import {SessionStorageService} from "ngx-webstorage";
import {IMTTranslateService} from "../../../services/common/imt.translation.service";
import {AppConstants} from "../../../constants/app-constants.model";
import {FormBuilder, FormControl, FormControlName, FormGroup, NgForm, Validators} from "@angular/forms";
import {
  BillingAddressRequest,
  CustomerEditRequest, DriverDetails,
  InsuredDetails,
  PostAddressRequest,
  Province
} from "../../../models/request.model";
import SubDistrictsJson from '../../../data/sub-district.json';
import DistrictsJson from '../../../data/district.json';
import ProvincesJson from '../../../data/province.json';
import SalutationsJson from '../../../data/salutation.json';
import OccupationsJson from '../../../data/occupation.json';
import GenderJson from '../../../data/gender.json';
import {formatDate} from "@angular/common";
import {DateFormatterPipe} from "../../../pipes/date-formatter.pipe";
import {LangChangeEvent} from "@ngx-translate/core";
import IdentificationJson from '../../../data/identification.json';

interface SubDistrictJson {
  key: string,
  postalCode: string,
  tumbonCode: string,
  districtCode: string,
  tumbonEngName: string,
  tumbonThaiName: string
}

interface DistrictJson {
  "districtCode": string;
  "provinceCode": string;
  "districtEngName": string;
  "districtThaiName": string
}

interface ProvinceJson {
  "id": string;
  "tidCode": string;
  "aegisCode": string;
  "provinceCode": string;
  "nameEng": string;
  "nameThai": string;
  "region": string;
}

interface SalutationJson {
  "salutation": string;
  "insuredType": string;
  "gender": string;
  "salutationDesc1": string;
  "salutationDesc2": string;
  "seq": string;
}

interface OccupationJson {
  "code": string;
  "nameEng": string;
  "nameThai": string;
}

interface GenderJson {
  "code": string;
  "nameEng": string;
  "nameThai": string;
}

interface IdentificationJson {
  "code": string;
  "nameEng": string;
  "nameThai": string;
}
@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss']
})
export class CustomerEditComponent implements OnInit {

  subscription: Subscription;
  alert: Alert;
  showAlert = false;
  errMsg: string = '';
  footer: any = {};
  chassisNumber: string;
  expiringPolicyNumber: string;
  offerResponse: OfferResponse;
  postEmailSelected: string
  emailRequired : boolean

  firstName: string;
  lastName: string;
  cusName: string;
  title: string;
  insuredName: string;
  idCardNumber: string;
  companyTaxId: string;
  phoneNumber: string;
  email: string;
  insuredDateOfBirth: Date;
  ocupation: string;
  beneficiary: string;
  dobMinDate: Date;
  dobMaxDate: Date;
  postSalutation: string;
  postName: string;
  postAddress1: string;
  postAddress2: string;
  postProvinceId: string;
  postProvinceRegion: string;
  postProvinceNameEng: string;
  postProvinceTidCode: string;
  postProvinceNameThai: string;
  postProvinceAegisCode: string;
  postProvinceProvinceCode: string;
  postDistrict: string;
  postDistrictCode: string;
  postSubDistrict: string;
  postSubDistrictCode: string;
  postPostCode: string;
  isUsesInsuredAddress:boolean;
  billingSalutation: string;
  billingName: string;
  billingAddress1: string;
  billingAddress2: string;
  billingProvinceId: string;
  billingProvinceRegion: string;
  billingProvinceNameEng: string;
  billingProvinceTidCode: string;
  billingProvinceNameThai: string;
  billingProvinceAegisCode: string;
  billingProvinceProvinceCode: string;
  billingDistrict: string;
  billingDistrictCode: string;
  billingSubDistrict: string;
  billingSubDistrictCode: string;
  billingPostCode: string;

  isValidBillingPostCode: boolean = true;
  isValidTaxId: boolean = true;
  isValidTaxBranchCode: boolean = true;
  isValidPhoneNumber: boolean = true;
  isValidPostPostCode: boolean = true;

  billingTaxIdNumber: string;
  billingTaxBranchCode: string;
  salutation: string;
  name: string;
  address1: string;
  address2: string;
  provinceId: string;
  provinceRegion: string;
  provinceNameEng: string;
  provinceTidCode: string;
  provinceNameThai: string;
  provinceAegisCode: string;
  provinceProvinceCode: string;
  district: string;
  districtCode: string;
  subDistrict: string;
  subDistrictCode: string;
  postCode: string;

  driverList: DriverInfo[] = [];

  isIdCard: Boolean[] = [];
  isPassport: Boolean[] = [];

  // car details
  brand: string;
  model: string;
  subModel: string;
  licenseNumber: string;
  licenseProvinceEn: string;
  licenseProvinceTh: string;
  engineSize: string;
  year: string;
  color: string;
  vehicleAdditionalEquipment: string;
  vehicleAccessories: string;
  postSubDistrictsJson: SubDistrictJson[] = SubDistrictsJson;
  postDistrictsJson: DistrictJson[] = DistrictsJson;

  billingSubDistrictsJson: SubDistrictJson[] = SubDistrictsJson;
  billingDistrictsJson: DistrictJson[] = DistrictsJson;

  provincesJson: ProvinceJson[] = ProvincesJson;
  salutationsJson: SalutationJson[] = SalutationsJson;
  subDistrictsJson: SubDistrictJson[] = SubDistrictsJson;
  districtsJson: DistrictJson[] = DistrictsJson;

  tmpSubDistrictsJson: SubDistrictJson[] = SubDistrictsJson;
  tmpDistrictsJson: DistrictJson[] = DistrictsJson;

  isLoading: boolean = true;
  isInsuredCompany: boolean = false;
  isBillingCompany: boolean = false;
  isEng: boolean = false;
  sameInsuredAddress: boolean = false;
  insuredType: string;
  billingInsuredType: string;
  billingAddress : string;
  isBillingAddress : boolean = true;
  driverListExist : boolean = false;
  isdriverListExceeded : boolean = false;
  isE12 : boolean = false;
  occupationJson: OccupationJson[] = OccupationsJson;
  genderJson: GenderJson[] = GenderJson;
  identificationJson: IdentificationJson[] = IdentificationJson;
  motorCode : string;

  /*postProvinceControl = new FormControl('', [Validators.required])
  postDistrictControl = new FormControl('', [Validators.required])
  postSubDistrictControl = new FormControl('', [Validators.required])
  billingSalutationControl = new FormControl('', [Validators.required])
  billingProvinceControl = new FormControl('', [Validators.required])
  billingDistrictControl = new FormControl('', [Validators.required])
  billingSubDistrictControl = new FormControl('', [Validators.required])*/
  phoneNumberControl = new FormControl('', [Validators.pattern("^[0-9]{10}$")]);

  constructor(
    private router: Router,
    private commonService: CommonService,
    private googleCaptchaService: GoogleCaptchaService,
    private spinner: NgxSpinnerService,
    private validationService: ValidationService,
    private authService: AuthService,
    private storageService: StorageService,
    private portalApiService: PortalApiService,
    private sessionStorage: SessionStorageService,
    private route: ActivatedRoute,
    private imtTranslateService: IMTTranslateService,
    private dateFormatterPipe: DateFormatterPipe,
    private cdr: ChangeDetectorRef
  ) {
    this.spinner.show();
  }


  ngOnInit(): void {
    window.scrollTo({top: 0});
    let language: string = this.imtTranslateService.getCurrentLanguage();
    this.isEng = language === 'en' ? true : false;
    this.imtTranslateService.onLanguageChanged.subscribe(() => {
      this.cdr.detectChanges();
      this.isEng = !this.isEng;
      console.log('this.cdr.detectChanges()', this.isEng);

    });

    console.log('this.imtTranslateService.getCurrentLanguage()', this.imtTranslateService.getCurrentLanguage());

    this.cusName = this.sessionStorage.retrieve(AppConstants.STORAGE_CUSTOMER_NAME) as string;
    this.phoneNumber = this.sessionStorage.retrieve(AppConstants.STORAGE_CUSTOMER_PHONE_NUMBER) as string;
    this.email = this.sessionStorage.retrieve(AppConstants.STORAGE_CUSTOMER_EMAIL) as string;
    let idCard = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_IDCARD) as string;
    let passport = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_PASSPORT) as string;
    let companyTaxId = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_COMPANY_TAX_ID) as string;
    this.expiringPolicyNumber = this.route.snapshot.paramMap.get('expiringPolicyNumber');
    console.log('expiringPolicyNumber', this.route.snapshot.paramMap, this.expiringPolicyNumber);
    this.postEmailSelected = this.route.snapshot.paramMap.get('postEmailSelected');
    console.log('postEmailSelected', this.route.snapshot.paramMap, this.postEmailSelected);
    if(this.postEmailSelected ==='EMAIL/POSTMAIL' || this.postEmailSelected ==='EMAIL'){
        this.emailRequired = true;
        console.log('emailRequired' , this.emailRequired);
    }
    console.log('this.postSubDistrictsJson.length: ', this.postSubDistrictsJson.length);
    console.log('this.billingSubDistrictsJson.length: ', this.billingSubDistrictsJson.length);
    console.log();


    this.portalApiService
      .offer(idCard, passport, companyTaxId, this.expiringPolicyNumber)
      .subscribe((response: OfferResponse) => {
        if (response) {
          this.isLoading = false;
          if (response.status === 'Success') {
            this.offerResponse = response;
            this.setInput(this.offerResponse);
          }
        }
      },
        (error) => {
          this.isLoading  = true;
          if (error.status === 400 && error.error.code === 'VALIDATION_FAILED') {
            this.router.navigate(['']);
          } else {
            this.isLoading  = false;
          }
        });
  }

  onKeyDown(event: KeyboardEvent, fieldName: string) {
    const fieldValidationMap = new Map([
      ['phoneNumber', 'isValidPhoneNumber'],
      ['postPostCode', 'isValidPostPostCode'],
      ['billingPostCode', 'isValidBillingPostCode'],
      ['taxId', 'isValidTaxId'],
      ['taxBranchCode', 'isValidTaxBranchCode']
    ]);

    const propertyToUpdate = fieldValidationMap.get(fieldName);

    if (propertyToUpdate) {
      this[propertyToUpdate] = this.commonService.sanitizeUserInputValue(event, 'number');
    } else {
      this.commonService.sanitizeUserInputValue(event, 'string');
    }
  }

  setInput(offerResponse: OfferResponse) {
    // InsuredInfo
    console.log('setInput***********************');
    this.title = offerResponse.policyData.insuredDetails.salutation;
    this.insuredName = offerResponse.policyData.insuredDetails.name;
    this.idCardNumber = offerResponse.policyData.insuredDetails.idCardNumber;
    this.phoneNumber = offerResponse.policyData.insuredDetails.phoneNumber;
    this.email = offerResponse.policyData.insuredDetails.email;
    this.companyTaxId = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_COMPANY_TAX_ID);
    this.billingSalutation = offerResponse?.policyData?.insuredDetails?.billingAddress?.salutation;

/*    this.insuredDateOfBirth = new Date(+offerResponse.policyData.insuredDetails.dateOfBirth?.substr(6, 4),
      +offerResponse.policyData.insuredDetails.dateOfBirth?.substr(3, 2) - 1,
      +offerResponse.policyData.insuredDetails.dateOfBirth?.substr(0, 2));*/
    this.insuredDateOfBirth = offerResponse.policyData.insuredDetails?.dateOfBirth
    && offerResponse.policyData.insuredDetails?.dateOfBirth.length > 0?
      new Date(offerResponse.policyData.insuredDetails?.dateOfBirth.replace(/ /g,'')) : null;
    console.log('offerResponse.policyData.insuredDetails?.dateOfBirth', offerResponse.policyData.insuredDetails?.dateOfBirth);
    console.log('this.insuredDateOfBirth', this.insuredDateOfBirth);
    this.ocupation = offerResponse.policyData.insuredDetails.occupation;
    this.beneficiary = offerResponse.policyData.insuredDetails.beneficiary;
    this.dobMinDate = new Date((new Date().getFullYear() - 80), (new Date().getMonth()), (new Date().getDate() + 1));
    this.dobMaxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 20));
/*    this.isInsuredCompany = (this.title === 'บริษัท' || this.title === 'บจก' || this.title === 'หจก'
        || this.title?.toUpperCase() === 'COMPANY')
      || (this.title === 'บริษัท.' || this.title === 'บจก.' || this.title === 'หจก.'
        || this.title?.toUpperCase() === 'COMPANY.')*/
    this.insuredType=offerResponse?.policyData?.insuredDetails?.insuredType;
    this.isInsuredCompany=this.insuredType === 'C' ? true : false
    //InsureAddress
    this.isUsesInsuredAddress = offerResponse?.isSameInsuredAddress;
    this.isUsesInsuredAddressEnable(this.isUsesInsuredAddress);

    this.postSalutation = offerResponse?.policyData?.insuredDetails?.salutation;
    this.postName = offerResponse?.policyData?.insuredDetails?.name;
    this.postAddress1 = offerResponse?.policyData?.insuredDetails?.insuredAddress?.address1;
    this.postAddress2 = offerResponse?.policyData?.insuredDetails?.insuredAddress?.address2;
    this.postProvinceId = offerResponse?.policyData?.insuredDetails?.insuredAddress?.province.id;
    this.postProvinceRegion = offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.region;
    this.postProvinceNameEng = offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.nameEng;
    this.postProvinceNameThai = offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.nameThai;
    this.postProvinceProvinceCode = offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.provinceCode;
    this.postProvinceTidCode = offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.tidCode;
    this.postProvinceAegisCode = offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.aegisCode;
    this.postDistrict = offerResponse?.policyData?.insuredDetails?.insuredAddress?.district;

    if(this.postProvinceAegisCode === null){
      this.postProvinceAegisCode = offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.provinceCode;
    }

    this.postSubDistrict = offerResponse?.policyData?.insuredDetails?.insuredAddress?.tumbon;
    console.log('this.postSubDistrict', this.postSubDistrict);
    this.postPostCode = offerResponse?.policyData?.insuredDetails?.insuredAddress?.postalCode;
    let postDistrictJson: DistrictJson = this.districtsJson.find(s => s.districtThaiName === offerResponse?.policyData?.insuredDetails?.insuredAddress?.district
    && (s => s.provinceCode === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.aegisCode || s.provinceCode === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.provinceCode));
    let postSubDistrictJson: SubDistrictJson = this.subDistrictsJson.find(s => (s.tumbonThaiName === offerResponse?.policyData?.insuredDetails?.insuredAddress?.tumbon || s.tumbonCode === offerResponse?.policyData?.insuredDetails?.insuredAddress?.tumbon)
    && postDistrictJson.districtCode === s.districtCode
    && (s.key === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.aegisCode || s.key === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.provinceCode));
    this.postDistrictsJson = this.districtsJson.filter(s => s.provinceCode === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.aegisCode || s.provinceCode === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.provinceCode);
    this.postSubDistrictsJson = this.subDistrictsJson.filter(s => s.districtCode === postDistrictJson?.districtCode
    && (s.key === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.aegisCode || s.key === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.provinceCode));

    if (!postDistrictJson || !this.postDistrictsJson || this.postDistrictsJson.length === 0 ) {
      this.postDistrict = '';
      this.postDistrictCode = '';
    } else {
      this.postDistrictCode = postDistrictJson.districtCode;
    }
    if (!postSubDistrictJson || !this.postSubDistrictsJson || this.postSubDistrictsJson.length === 0 ) {
      this.postSubDistrict = '';
      this.postSubDistrictCode = '';
    } else {
      this.postSubDistrictCode = postSubDistrictJson.tumbonCode;
    }


    // BillingAddress
    this.billingSalutation = offerResponse?.policyData?.insuredDetails?.billingAddress?.salutation;
    this.billingName = offerResponse?.policyData?.insuredDetails?.billingAddress?.name;
    this.billingAddress1 = offerResponse?.policyData?.insuredDetails?.billingAddress?.address1;
    this.billingAddress2 = offerResponse?.policyData.insuredDetails?.billingAddress?.address2;
    this.billingProvinceId = offerResponse?.policyData?.insuredDetails?.billingAddress?.province?.id;
    this.billingProvinceRegion = offerResponse?.policyData?.insuredDetails?.billingAddress?.province?.region;
    this.billingProvinceNameEng = offerResponse?.policyData?.insuredDetails?.billingAddress?.province?.nameEng;
    this.billingProvinceNameThai = offerResponse?.policyData?.insuredDetails?.billingAddress?.province?.nameThai;
    this.billingProvinceProvinceCode = offerResponse?.policyData?.insuredDetails?.billingAddress?.province?.provinceCode;
    this.billingProvinceTidCode = offerResponse?.policyData?.insuredDetails?.billingAddress?.province?.tidCode;
    this.billingProvinceAegisCode = offerResponse?.policyData?.insuredDetails?.billingAddress?.province?.aegisCode;
    this.billingDistrict = offerResponse?.policyData?.insuredDetails?.billingAddress?.district;
    this.billingDistrictCode = offerResponse?.policyData?.insuredDetails?.billingAddress?.districtCode;
    this.billingSubDistrict = offerResponse?.policyData?.insuredDetails?.billingAddress?.tumbon;
    this.billingPostCode = offerResponse?.policyData?.insuredDetails?.billingAddress?.postalCode;

    let billingDistrictJson: DistrictJson = this.districtsJson.find(s => s.districtThaiName === offerResponse?.policyData?.insuredDetails?.billingAddress?.district
      && (s => s.provinceCode === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.aegisCode || s.provinceCode === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.provinceCode));
    let billingSubDistrictJson: SubDistrictJson = this.subDistrictsJson.find(s => (s.tumbonThaiName === offerResponse?.policyData?.insuredDetails?.billingAddress?.tumbon || s.tumbonCode === offerResponse?.policyData?.insuredDetails?.billingAddress?.tumbon)
    && billingDistrictJson.districtCode === s.districtCode
    && (s.key === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.aegisCode || s.key === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.provinceCode));
    this.billingDistrictsJson = this.districtsJson.filter(s => s.provinceCode === offerResponse?.policyData?.insuredDetails?.billingAddress?.province?.aegisCode || s.provinceCode === offerResponse?.policyData?.insuredDetails?.billingAddress?.province?.provinceCode);
    this.billingSubDistrictsJson = this.subDistrictsJson.filter(s => s.districtCode === billingDistrictJson?.districtCode
    && (s.key === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.aegisCode || s.key === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.provinceCode));

    if (!billingDistrictJson || !this.billingDistrictsJson || this.billingDistrictsJson.length === 0 ) {
      this.billingDistrict = '';
      this.billingDistrictCode = '';
    } else {
      this.billingDistrictCode = billingDistrictJson.districtCode;
    }
    if (!billingSubDistrictJson || !this.billingSubDistrictsJson || this.billingSubDistrictsJson.length === 0 ) {
      this.billingSubDistrict = '';
      this.billingSubDistrictCode = '';
    } else {
      this.billingSubDistrictCode = billingSubDistrictJson.tumbonCode;
    }

    this.billingTaxIdNumber = offerResponse?.policyData?.insuredDetails?.billingAddress?.taxIdNumber
    this.billingTaxBranchCode = offerResponse?.policyData?.insuredDetails?.billingAddress?.taxBranchCode

    this.billingAddress =
      (offerResponse?.policyData?.insuredDetails?.billingAddress?.salutation ? offerResponse?.policyData?.insuredDetails?.billingAddress?.salutation + ' ' : '')
      + (offerResponse?.policyData?.insuredDetails?.billingAddress?.name ? offerResponse?.policyData?.insuredDetails?.billingAddress?.name + ' ' : '')
      + (this.billingAddress1 ? this.billingAddress1 + ' ' : '')
      + (this.billingAddress2 ? this.billingAddress2 + ' ' : '')
      + (this.billingSubDistrict ? this.billingSubDistrict + ' ' : '')
      +  (this.billingDistrict ? this.billingDistrict + ' ' : '')
      +  (this.billingProvinceId ? this.billingProvinceId + ' ' : '')
      +  (this.billingPostCode ? this.billingPostCode : '')
    this.isBillingAddress = this.billingAddress === '' || this.billingAddress === null ? false : true

  /*  if((this.billingSalutation === 'บริษัท' || this.billingSalutation === 'หสม.'
    || this.billingSalutation === 'หจก.' || this.billingSalutation === 'บมจ.'
    || this.billingSalutation === 'บจก.' || this.billingSalutation?.toUpperCase() === 'COMPANY')){
      this.billingInsuredType ='C';
    }else{
      this.billingInsuredType ='P';
    }*/
    this.resetBillingInsuredType( this.billingSalutation);

    this.salutation = offerResponse?.policyData?.insuredDetails?.billingAddress?.salutation;
    this.name = offerResponse?.policyData?.insuredDetails?.billingAddress?.name;
    this.address1 = offerResponse?.policyData?.insuredDetails?.billingAddress?.address1;
    this.address2 = offerResponse?.policyData?.insuredDetails?.billingAddress?.address2;
    this.provinceId = offerResponse?.policyData?.insuredDetails?.billingAddress?.province?.id;
    this.provinceRegion = offerResponse?.policyData?.insuredDetails?.billingAddress?.province?.region;
    this.provinceNameEng = offerResponse?.policyData?.insuredDetails?.billingAddress?.province?.nameEng;
    this.provinceNameThai = offerResponse?.policyData?.insuredDetails?.billingAddress?.province?.nameThai;
    this.provinceProvinceCode = offerResponse?.policyData?.insuredDetails?.billingAddress?.province?.provinceCode;
    this.provinceTidCode = offerResponse?.policyData?.insuredDetails?.billingAddress?.province?.tidCode;
    this.provinceAegisCode = offerResponse?.policyData?.insuredDetails?.billingAddress?.province?.aegisCode;
    this.district = offerResponse?.policyData?.insuredDetails?.billingAddress?.district;
    this.districtCode = offerResponse?.policyData?.insuredDetails?.billingAddress?.districtCode;
    this.subDistrict = offerResponse?.policyData?.insuredDetails?.billingAddress?.tumbon;
    this.subDistrictCode = this.billingSubDistrictCode;
    //this.subDistrictCode = billingSubDistrictJson.tumbonCode;
    this.postCode = offerResponse.policyData?.insuredDetails?.billingAddress?.postalCode;
    this.tmpDistrictsJson = this.billingDistrictsJson;
    this.tmpSubDistrictsJson = this.billingSubDistrictsJson;

    //driver details
    let mainDriver: DriverInfo = offerResponse?.policyData?.driverDetails?.mainDriver
    if(mainDriver?.dateOfBirth) {
      mainDriver.driverType = offerResponse?.policyData?.driverDetails?.mainDriver?.name + ' (1)';
      this.driverList.push(mainDriver);
    }
    let additionalDriver: DriverInfo = offerResponse?.policyData?.driverDetails?.additionalDriver
    if(additionalDriver?.dateOfBirth) {
      additionalDriver.driverType = offerResponse?.policyData?.driverDetails?.additionalDriver?.name + ' (2)';
      this.driverList.push(additionalDriver);
    }
    let driver3: DriverInfo = offerResponse?.policyData?.driverDetails?.driver3
    if(driver3?.dateOfBirth) {
      driver3.driverType = offerResponse?.policyData?.driverDetails?.driver3?.name + ' (3)';
      this.driverList.push(driver3);
    }
    let driver4: DriverInfo = offerResponse?.policyData?.driverDetails?.driver4
    if(driver4?.dateOfBirth) {
      driver4.driverType = offerResponse?.policyData?.driverDetails?.driver4?.name + ' (4)';
      this.driverList.push(driver4);
    }
    let driver5: DriverInfo = offerResponse?.policyData?.driverDetails?.driver5
    if(driver5?.dateOfBirth) {
      driver5.driverType = offerResponse?.policyData?.driverDetails?.driver5?.name + ' (5)';
      this.driverList.push(driver5);
    }

    if (this.driverList && this.driverList.length > 0) {
      for (let i = 0; i < this.driverList.length; i++) {
        this.driverList[i].dateOfBirth = this.driverList[i].dateOfBirth ?
          new Date(this.driverList[i].dateOfBirth) : null;
        this.isIdCard[i] = this.driverList[i].identificationType === 'ID_CARD' ? true : false;
        this.isPassport[i] = this.driverList[i].identificationType === 'PASSPORT' ? true : false;
      }
    }

    if (this.driverList && this.driverList.length > 0) {
      this.driverListExist = true;
    } else {
      if(this.insuredType ==='P'){
        mainDriver.idCardNumber = this.idCardNumber;
        mainDriver.dateOfBirth = this.insuredDateOfBirth;
        this.isIdCard[0]= true;
       // mainDriver.age = this.insu
        this.driverList.push(mainDriver);
        this.driverListExist = true;
      }else{
        this.driverListExist = false;
      }
    }

    if (this.driverList && this.driverList.length >= 5) {
      this.isdriverListExceeded = true;
    } else {
      this.isdriverListExceeded = false;
    }

    this.motorCode = offerResponse?.policyData?.coverageDetails?.motorCode;
    if (["E12", "120E"].includes(this.motorCode)) {
      this.isE12 = true;
    } else {
      this.isE12 = false;
    }

    //car details
    this.brand = offerResponse?.policyData?.vehicleDetails?.make;
    this.model = offerResponse?.policyData?.vehicleDetails?.model;
    this.subModel = offerResponse?.policyData?.vehicleDetails?.subModel;
    this.licenseNumber = offerResponse?.policyData?.vehicleDetails?.licenseNumber;
    this.licenseProvinceEn = offerResponse?.policyData?.vehicleDetails?.licenseProvince?.nameEng;
    this.licenseProvinceTh = offerResponse?.policyData?.vehicleDetails?.licenseProvince?.nameThai;
    this.chassisNumber = offerResponse?.policyData?.vehicleDetails?.chassisNumber;
    this.engineSize = offerResponse?.policyData?.vehicleDetails?.engineSize;
    this.year = offerResponse?.policyData?.vehicleDetails?.year;
    this.color = offerResponse?.policyData?.vehicleDetails?.color;
    this.vehicleAdditionalEquipment = offerResponse?.policyData?.vehicleDetails?.vehicleAdditionalEquipment;
    this.vehicleAccessories = offerResponse?.policyData?.vehicleDetails?.vehicleAccessories;
    if(!this.isBillingAddress){
      this.isUsesInsuredAddress = true;
      this.isUsesInsuredAddressEnable(true);
    }
  }

  removeDriver(i) {
    this.driverList.splice(i, 1);
    if (this.driverList && this.driverList.length > 0) {
      this.driverListExist = true;
    } else {
      this.driverListExist = false;
    }

    if (this.driverList && this.driverList.length >= 5) {
      this.isdriverListExceeded = true;
    } else {
      this.isdriverListExceeded = false;
    }
  }

  addDriver() {
    this.driverList.push(new DriverInfo());
    if (this.driverList && this.driverList.length > 0) {
      this.driverListExist = true;
    } else {
      this.driverListExist = false;
    }

    if (this.driverList && this.driverList.length >= 5) {
      this.isdriverListExceeded = true;
    } else {
      this.isdriverListExceeded = false;
    }
  }
  submit(editCustomerForm: NgForm): void {
    console.log('submit');
    if (this.validationService.checkFormValidity(editCustomerForm)) {
      this.editCustomer();
    }
  }

  updatePostDistrict(updatedProvinceCode: string) {
    console.log('updatedProvinceCode', updatedProvinceCode);
    this.postDistrictsJson = this.districtsJson.filter(s => s.provinceCode === updatedProvinceCode);
    this.postSubDistrictsJson = this.subDistrictsJson.filter(s => s.key === updatedProvinceCode);
/*    this.postSubDistrictsJson = null;*/
    let provinceJson: ProvinceJson = this.provincesJson.find(s => s.aegisCode === updatedProvinceCode);
    this.postDistrict = '';
    this.postSubDistrict = '';
    this.postDistrictCode = '';
    this.postSubDistrictCode = '';
    this.postPostCode = '';
    this.postProvinceRegion = provinceJson.region;
    this.postProvinceTidCode = provinceJson.tidCode;
    this.postProvinceNameEng = provinceJson.nameEng;
    this.postProvinceNameThai = provinceJson.nameThai;
    this.postProvinceProvinceCode = provinceJson.provinceCode;
    this.postProvinceAegisCode = provinceJson.aegisCode;
  }

  updatePostSubDistrict(updatedDistrictCode: string, provinceId: string) {
    this.postDistrictCode = updatedDistrictCode;
    this.postSubDistrictsJson = this.subDistrictsJson.filter(s => s.districtCode === updatedDistrictCode && s.key === provinceId);
    let districtJson: DistrictJson = this.districtsJson.find(s => s.districtCode === updatedDistrictCode && s.provinceCode === provinceId);
    this.postDistrict = districtJson.districtThaiName;
    this.postSubDistrict = '';
    this.postSubDistrictCode = '';
    this.postPostCode = '';
    console.log('this.postSubDistrictsJson.length: ', this.postSubDistrictsJson.length);
  }

  updatePostPostCode(tumbonCode: string, updatedPostPostCode: string) {
    this.postPostCode = updatedPostPostCode;
    let subDistrictJson: SubDistrictJson  = this.subDistrictsJson.find(s => s.tumbonCode === tumbonCode);
    this.postSubDistrict = subDistrictJson.tumbonThaiName;
  }

  updateBillingDistrict(updatedProvinceCode: string) {
    this.billingDistrictsJson = this.districtsJson.filter(s => s.provinceCode === updatedProvinceCode);
    this.billingSubDistrictsJson = this.subDistrictsJson.filter(s => s.key === updatedProvinceCode);
    let provinceJson: ProvinceJson = this.provincesJson.find(s => s.aegisCode === updatedProvinceCode);
    this.billingProvinceRegion = provinceJson.region;
    this.billingProvinceTidCode = provinceJson.tidCode;
    this.billingProvinceNameEng = provinceJson.nameEng;
    this.billingProvinceNameThai = provinceJson.nameThai;
    this.billingProvinceProvinceCode = provinceJson.provinceCode;
    this.billingProvinceAegisCode = provinceJson.aegisCode;
   /* this.billingSubDistrictsJson = null;*/
    this.billingDistrict = '';
    this.billingSubDistrict = '';
    this.billingDistrictCode = '';
    this.billingSubDistrictCode = '';
    this.billingPostCode = '';
  }

  updateBillingSubDistrict(updatedDistrictCode: string, provinceId: string) {
    this.billingDistrictCode = updatedDistrictCode;
    let districtJson: DistrictJson = this.districtsJson.find(s => s.districtCode === updatedDistrictCode && s.provinceCode === provinceId);
    this.billingDistrict = districtJson.districtThaiName;
    this.billingSubDistrictsJson = this.subDistrictsJson.filter(s => s.districtCode === updatedDistrictCode && s.key === provinceId);
    this.billingSubDistrict = '';
    this.billingSubDistrictCode = '';
    this.billingPostCode = '';
    console.log('updatedDistrictCode, updatedDistrictCode, this.billingSubDistrictsJson.length: ', updatedDistrictCode, provinceId, this.billingSubDistrictsJson.length);
  }


  updateOccupation(occupationName: string, i: number): void {
    this.driverList[i].occupation = occupationName;
  }

  updateIdentificationType(type: string, i: number): void {
    if (type === 'ID_CARD') {
      this.driverList[i].identificationType = 'ID_CARD'
      this.isIdCard[i] = true;
      this.isPassport[i] = false;
    }
    if (type === 'PASSPORT') {
      this.driverList[i].identificationType = 'PASSPORT';
      this.isPassport[i] = true;
      this.isIdCard[i] = false;
    }
  }

  updateBillingPostCode(tumbonCode: string, updatedBillingPostCode: string): void {
    this.billingPostCode = updatedBillingPostCode;
    let subDistrictJson: SubDistrictJson  = this.subDistrictsJson.find(s => s.tumbonCode === tumbonCode);
    this.billingSubDistrict = subDistrictJson.tumbonThaiName;
  }

  isUsesInsuredAddressEnable(isUsesInsuredAddress: boolean): void{
    if (isUsesInsuredAddress && isUsesInsuredAddress === true) {
      this.billingName = this.postName;
      this.billingSalutation = this.postSalutation;
      this.billingAddress1 =  this.postAddress1;
      this.billingAddress2 =  this.postAddress2;
      this.billingProvinceId = this.postProvinceId;
      this.billingProvinceRegion = this.postProvinceRegion;
      this.billingProvinceNameEng = this.postProvinceNameEng;
      this.billingProvinceNameThai =  this.postProvinceNameThai;
      this.billingProvinceProvinceCode = this.postProvinceProvinceCode;
      this.billingProvinceTidCode = this.postProvinceTidCode;
      this.billingProvinceAegisCode = this.postProvinceAegisCode;
      this.billingDistrict =  this.postDistrict;
      this.billingDistrictCode =  this.postDistrictCode;
      this.billingSubDistrict = this.postSubDistrict;
      this.billingSubDistrictCode = this.postSubDistrictCode;
      this.billingPostCode = this.postPostCode;
      this.billingDistrictsJson = this.postDistrictsJson;
      this.billingSubDistrictsJson = this.postSubDistrictsJson;
      this.sameInsuredAddress = true;
      this.resetBillingInsuredType(this.billingSalutation);
      this.billingTaxBranchCode ="";
      this.billingTaxIdNumber = "";
    }
  }

  updateSalutation (salutation: string, insuredType: string) {
    this.billingInsuredType = insuredType;
    console.log('updateSalutation :' + salutation + '=' + insuredType);
    if(this.billingInsuredType === 'P'){
      this.billingTaxIdNumber ="";
      this.billingTaxBranchCode ="";
    }
  }
  resetBillingInsuredType(salutation: string){
    console.log('resetBillingInsuredType =' + salutation);
    if(salutation === 'บริษัท' || salutation === 'หสม.' || salutation === 'หจก.' || salutation === 'บมจ.'
        || salutation === 'บจก.' || salutation?.toUpperCase() === 'COMPANY'){
      this.billingInsuredType ='C';
    }else if(salutation === 'นาย' || salutation === 'นางสาว' || salutation === 'นาง' || salutation === 'คุณ'
    || salutation?.toUpperCase() === 'MR.' || salutation?.toUpperCase() === 'MISS' || salutation?.toUpperCase() === 'MRS.'
    || salutation?.toUpperCase() === 'KHUN'){
      this.billingInsuredType ='P';
    }
    else{
      this.billingInsuredType ='';
    }
  }

  cancel(customerForm: NgForm): void {
    this.router.navigate(['/customer-detail', {expiringPolicyNumber : this.expiringPolicyNumber}]);
  }

  closeAlert(alert: Alert): void {
    this.showAlert = false;
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
    this.spinner.hide();
  }

  editCustomer(): void {
    if (!this.isE12 && !this.driverListExist) {
      this.errMsg = 'Require at least 1 driver';
      this.showAlert = true;
      return;
    }
    const fieldsToCheck = [
      'postDistrictControl'
    ];


    console.log('request.policyNumber = this.expiringPolicyNumber', this.expiringPolicyNumber);
    const idCard = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_IDCARD) as string;
    const passport = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_PASSPORT) as string;
    const companyTaxId = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_COMPANY_TAX_ID) as string;
    this.expiringPolicyNumber = this.route.snapshot.paramMap.get('expiringPolicyNumber');
    let request: CustomerEditRequest = new CustomerEditRequest();
    let insuredDetails: InsuredDetails = new InsuredDetails();
    let driverDetails: DriverDetails = new DriverDetails();
    let postAddress: PostAddressRequest = new PostAddressRequest();
    let postAddressProvince: Province = new Province();
    let billingAddress: BillingAddressRequest = new BillingAddressRequest();
    let billingAddressProvince: Province = new Province();
    request.policyNumber = this.expiringPolicyNumber;
    insuredDetails.phoneNumber = this.phoneNumber;
    insuredDetails.email = this.email;
    insuredDetails.billingInsuredType = this.billingInsuredType;
    insuredDetails.billingTaxIdNumber = this.billingTaxIdNumber;
    insuredDetails.billingTaxBranchCode = this.billingTaxBranchCode;
    console.log('this.insuredDateOfBirth', this.insuredDateOfBirth);
/*    insuredDetails.dateOfBirth = this.dateFormatterPipe
      .transform(new Date(this.insuredDateOfBirth), 'dd/MM/yyyy');*/
    if (!this.isInsuredCompany) {
      insuredDetails.dateOfBirth = this.insuredDateOfBirth ? this.dateFormatterPipe
        .transform(this.insuredDateOfBirth, 'dd/MM/yyyy'): null;
    }


    this.isUsesInsuredAddressEnable(this.isUsesInsuredAddress);

    postAddress.address1 = this.postAddress1;
    postAddress.address2 = this.postAddress2;
    postAddressProvince.id = this.postProvinceId;
    postAddressProvince.region = this.postProvinceRegion;
    postAddressProvince.tidCode = this.postProvinceTidCode;
    postAddressProvince.nameEng = this.postProvinceNameEng;
    postAddressProvince.nameThai = this.postProvinceNameThai;
    postAddressProvince.provinceCode = this.postProvinceProvinceCode;
    postAddressProvince.aegisCode = this.postProvinceAegisCode;
    postAddress.province = postAddressProvince;
    postAddress.tumbon = this.postSubDistrictCode;
    postAddress.tumbonName = this.postSubDistrict;
    postAddress.district = this.postDistrict;
    postAddress.districtCode = this.postDistrictCode;
    postAddress.provinceCode = this.postProvinceAegisCode;
    postAddress.postalCode = this.postPostCode;
    insuredDetails.postAddress = postAddress;

    billingAddress.salutation = this.billingSalutation;
    billingAddress.name = this.billingName;
    billingAddress.address1 = this.billingAddress1;
    billingAddress.address2 = this.billingAddress2;
    billingAddressProvince.id = this.billingProvinceId;
    billingAddressProvince.region = this.billingProvinceRegion;
    billingAddressProvince.tidCode = this.billingProvinceTidCode;
    billingAddressProvince.nameEng = this.billingProvinceNameEng;
    billingAddressProvince.nameThai = this.billingProvinceNameThai;
    billingAddressProvince.provinceCode = this.billingProvinceProvinceCode;
    billingAddressProvince.aegisCode = this.billingProvinceAegisCode;
    billingAddress.province = billingAddressProvince;
    billingAddress.tumbon = this.billingSubDistrictCode;
    billingAddress.tumbonName = this.billingSubDistrict;
    billingAddress.district = this.billingDistrict;
    billingAddress.districtCode = this.billingDistrictCode;
    billingAddress.provinceCode = this.billingProvinceAegisCode;
    billingAddress.postalCode = this.billingPostCode;
    billingAddress.taxIdNumber = this.billingTaxIdNumber;
    billingAddress.taxBranchCode = this.billingTaxBranchCode;
    insuredDetails.billingAddress = billingAddress;

    insuredDetails.sameInsuredAddress = this.sameInsuredAddress;
    request.insuredDetails = insuredDetails;

    driverDetails.mainDriver = this.driverList && this.driverList.length >= 0 ? this.driverList[0] : null;
    if (this.driverList?.length > 0) {
      driverDetails.mainDriver = this.driverList[0];
      driverDetails.mainDriver.dateOfBirth = this.driverList[0]?.dateOfBirth ? this.dateFormatterPipe
        .transform(this.driverList[0]?.dateOfBirth, 'dd/MM/yyyy'): null;
    } else {
      driverDetails.mainDriver = null;
    }
    if (this.driverList?.length > 1) {
      driverDetails.additionalDriver = this.driverList[1];
      driverDetails.additionalDriver.dateOfBirth = this.driverList[1]?.dateOfBirth ? this.dateFormatterPipe
        .transform(this.driverList[1].dateOfBirth, 'dd/MM/yyyy'): null;
    } else {
      driverDetails.additionalDriver = null;
    }
    if (this.driverList?.length > 2) {
      driverDetails.driver3 = this.driverList[2];
      driverDetails.driver3.dateOfBirth = this.driverList[2]?.dateOfBirth ? this.dateFormatterPipe
        .transform(this.driverList[2].dateOfBirth, 'dd/MM/yyyy'): null;
    } else {
      driverDetails.driver3 = null;
    }
    if (this.driverList?.length > 3) {
      driverDetails.driver4 = this.driverList[3];
      driverDetails.driver4.dateOfBirth = this.driverList[3]?.dateOfBirth ? this.dateFormatterPipe
        .transform(this.driverList[3].dateOfBirth, 'dd/MM/yyyy'): null;
    } else {
      driverDetails.driver4 = null;
    }
    if (this.driverList?.length > 4) {
      driverDetails.driver5 = this.driverList[4];
      driverDetails.driver5.dateOfBirth = this.driverList[4]?.dateOfBirth ? this.dateFormatterPipe
        .transform(this.driverList[4].dateOfBirth, 'dd/MM/yyyy'): null;
    } else {
      driverDetails.driver5 = null;
    }
    request.driverDetails = driverDetails;
    console.log("driverDetails", driverDetails);
    // TODO: change special & payment value
    this.isLoading = true;
    this.portalApiService.editCustomer(request)
      .subscribe(
        (response: CustomerEditResponse) => {
          this.isLoading = false;
          if (response.status === 'Success') {
            this.router.navigate(['/customer-detail', {expiringPolicyNumber : this.expiringPolicyNumber}]);
          } else {
            this.showAlert=true;
            this.errMsg = response?.message;
          }

        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.showAlert=true;
          this.errMsg = error?.error?.description;
        });
  }

  isEnglishSelected(): boolean {
    console.log('this.imtTranslationService.getCurrentLanguage()', this.imtTranslateService.getCurrentLanguage());
    return 'en' === this.imtTranslateService.getCurrentLanguage();
  }
}
