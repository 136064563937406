<app-header></app-header>
<div *ngIf="!isLoading, else spinner">
  <div class="wrapper" [class.Font-Thai]="!isEnglishSelected">
    <figure class="mb-4">
      <img alt="Tesla Cars" src="assets/tesla-cars-banner.png" class="img-fluid">
    </figure>

    <div class="row">
      <div class="col-10 col-sm-4 col-md-4 col-lg-4 mx-sm-auto">
        <section class="mt-4 mb-3">

          <div class="text-center mx-1 mx-sm-auto">
            <div>
              <h5 class="my-3 ls-1">{{ 'common.authentication' | translate}}</h5>
            </div>
          </div>
        </section>

        <section class="mb-4">
          <div class="main-container  text-center mx-1 mx-sm-auto">
            <form #customerForm="ngForm" autocomplete="off" (ngSubmit)="submit(customerForm)">
              <div class="text-center mx-1 mx-sm-auto">
                <div *ngIf="showAlert" class="mt-3 mb-4">
                  <ngb-alert [type]="'custom'" class="alert-home-custom">
                    <ng-container>
                      {{ 'home.incorrectValidation' | translate}}
                    </ng-container>
                  </ngb-alert>
                </div>
                <p class=" my-3 ls-1">{{ 'home.identity' | translate}}</p>
              </div>
              <div class="row my-3 ls-1">
                <div class="col-12 col-md-12 form-group">
                  <div class="select-toggle p-relative">
                    <input type="text" class="form-control text-center rounded-5 p-0" id="chassisNo" name="idCard"
                           #chassisNoControl="ngModel"
                           [(ngModel)]="idCard" required pattern="^[a-zA-Z0-9]+$" maxlength="13" minlength="6"
                           [ngClass]="showAlert ? 'error' : ''" [appValidateOnBlur]="chassisNoControl">
                  </div>
                  <ng-container *ngIf="chassisNoControl.touched && chassisNoControl?.errors">
                    <div class="input-error" *ngIf="chassisNoControl?.errors['required']">
                      {{ 'home.identityValidation' | translate}}
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="row mt-4">
                <div class="form-group">
                  <button type="submit" id="submit_btn" [disabled]="!chassisNoControl.valid || disableSubmitBtn"
                          class="btn btn-primary button br-60 disable-button submit_button">
                    {{ 'common.button.confirm' | translate }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
  <ng-template #spinner>
    <ngx-spinner type="ball-spin-clockwise-fade-rotating" size="medium"></ngx-spinner>
  </ng-template>
