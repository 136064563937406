import { Injectable } from '@angular/core';
import {forkJoin, Observable, Subject, Subscription, timer} from 'rxjs';
import {map, take} from 'rxjs/operators';
import { Router } from '@angular/router';
// import { CacheService } from 'app/core/services/cache/cache.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
// tslint:disable-next-line:max-line-length
// import { SessionExpiryAlertModalComponent } from 'app/core/components/shared/modals/session-expiry-alert-modal/session-expiry-alert-modal.component';
import { AppConstants } from '../../constants/app-constants.model';
import { StorageService } from '../storage/storage.service';
import { StorageType } from '../../enum/storage-type.enum';
import {PortalApiService} from "../rest-api/portal-api.service";
import {CustomerAuthenticatedResponse, Status, ValidateCustomerResponse} from "../../models/response.model";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {resolve} from "@angular/compiler-cli/src/ngtsc/file_system";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  sessionTimer: Observable<number>;
  sessionSubscription: Subscription;
  bsModalRef: BsModalRef;

  constructor(
    private storageService: StorageService,
    private router: Router,
    // private cacheService: CacheService,
    private modalService: BsModalService,
    private portalApiService: PortalApiService,
  ) { }

  isAuthenticated(): boolean {
    if (this.storageService.isAvailable(StorageType.SESSION, AppConstants.STORAGE_KEY_IS_AUTHENTICATED)) {
      return this.storageService.retrieve(StorageType.SESSION, AppConstants.STORAGE_KEY_IS_AUTHENTICATED);
    } else { return false; }
  }

  authenticate(): void {
    this.storageService.store(StorageType.SESSION, AppConstants.STORAGE_KEY_IS_AUTHENTICATED, true);
    this.startSession();
  }

  optAuthenticate(): void {
    this.storageService.store(StorageType.SESSION, AppConstants.STORAGE_KEY_IS_OTP_AUTHENTICATED, true);
    this.startSession();
  }




  async isOtpAuthenticated() : Promise<boolean>{
    let check = await this.portalApiService.checkCustomerAuthenticated(
      this.storageService.retrieve(StorageType.SESSION, AppConstants.STORAGE_KEY_IDCARD),
      this.storageService.retrieve(StorageType.SESSION, AppConstants.STORAGE_KEY_PASSPORT),
      this.storageService.retrieve(StorageType.SESSION, AppConstants.STORAGE_KEY_COMPANY_TAX_ID)
    )
    console.log('isOtpAuthenticated ', check );
    return new Promise((resolve, reject) => {
      resolve(check);
    });
  }

  private startSession(): void {
    this.sessionTimer = timer(1000, 1000);
    this.sessionSubscription = this.sessionTimer.pipe(
      take((AppConstants.SESSION_DURATION_MINUTES * 60) - 30))
      .subscribe({
        next: (elapsedTime: number) => {
         this.storageService.store(StorageType.SESSION, AppConstants.STORAGE_KEY_ELAPSED_SESSION_TIME, elapsedTime + 1);
          // if ((elapsedTime + 1) === (AppConstants.SESSION_EXPIRY_ALERT_MINUTES * 60)) {
          //   this.showSessionExpiryAlertModal();
          // }
        },
        error: null,
        complete: () => {
          // this.router.navigate(['/sessionexpired']);
          this.router.navigate(['']);
        }
      });
  }

  clear(): void {
    this.storageService.clearAll(StorageType.LOCAL);
    this.storageService.clearAll(StorageType.SESSION);
  }

  // resumeSession(): void {
  //   if (!this.sessionTimer) {
  //     this.sessionTimer = timer(1000, 1000);
  //     this.sessionSubscription = this.sessionTimer.pipe(
  //       take(((AppConstants.SESSION_DURATION_MINUTES * 60) - 30) -
  //         this.storageService.retrieve(StorageType.SESSION, AppConstants.STORAGE_KEY_ELAPSED_SESSION_TIME)))
  //       .subscribe({
  //         next: (elapsedTime: number) => {
  // tslint:disable-next-line:max-line-length
  //           const updatedElapsedTime = this.storageService.retrieve(StorageType.SESSION, AppConstants.STORAGE_KEY_ELAPSED_SESSION_TIME) + 1;
  //           this.storageService.store(StorageType.SESSION, AppConstants.STORAGE_KEY_ELAPSED_SESSION_TIME, updatedElapsedTime);
  //           if (updatedElapsedTime === (AppConstants.SESSION_EXPIRY_ALERT_MINUTES * 60)) {
  //             this.showSessionExpiryAlertModal();
  //           }
  //         },
  //         error: null,
  //         complete: () => {
  //           this.router.navigate(['/sessionexpired']);
  //         }
  //       });
  //   }
  // }

  // private showSessionExpiryAlertModal(): void {
  //   this.bsModalRef = this.modalService.show(SessionExpiryAlertModalComponent,
  //     { id: AppConstants.SESSION_EXPIRY_ALERT_MODAL, ignoreBackdropClick: true });
  // }

  // prepareNewSession(): void {
  //   this.endSession();
  // }

  // endSession(): void {
  //   if (this.sessionSubscription) {
  //     this.sessionSubscription.unsubscribe();
  //   }
  //   if (this.modalService) {
  //     this.modalService.hide(AppConstants.DATA_ERROR_MODAL);
  //     this.modalService.hide(AppConstants.SESSION_EXPIRY_ALERT_MODAL);
  //   }
  //   this.sessionTimer = null;
  //   this.storageService.clearStorage();
  //   this.cacheService.clearCache();
  // }

}
