export class Status {
  statusCode: string;
  statusDescription: StatusDesc[];
}

export class StatusDesc {
  fieldName: string;
  errorCode: string;
}

export class ValidateCustomerResponse {
  status: string;
  code: string;
  message: string;
  phoneNumberAvailable : boolean;
  customer: Customer;
}

export class Customer {
  customerId: number;
  idCard: string;
  passport: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  name: string;
}

export class OTPResponse {
  status: Status;
  message: string;
  otpExpiryTs: string;

  maskPhoneNumber: string;
}

export class VerifyOTPResponse {
  status: string;
  message: string;
}

export class StatusCode {
  statusCode: string;
}

export class PoliciesResponse {
  status: string;
  message: string;
  code: string;
  expiringPolicies: string [];
}

export class OfferResponse {
  status: string;
  message: string;
  code: string;
  policyStatus: string;
  policyData: PolicyData;
  isSameInsuredAddress: boolean;
  driverList: [];
}

export class PolicyData {
  policyDetails: OfferPolicyDetail;
  coverageDetails: OfferCoverageDetail;
  insuredDetails: OfferInsuredDetail;
  driverDetails: OfferDriverDetail;
  vehicleDetails: OfferVehicleDetail;
  batteryDetails: OfferBatteryDetail;
}

export class OfferPolicyDetail {
  id: string;
  status: string;
  agentName: string;
  agentCode: string;
  parentAgent: string;
  branchCode: string;
  expiringAVPolicyNumber: string;
  voluntaryStartDate: string;
  voluntaryEndDate: string;
  expiringACPolicyNumber: string;
  compulsoryStartDate: string;
  compulsoryEndDate: string;
  unitRemarks: string;
  contractRemarks: string;
}

export class OfferCoverageDetail {
  coveragePeriod: string;
  shortPeriodType: string;
  shortTermPolicy: string;
  motorCode: string;
  productCode: string;
  voluntaryCaCode: string;
  compulsoryCaCode: string;
  garageType: string;
  expiryDate: string;
  policyType: string;
  coverageBreakdown: CoverageBreakdown;
  discountBreakdown: DiscountBreakdown;
  premiumBreakdown: PremiumBreakdown;
  AdditionalPremiumBreakdown: AdditionalPremiumBreakdown;

}

export class CoverageBreakdown {
  limitTPBI: string;
  limitTPPD: string;
  sumInsured: string;
  av5SumInsured: string;
  limitBailBond: string;
  limitPADriver: string;
  sumInsuredMax: string;
  sumInsuredMin: string;
  limitFireTheft: string;
  limitOwnDamage: string;
  limitODDeductible: string;
  limitPAPassengers: string;
  limitTPDeductible: string;
  limitDeathOverCTOL: string;
  limitMedicalExpense: string;
  limitDisabilityPersons: string;
  limitPAPassengersPersons: string;
  limitMedicalExpensePersons: string;
  limitTotalDisabilityPerWeekDriver: string;
  limitTotalDisabilityPerWeekPassenger: string;
}

export class DiscountBreakdown {
  ncbPercent: string;
  fleetPercent: string;
  otherPercent: string;
  surchargePercent: string;
  driverDiscountPercent: string;
}

export class PremiumBreakdown {
  stampDuty: string;
  netPremium: string;
  grossPremium: string;
  valueAddedTax: string;
  mainRelativity: string;
  targetNetPremium: string;
  taxAmountOver1Year: string;
  compulsoryStampDuty: string;
  netPremiumOver1Year: string;
  compulsoryNetPremium: string;
  stampAmountOver1Year: string;
  grossPremiumOver1Year: string;
  compulsoryGrossPremium: string;
  compulsoryValueAddedTax: string;
}

export class AdditionalPremiumBreakdown {
  basePremium: string;
  additionalAV: string;
  additionalBB: string;
  additionalDD: string;
  additionalEA: string;
  additionalED: string;
  additionalFD: string;
  additionalME: string;
  additionalMP: string;
  additionalOD: string;
  additionalOT: string;
  additionalPD: string;
  additionalPP: string;
  additionalTD: string;
  additionalTH: string;
  additionalTP: string;
  additionalFTAV: string;
  additionalODAV: string;
  additionalSIPR: string;
  av5BasePremium: string;
  av5MainPremium: string;
  additionalTPPDAV: string;
  totalDiscountAmount: string;
  driverDiscountAmount: string;
  annualizedAdditionalAV: string;
  annualizedAdditionalBB: string;
  annualizedAdditionalME: string;
  annualizedAdditionalMP: string;
  annualizedAdditionalPD: string;
  annualizedAdditionalPP: string;
  annualizedAdditionalTD: string;
  annualizedAdditionalTP: string;
  annualizedAdditionalFTAV: string;
  annualizedAdditionalODAV: string;
  annualizedAdditionalSIPR: string;
  annualizedAdditionalTPPDAV: string;
}

export class OfferInsuredDetail {
  salutation: string;
  name: string;
  dateOfBirth: string;
  insuredType: string;
  idCardNumber: string;
  taxBranchCode: string;
  otherCardNumber: string;
  phoneNumber: string;
  email: string;
  occupation: string;
  beneficiary: string;
  insuredAddress: OfferInsuredAddress;
  billingAddress: OfferBillingAddress;
}
export class OfferDriverDetail {
  mainDriver: DriverInfo;
  additionalDriver: DriverInfo;
  driver3: DriverInfo;
  driver4: DriverInfo;
  driver5: DriverInfo;
}

export class DriverInfo {
  age: string;
  sex: string;
  name: string;
  occupation: string;
  dateOfBirth: Date;
  identificationType: string;
  idCardNumber: string;
  passportNumber: string;
  licenseNumber: string;
  occupationCode: string;
  driverType : string;
}
export class OfferInsuredAddress {
  salutation: string;
  name: string;
  tumbon: string;
  address1: string;
  address2: string;
  district: string;
  province: Province;
  postalCode: string;
  taxIdNumber: string;
  taxBranchCode: string;
  districtCode: string;
}
class Province {
  id: string;
  region: string;
  nameEng: string;
  tidCode: string;
  nameThai: string;
  aegisCode: string;
  provinceCode: string;
}
export class OfferBillingAddress {
  salutation;
  name: string;
  tumbon: string;
  address1: string;
  address2: string;
  district: string;
  province: Province;
  postalCode: string;
  taxIdNumber: string;
  taxBranchCode: string;
  districtCode: string;
}

export class  OfferVehicleDetail {
  make: string;
  model: string;
  subModel: string;
  licenseNumber: string;
  licenseProvince: Province;
  chassisNumber: string;
  engineSize: string;
  weight: string;
  year: string;
  color: string;
  doors: string;
  seats: string;
  vehicleUse: string;
  vehicleAdditionalEquipment: string;
  vehicleAccessories: string;
}

export class  OfferBatteryDetail {
  batteryYear: string;
  newBatterySerialNumber: string;
  wallChargerSerialNumber: string;
}

export class PaymentStatus {
  idCard: string;
  passport: string;
  companyTaxId: string;
  paymentUuid: string;
  paymentStatus: string;
  newAvPolicyNumber: string;
  newAcPolicyNumber: string;
  newAvPolicyEffectiveDate: string;
  newAvPolicyExpiryDate: string;
  newAcPolicyEffectiveDate: string;
  newAcPolicyExpiryDate: string;
  paymentReferenceNumber: string;
  policyStatus: string;
  isFinance: boolean;
}

export class PaymentResponse {
  uuid: string;
  paymentAppUrl: string;
  status: string;
}

export class CustomerEditResponse {
  status: string;
  code: string;
  message: string;
  policyNumber: string;
  insuredDetails: PolicyInsuredDetails;
}

export class PolicyInsuredDetails {
  phoneNumber: string;
  sameInsuredAddress: string;
  email: string;
  dateOfBirth: string;
  insuredType: string;
  taxIdNumber: string;
  taxBranchCode: string;
  billingInsuredType: string;
  billingTaxIdNumber: string;
  billingTaxBranchCode: string;
  postAddress: PostAddress;
  billingAddress: BillingAddress;

}

export class PostAddress {
  tumbon: string;
  address1: string;
  address2: string;
  districtCode: string;
  provinceCode: string;
  postalCode: string;
}

export class BillingAddress {
  salutation: string;
  name: string;
  tumbon: string;
  address1: string;
  address2: string;
  districtCode: string;
  provinceCode: string;
  postalCode: string;
}

export class UpdatePhoneNumberResponse {
  statusMessage: string;
  status: string;
  message: string;
  code: string;
}

export class UpdateOtherDetailsResponse {
  status: string;
  code: string;
  message: string;
}

export class CustomerAuthenticatedResponse {
  statusCode: string;
}
