import {Routes} from '@angular/router';
import {HomeComponent} from '../components/main/home/home.component';
import {OtpComponent} from '../components/main/otp/otp.component';
import {PolicyListComponent} from '../components/main/policy-list/policy-list.component';
import {PolicyCoverageComponent} from '../components/main/policy-coverage/policy-coverage.component';
import {CustomerDetailComponent} from '../components/main/customer-detail/customer-detail.component';
import {AuthGuard} from '../guards/auth.guard';
import {OtpGuard} from '../guards/otp.guard';
import {OthersGuard} from '../guards/others.guard';
import {PaymentStatusComponent} from '../components/main/payment/payment-status.component';
import {UpdatePhoneNumberComponent} from '../components/main/update-phone-number/update-phone-number.component';
import {CustomerEditComponent} from '../components/main/customer-edit/customer-edit.component';
import {HomeGuard} from "../guards/home.guard";

export const AppRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [HomeGuard],
  },
  {
    path: 'otp',
    component: OtpComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'policies',
    component: PolicyListComponent,
    canActivate: [OtpGuard],
  },
  {
    path: 'policy-coverage',
    component: PolicyCoverageComponent,
    canActivate: [OtpGuard],
  },
  {
    path: 'customer-detail',
    component: CustomerDetailComponent,
    canActivate: [OtpGuard],
  },
  {
    path: 'payment/status/:uuid',
    component: PaymentStatusComponent,
  },
  {
    path: 'update-phone-number',
    component: UpdatePhoneNumberComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'customer-edit',
    component: CustomerEditComponent,
    canActivate: [OtpGuard],
  },
  {
    path: '**',
    component: HomeComponent,
    canActivate: [OthersGuard]
  }
];
