import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/core/constants/app-constants.model';
import { Language } from 'src/app/core/models/language';
import { CommonService } from 'src/app/core/services/common/common.service';
import { IMTTranslateService } from 'src/app/core/services/common/imt.translation.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  libertyHelplineShort: string;
  libertyHelplineFull: string;
  libertyMailSupport: string;

  constructor(private commonService: CommonService, private imtTranslationService: IMTTranslateService) {
    this.libertyHelplineShort = AppConstants.LIBERTY_HELPLINE_SHORT;
    this.libertyHelplineFull = AppConstants.LIBERTY_HELPLINE_FULL_WITHOUT_DASH;
    this.libertyMailSupport = AppConstants.LIBERTY_CONTACT_SUPPORT_EMAIL;
  }

  ngOnInit(): void {
  }

  callSupport(): void {
    window.open(`tel:${this.libertyHelplineFull}`);
    // window.open(`Call Customer Helpline`);
  }

  mailSupport(): void {
    window.open(`mailto:${this.libertyMailSupport}`);
  }

  get getCurrentLanguage(): string {
    return this.imtTranslationService.getCurrentLanguage();
  }

  getAlternativeLanguage(): Language {
    return this.getCurrentLanguage === 'en' ? Language.th : Language.en;
  }

  setLanguage(language: Language) {
    this.imtTranslationService.updateLanguage(language);
  }

  changeLanguage() {
    this.setLanguage(this.getAlternativeLanguage());
  }

  isEnglishLanguage() {
    return 'en' === this.getCurrentLanguage;
  }

}
