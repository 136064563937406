export class DeviceInfo {
    userAgent: string;
    os: string;
    browser: string;
    device: string;
    deviceCategory: string;
    osVersion: string;
    browserVersion: string;
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;

    
}
