<app-header xmlns="http://www.w3.org/1999/html"></app-header>
<div *ngIf="!isLoading, else spinner">
  <div class="wrapper" [class.Font-Thai]="!isEnglishSelected">
    <figure class="mb-4">
      <img alt="Tesla Cars" src="assets/tesla-cars-banner.png" class="img-fluid">
    </figure>

    <div class="row margin-left-right-zero">
      <section class="mt-4 mb-3 col-10 col-sm-4 col-md-4 col-lg-4 mx-sm-auto">

        <div class="text-center mx-1 mx-sm-auto">
          <div>
            <h5 class="my-3 ls-1">{{ 'customer.hi' | translate }} {{cusName}}</h5>
          </div>
        </div>
        <div class="text-center mx-1 mx-sm-auto">
          <div>
            <p class=" my-3 ls-1">{{ 'customer.description' | translate }}</p>
          </div>
        </div>
      </section>
    </div>
    <div class="row margin-left-right-zero">
      <section class="mb-4 mb-3 col-10 col-sm-10 col-md-10 col-lg-8 mx-sm-auto">
        <div class="text-center mx-1 mx-sm-auto">
          <form #editCustomerForm="ngForm" autocomplete="off" (ngSubmit)="submit(editCustomerForm)" appFocus>
            <div class="text-center mx-1 mx-sm-auto">
              <div *ngIf="showAlert" class="mt-3 mb-4">
                <ngb-alert [type]="'custom'" class="alert-home-custom">
                  <ng-container>
                    {{ errMsg }}
                  </ng-container>
                </ngb-alert>
              </div>
            </div>
            <div class="row">
              <p class="text-start font-weight-bold">{{ 'customer.insuredInfo' | translate}}</p>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                <div class="select-toggle p-relative">
                  <p class="text-start m-0">{{ 'customer.salutation' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <input type="text" class="form-control" [disabled]=true [(ngModel)]="title" id="title" name="title">
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                <div class="select-toggle p-relative">
                  <p class="text-start m-0">{{ 'customer.insuredName' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <input type="text" class="form-control" [disabled]=true [(ngModel)]="insuredName" id="insuredName"
                    name="insuredName">
                </div>
              </div>
            </div>
            <div class="row">
              <ng-container *ngIf="!isInsuredCompany">
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                  <div class="select-toggle p-relative">
                    <p class="text-start m-0">{{ 'customer.identification' | translate}}</p>
                  </div>
                  <div class="select-toggle p-relative">
                    <input type="text" class="form-control" [disabled]=true [(ngModel)]="idCardNumber"
                      id="identificationNumber" name="identificationNumber">
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="isInsuredCompany">
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                  <div class="select-toggle p-relative">
                    <p class="text-start m-0">{{ 'customer.companyTaxId' | translate}}</p>
                  </div>
                  <div class="select-toggle p-relative">
                    <input type="text" class="form-control" [disabled]=true [(ngModel)]="companyTaxId"
                           id="taxId" name="taxId">
                  </div>
                </div>
              </ng-container>
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                <div class="select-toggle p-relative">
                  <p class="text-start m-0">{{ 'customer.phone' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <input type="text" class="form-control" [disabled]=true [(ngModel)]="phoneNumber" maxlength="10" id="phoneNumber" name="phoneNumber"
                    required (keydown)="onKeyDown($event, 'phoneNumber')">
                  <div class="input-error" *ngIf="!isValidPhoneNumber">
                    {{ 'common.numbericInputError' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                <div class="select-toggle p-relative">
                  <!--IMTT-181-->
                  <ng-container *ngIf="this.emailRequired">
                    <p class="text-start m-0 required">
                      {{ 'customer.email' | translate}}</p>
                  </ng-container>
                  <ng-container *ngIf="!this.emailRequired">
                    <p class="text-start m-0">
                      {{ 'customer.email' | translate}}</p>
                  </ng-container>
                </div>
                <!--  <div class="select-toggle p-relative">
                    <input type="text" class="form-control" [(ngModel)]="email" id="email" name="email">
                  </div>-->

                <div class="select-toggle p-relative">
                  <ng-container *ngIf="this.emailRequired">
                    <input type="text" class="form-control" [(ngModel)]="email" id="email2" name="email2"
                      [required]="true" #emailControl="ngModel" maxlength = "100" (keydown)="onKeyDown($event, 'email')">

                    <ng-container *ngIf="emailControl?.errors">
                      <div class="input-error" *ngIf="emailControl?.errors.required">
                        {{'common.require' | translate}}
                      </div>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="!this.emailRequired">
                    <input type="text" class="form-control" [(ngModel)]="email" id="email3" name="email2">
                  </ng-container>
                </div>
              </div>

              <ng-container *ngIf="!isInsuredCompany">
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                  <div class="select-toggle p-relative">
                    <p class="text-start m-0 required">{{ 'customer.dateOfBirth' | translate}}</p>
                  </div>
                  <div class="row mt-0 mt-md-2">
                    <div class="p-relative">
                      <input type="text" class="form-control padding-left-2rem insertion-caret-hidden" id="dob"
                        name="dob" placeholder="DD/MM/YYYY" bsDatepicker #dp="bsDatepicker" appSuppressKeys
                        [required]="true" #dobControl="ngModel" [(ngModel)]="insuredDateOfBirth" [bsConfig]="{
                                  isAnimated: true,
                                  adaptivePosition: true,
                                  dateInputFormat: 'DD/MM/YYYY',
                                  containerClass: 'theme-dark-blue'}">

                      <img src="assets/calendar-icon.png" class="inp-cal-icon" (click)="dp.toggle()">
                    </div>
                    <ng-container *ngIf="dobControl?.errors">
                      <div class="input-error" *ngIf="dobControl?.errors.required">
                        {{'common.require' | translate}}
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
               <ng-container *ngIf="isInsuredCompany">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-6 form-group">
                      <div class="select-toggle p-relative">
                       <!-- <p class="text-start m-0">{{ 'customer.beneficiary' | translate}}</p>-->
                      </div>
                      <div class="select-toggle p-relative">
                        <input type="hidden" class="form-control" [disabled]=true [(ngModel)]="beneficiary" id="beneficiary2"
                               name="beneficiary2">
                      </div>
                    </div>
                </ng-container>
              </div>
              <!--<div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                  <div class="select-toggle p-relative">
                    <p class="text-start m-0">{{ 'customer.occupation' | translate}}</p>
                  </div>
                  <div class="select-toggle p-relative">
                    <input type="text" class="form-control" [disabled]=true [(ngModel)]="ocupation" id="ocupation"
                           name="ocupation">
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-12 col-lg-6 form-group">
                  <div class="select-toggle p-relative">
                    <p class="text-start m-0">{{ 'customer.beneficiary' | translate}}</p>
                  </div>
                  <div class="select-toggle p-relative">
                    <input type="text" class="form-control" [disabled]=true [(ngModel)]="beneficiary" id="beneficiary"
                           name="beneficiary">
                  </div>
                </div>
              </div>-->

            <div class="row">
              <p class="text-start font-weight-bold">{{ 'customer.insuredAddress' | translate}}</p>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                <div class="select-toggle p-relative">
                  <p class="text-start m-0 required">{{ 'customer.addressLine1' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <input type="text" class="form-control" [(ngModel)]="postAddress1" id="postAddress1"
                    name="postAddress1" required #postAddress1Control="ngModel" maxlength="50" (keydown)="onKeyDown($event, 'postAddress1')">
                </div>
                <ng-container *ngIf="postAddress1?.length > 50">
                  <div class="input-error" >
                    {{ 'customer.addressLine1' | translate }}
                    {{ 'common.exceedCharacters' | translate }}
                  </div>
                </ng-container>
                <ng-container *ngIf="postAddress1Control?.errors">
                  <div class="input-error" *ngIf="postAddress1Control?.errors.required">
                    {{'common.require' | translate}}
                  </div>
                </ng-container>
              </div>

              <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                <div class="select-toggle p-relative">
                  <p class="text-start m-0">{{ 'customer.addressLine2' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <input type="text" class="form-control" [(ngModel)]="postAddress2" id="postAddress2"
                    name="postAddress2" maxlength="50" (keydown)="onKeyDown($event, 'postAddress2')">
                </div>
                <ng-container *ngIf="postAddress2?.length > 50">
                  <div class="input-error" >
                    {{ 'customer.addressLine2' | translate }}
                    {{ 'common.exceedCharacters' | translate }}
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                <div class="select-toggle p-relative">
                  <p class="text-start m-0 required">{{ 'customer.province' | translate}}</p>
                </div>
                <mat-select [(ngModel)]="postProvinceAegisCode" class="form-control text-left" id="postProvince"
                  name="postProvince" required #postProvinceControl="ngModel">
                  <mat-option [id]="'province' + i" *ngFor="let province of provincesJson; index as i;"
                    value="{{province.aegisCode}}" (click)="updatePostDistrict(province.aegisCode)">
                    {{!isEng ? province.nameThai : province.nameEng}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="postProvinceControl.hasError('required')"> {{'common.require' |
                  translate}}</mat-error>
              </div>

              <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                <div class="select-toggle p-relative">
                  <p class="text-start m-0 required">{{ 'customer.district' | translate}}</p>
                </div>
                <mat-select [(ngModel)]="postDistrictCode" class="form-control text-left" id="postDistrict"
                  name="postDistrict" required #postDistrictControl="ngModel">
                  <mat-option [id]="'district' + i" *ngFor="let district of postDistrictsJson; index as i;"
                    value="{{district.districtCode}}"
                    (click)="updatePostSubDistrict(district.districtCode, district.provinceCode)">
                    {{!isEng ? district.districtThaiName : district.districtEngName}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="postDistrictControl.hasError('required')"> {{'common.require' |
                  translate}}</mat-error>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                <div class="select-toggle p-relative">
                  <p class="text-start m-0 required">{{ 'customer.subDistrict' | translate}}</p>
                </div>
                <mat-select [(ngModel)]="postSubDistrictCode" class="form-control text-left" id="postSubdistrict"
                  name="postSubdistrict" required #postSubDistrictControl="ngModel">
                  <mat-option [id]="'sub-district' + i" *ngFor="let subDistrict of postSubDistrictsJson; index as i;"
                    value="{{subDistrict.tumbonCode}}"
                    (click)="updatePostPostCode(subDistrict.tumbonCode, subDistrict.postalCode)">
                    {{!isEng ? subDistrict.tumbonThaiName : subDistrict.tumbonEngName}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="postSubDistrictControl.hasError('required')"> {{'common.require' |
                  translate}}</mat-error>
              </div>

              <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                <div class="select-toggle p-relative">
                  <p class="text-start m-0 required">{{ 'customer.postCode' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <input type="text" class="form-control" [(ngModel)]="postPostCode" maxlength="5" id="postPostCode" name="postPostCode"
                    #postPostCodeControl="ngModel" (keydown)="onKeyDown($event, 'postPostCode')">
                  <div class="input-error" *ngIf="!isValidPostPostCode">
                    {{ 'common.numbericInputError' | translate }}
                  </div>
                </div>
                <ng-container *ngIf="postPostCodeControl?.errors">
                  <div class="input-error" *ngIf="postPostCodeControl?.errors['required']">
                    {{ 'common.require' | translate }}
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="row">
              <p class="text-start font-weight-bold">{{ 'customer.billingAddress' | translate}}</p>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 form-group text-left check-box-item">
                <label class="radio-inline">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="isUsesInsuredAddress"
                    (change)="isUsesInsuredAddressEnable(isUsesInsuredAddress)" name="isUsesInsuredAddressEnable"  />{{
                  'customer.usesInsuredAddress' | translate}}
                </label>
              </div>
            </div>
           <ng-container *ngIf="!isUsesInsuredAddress">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                  <div class="select-toggle p-relative">
                    <p class="text-start m-0 required">{{ 'customer.salutation' | translate}}</p>
                  </div>
                  <mat-select [(ngModel)]="billingSalutation" class="form-control text-left" id="billingSalutation"
                              name="billingSalutation" required #billingSalutationControl = "ngModel">
                    <mat-option [id]="'billingSalutation' + i" *ngFor="let salutation of salutationsJson; index as i;"
                                value="{{salutation.salutation}}" (click)="updateSalutation(salutation.salutation, salutation.insuredType)">
                      {{salutation.salutation}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="billingSalutationControl.hasError('required')"> {{'common.require' | translate}}</mat-error>
              </div>

              <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                <div class="select-toggle p-relative">
                  <p class="text-start m-0 required">{{ 'customer.billingName' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <input type="text" class="form-control" [(ngModel)]="billingName" id="billingName" name="billingName"
                    required #billingNameControl="ngModel" maxlength="250" (keydown)="onKeyDown($event, 'billingName')">
                </div>
                <ng-container *ngIf="billingNameControl?.errors">
                  <div class="input-error" *ngIf="billingNameControl?.errors['required']">
                    {{ 'common.require' | translate }}
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                <div class="select-toggle p-relative">
                  <p class="text-start m-0 required">{{ 'customer.billingAddress1' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <input type="text" class="form-control" [(ngModel)]="billingAddress1" id="billingAddress1"
                    name="billingAddress1" required #billingAdress1Control="ngModel" maxlength="50" (keydown)="onKeyDown($event, 'billingAddress1')">
                </div>
                <ng-container *ngIf="billingAddress1?.length > 50">
                  <div class="input-error" >
                    {{ 'customer.billingAddress' | translate }}
                    {{ 'common.exceedCharacters' | translate }}
                  </div>
                </ng-container>
                <ng-container *ngIf="billingAdress1Control?.errors">
                  <div class="input-error" *ngIf="billingAdress1Control?.errors['required']">
                    {{ 'common.require' | translate }}
                  </div>
                </ng-container>
              </div>

              <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                <div class="select-toggle p-relative">
                  <p class="text-start m-0">{{ 'customer.billingAddress2' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <input type="text" class="form-control" [(ngModel)]="billingAddress2" id="billingAddress2"
                    name="billingAddress2" maxlength="50" (keydown)="onKeyDown($event, 'billingAddress2')">
                </div>
                <ng-container *ngIf="billingAddress2?.length > 50">
                  <div class="input-error" >
                    {{ 'customer.billingAddress2' | translate }}
                    {{ 'common.exceedCharacters' | translate }}
                  </div>
                </ng-container>

              </div>
            </div>

            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                <div class="select-toggle p-relative">
                  <p class="text-start m-0 required">{{ 'customer.province' | translate}}</p>
                </div>
                <mat-select [(ngModel)]="billingProvinceAegisCode" class="form-control text-left" id="billingProvince"
                  name="billingProvince" required #billingProvinceControl="ngModel">
                  <mat-option [id]="'billingProvince' + i" *ngFor="let provinceJ of provincesJson; index as i;"
                    value="{{provinceJ.aegisCode}}" (click)="updateBillingDistrict(provinceJ.aegisCode)">
                    {{!isEng ? provinceJ.nameThai : provinceJ.nameEng}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="billingProvinceControl.hasError('required')"> {{'common.require' |
                  translate}}</mat-error>
              </div>

              <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                <div class="select-toggle p-relative">
                  <p class="text-start m-0 required">{{ 'customer.district' | translate}}</p>
                </div>
                <mat-select [(ngModel)]="billingDistrictCode" class="form-control text-left" id="billingDistrict"
                  name="billingDistrict" required #billingDistrictControl="ngModel">
                  <mat-option [id]="'billingDistrict' + i" *ngFor="let districtJ of billingDistrictsJson; index as i;"
                    value="{{ districtJ.districtCode }}"
                    (click)="updateBillingSubDistrict(districtJ.districtCode, districtJ.provinceCode)">
                    {{!isEng ? districtJ.districtThaiName : districtJ.districtEngName}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="billingDistrictControl.hasError('required')"> {{'common.require' |
                  translate}}</mat-error>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                <div class="select-toggle p-relative">
                  <p class="text-start m-0 required">{{ 'customer.subDistrict' | translate}}</p>
                </div>
                <mat-select [(ngModel)]="billingSubDistrictCode" class="form-control text-left" id="billingSubDistrict"
                  name="billingSubDistrict" required #billingSubDistrictControl="ngModel">
                  <mat-option>---</mat-option>
                  <mat-option [id]="'billingSubDistrict' + i"
                    *ngFor="let subdistrictJ of billingSubDistrictsJson; index as i;"
                    value="{{subdistrictJ.tumbonCode}}"
                    (click)="updateBillingPostCode(subdistrictJ.tumbonCode, subdistrictJ.postalCode)">
                    {{!isEng ? subdistrictJ.tumbonThaiName : subdistrictJ.tumbonEngName}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="billingSubDistrictControl.hasError('required')"> {{'common.require' |
                  translate}}</mat-error>
              </div>

              <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                <div class="select-toggle p-relative">
                  <p class="text-start m-0 required">{{ 'customer.postCode' | translate}}</p>
                </div>
                <div class="select-toggle p-relative">
                  <input maxLength="5" type="text" class="form-control" [(ngModel)]="billingPostCode" id="billingPostCode"
                    name="billingPostCode" required #billingPostCodeControl="ngModel" (keydown)="onKeyDown($event, 'billingPostCode')">
                  <div class="input-error" *ngIf="!isValidBillingPostCode">
                    {{ 'common.numbericInputError' | translate }}
                  </div>
                </div>
                <ng-container *ngIf="billingPostCodeControl?.errors">
                  <div class="input-error" *ngIf="billingPostCodeControl?.errors['required']">
                    {{ 'common.require' | translate }}
                  </div>
                </ng-container>
              </div>
            </div>

            <!-- <ng-container *ngIf="this.billingInsuredType==='P'">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12 col-lg-6 form-group">
                        <div class="select-toggle p-relative">
                          <p class="text-start required">{{ 'customer.idCard_Passport' | translate}}</p>
                        </div>
                        <div class="select-toggle p-relative">
                          <input type="text" class="form-control" [(ngModel)]="idCardNumber" id="idCard_Passport"
                                name="idCard_Passport" required>
                        </div>
                      </div>
                    </div>
                  </ng-container>-->
                <ng-container *ngIf="this.billingInsuredType==='C'">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-6 form-group">
                      <div class="select-toggle p-relative">
                        <p class="text-start m-0 required">{{ 'customer.companyTaxId' | translate}}</p>
                      </div>
                      <div class="select-toggle p-relative">
                        <input type="text" class="form-control" [(ngModel)]="billingTaxIdNumber" id="taxId1"
                              name="taxId1" required ng-pattern="/^[0-9]*$/" maxlength="13" (keydown)="onKeyDown($event, 'taxId')">
                              <div class="input-error" *ngIf="!isValidTaxId">
                                {{ 'common.numbericInputError' | translate }}
                              </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-6 form-group">
                      <div class="select-toggle p-relative">
                        <p class="text-start m-0 required">{{ 'customer.taxBranchCode' | translate}}</p>
                      </div>
                      <div class="select-toggle p-relative">
                        <input type="text" class="form-control" [(ngModel)]="billingTaxBranchCode" id="taxBranchCode"
                              name="taxBranchCode" required ng-pattern="/^[0-9]*$/" maxlength="6" (keydown)="onKeyDown($event, 'taxBranchCode')">
                              <div class="input-error" *ngIf="!isValidTaxBranchCode">
                                {{ 'common.numbericInputError' | translate }}
                              </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
            </ng-container>
            <div class="row">
              <p class="text-start font-weight-bold">{{ 'customer.driverDetail' | translate}}</p>
            </div>
            <div *ngFor="let driver of driverList, let i = index ">
              <div class="row">
                <p class="text-start font-weight-bold">{{ 'customer.driver' | translate}} {{i + 1}}
                  <i class="fa-solid fa-x float-end clr-red" (click)="removeDriver(i)" type="button" id="removeDriver{{i}}"></i>
                </p>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                  <div class="select-toggle p-relative">
                    <p class="text-start m-0 required">{{ 'customer.driverName' | translate}}</p>
                  </div>
                  <div class="select-toggle p-relative">
                    <input type="text" class="form-control" [(ngModel)]="driver.name" id="driverName{{i}}"
                      name="driverName{{i}}" maxlength="200" required #driverNameControl="ngModel">
                  </div>
                  <ng-container *ngIf="driverNameControl?.errors">
                    <div class="input-error" *ngIf="driverNameControl?.errors.required">
                      {{'common.require' | translate}}
                    </div>
                  </ng-container>
                </div>

                <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                  <div class="select-toggle p-relative">
                    <p class="text-start m-0 required">{{ 'customer.dateOfBirth' | translate}}</p>
                  </div>
                  <div class="select-toggle p-relative">
                    <input type="text" class="form-control padding-left-3rem insertion-caret-hidden" id="driverDateOfBirth{{i}}"
                           name="driverDateOfBirth{{i}}" placeholder="DD/MM/YYYY" bsDatepicker #dp="bsDatepicker" appSuppressKeys
                           [required]="true" #driverdobControl="ngModel" [(ngModel)]="driver.dateOfBirth" [bsConfig]="{
                                  isAnimated: true,
                                  adaptivePosition: true,
                                  dateInputFormat: 'DD/MM/YYYY',
                                  containerClass: 'theme-dark-blue'}">

                    <img src="assets/calendar-icon.png" class="inp-cal-icon" (click)="dp.toggle()">
                  </div>
                  <ng-container *ngIf="driverdobControl?.errors">
                    <div class="input-error" *ngIf="driverdobControl?.errors.required">
                      {{'common.require' | translate}}
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                  <div class="select-toggle p-relative">
                    <p class="text-start m-0 required">{{ 'customer.gender' | translate}}</p>
                  </div>
                  <div class="select-toggle p-relative">
                    <mat-select [(ngModel)]="driver.sex" class="form-control text-left" id="driverGender{{i}}"
                                name="driverGender{{i}}" required #driverGenderTypeControl="ngModel">
                      <mat-option id="{{genderJ.code}}" *ngFor="let genderJ of genderJson; index as j;"
                                  value="{{genderJ.code}}">
                        {{!isEng ? genderJ.nameThai : genderJ.nameEng}}
                      </mat-option>
                    </mat-select>
                    <ng-container *ngIf="driverGenderTypeControl?.errors">
                      <div class="input-error" *ngIf="driverGenderTypeControl?.errors['required']">
                        {{ 'common.require' | translate }}
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 form-group">
                  <div class="select-toggle p-relative">
                    <p class="text-start m-0 required">{{ 'customer.identificationType' | translate}}</p>
                  </div>
                  <div class="select-toggle p-relative">
                    <mat-select [(ngModel)]="driver.identificationType" class="form-control text-left" id="identificationType{{i}}"
                                name="identificationType{{i}}" required #driverIdentificationTypeControl="ngModel">
                      <mat-option id="{{identificationJ.code}}" *ngFor="let identificationJ of identificationJson; index as j;"
                                  value="{{identificationJ.code}}" (click)="updateIdentificationType(identificationJ.code, i)">
                        {{!isEng ? identificationJ.nameThai : identificationJ.nameEng}}
                      </mat-option>
                    </mat-select>
                    <ng-container *ngIf="driverIdentificationTypeControl?.errors">
                      <div class="input-error" *ngIf="driverIdentificationTypeControl?.errors['required']">
                        {{ 'common.require' | translate }}
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div class="row">
                <div *ngIf="isIdCard[i]" class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                  <div class="select-toggle p-relative">
                    <p class="text-start m-0 required">{{ 'customer.idCard' | translate}}</p>
                  </div>
                  <div class="select-toggle p-relative">
                    <input type="text" class="form-control" [(ngModel)]="driver.idCardNumber"
                           id="driverIdCardNumber{{i}}" name="driverIdCardNumber{{i}}"
                           pattern="^[0-9]{0,13}$" maxlength="13" required #driverIdCardNumberControl="ngModel">
                  </div>
                  <ng-container *ngIf="driverIdCardNumberControl?.errors">
                    <div class="input-error" *ngIf="driverIdCardNumberControl?.errors.required">
                      {{'common.require' | translate}}
                    </div>
                    <div class="input-error" *ngIf="driverIdCardNumberControl?.errors?.pattern">
                      <span>{{ 'customer.idCard' | translate}} {{'common.invalid' | translate}}</span>
                    </div>
                  </ng-container>
                </div>

                <div *ngIf="isPassport[i]" class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                  <div class="select-toggle p-relative">
                    <p class="text-start m-0 required">{{ 'customer.passport' | translate}}</p>
                  </div>
                  <div class="select-toggle p-relative">
                    <input type="text" class="form-control" [(ngModel)]="driver.passportNumber"
                           id="passportNumber{{i}}" name="passportNumber{{i}}" required #driverPassportControl="ngModel"
                           pattern="^[a-zA-Z0-9]{0,10}$" maxlength="10">
                  </div>
                  <ng-container *ngIf="driverPassportControl?.errors">
                    <div class="input-error" *ngIf="driverPassportControl?.errors.required">
                      {{'common.require' | translate}}
                    </div>
                    <div class="input-error" *ngIf="driverPassportControl?.errors?.pattern">
                      <span>{{ 'customer.passport' | translate}} {{'common.invalid' | translate}}</span>
                    </div>
                  </ng-container>
                </div>
                <ng-container>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-6 mx-sm-auto form-group">
                    <div class="select-toggle p-relative">
                      <p class="text-start m-0 required">{{ 'customer.drivingLicenceNo' | translate}}</p>
                    </div>
                    <div class="select-toggle p-relative">
                      <input type="text" class="form-control" [(ngModel)]="driver.licenseNumber"
                             id="drivingLicenceNo{{i}}" name="drivingLicenceNo{{i}}" required #driverLicenseNumberControl="ngModel"
                             maxlength="20" pattern="^[a-zA-Z0-9]{0,20}$">
                    </div>
                    <ng-container *ngIf="driverLicenseNumberControl?.errors">
                      <div class="input-error" *ngIf="driverLicenseNumberControl?.errors.required">
                        {{'common.require' | translate}}
                      </div>
                      <div class="input-error" *ngIf="driverLicenseNumberControl?.errors?.pattern">
                        <span>{{ 'customer.drivingLicenceNo' | translate}} {{'common.invalid' | translate}}</span>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 form-group">
                  <div class="select-toggle p-relative">
                    <p class="text-start m-0 required">{{ 'customer.occupation' | translate}}</p>
                  </div>
                  <div class="select-toggle p-relative">
                    <mat-select [(ngModel)]="driver.occupationCode" class="form-control text-left" id="driverOccupation{{i}}"
                                name="driverOccupation{{i}}" required #driverOccupationControl="ngModel" maxlength="50">
                      <mat-option [id]="'occupation' + j" *ngFor="let occupationJ of occupationJson; index as j;"
                                  value="{{occupationJ.code}}" (click)="updateOccupation(occupationJ.nameEng, i)">
                        {{!isEng ? occupationJ.nameThai : occupationJ.nameEng}}
                      </mat-option>
                    </mat-select>
                    <ng-container *ngIf="driverOccupationControl?.errors">
                      <div class="input-error" *ngIf="driverOccupationControl?.errors['required']">
                        {{ 'common.require' | translate }}
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <p *ngIf="!driverListExist" class="text-start mb-5">
              {{ 'customer.noDriver' | translate}}
              <br/>
              <span>{{ 'customer.noDriverRequired' | translate}}</span>
            </p>
            <p *ngIf="!isdriverListExceeded">
              <span type="button" class="font-weight-bold" (click)="addDriver()">
                <i class="fa-solid fa-plus" id="addDriver"></i>
                {{ 'customer.addDriver' | translate}}
              </span>
            </p>
            <div class="mt-4">
              <div class="text-center d-flex align-items-center justify-content-center">
                <button (click)="cancel(editCustomerForm)" id="submit_btn-desktop" type="button"
                  class="btn btn-outline-primary button br-60 disable-button submit_button m-3">
                  {{ 'common.button.cancel' | translate }}
                </button>
                <button type="submit" id="edit_btn-desktop"
                  class="btn btn-primary button br-60 disable-button submit_button m-3">
                  {{ 'common.button.save' | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</div>
<ng-template #spinner>
  <ngx-spinner type="ball-spin-clockwise-fade-rotating" size="medium"></ngx-spinner>
</ng-template>
