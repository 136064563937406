import {Component, OnInit} from '@angular/core';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonService} from 'src/app/core/services/common/common.service';
import {Alert} from 'src/app/core/interfaces/alert';
import {GoogleCaptchaService} from 'src/app/core/services/google/google-captcha.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidationService} from 'src/app/core/services/validation/validation.service';
import {AppConstants} from '../../../constants/app-constants.model';
import {AuthService} from '../../../services/auth/auth.service';
import {StorageService} from '../../../services/storage/storage.service';
import {PortalApiService} from '../../../services/rest-api/portal-api.service';
import {SessionStorageService} from 'ngx-webstorage';
import {OfferResponse, PoliciesResponse, ValidateCustomerResponse} from '../../../models/response.model';
import {IMTTranslateService} from 'src/app/core/services/common/imt.translation.service';
import {catchError, map, mergeMap} from 'rxjs/operators';
import { formatDate } from "@angular/common";

interface Result {
  policy: string;
  brand: string;
  model: string;
  submodel: string;
  licenseNo: string;
  expireDate: string;

}

@Component({
  selector: 'app-policy-list',
  templateUrl: './policy-list.component.html',
  styleUrls: ['./policy-list.component.scss']
})
export class PolicyListComponent implements OnInit {
  name: string = '';
  alert: Alert;
  showAlert = false;
  enableEng = false;
  policiesResponse: PoliciesResponse;
  customer: ValidateCustomerResponse = new ValidateCustomerResponse();
  resourcesAPISubscription: Subscription;
  offers: Observable<OfferResponse> [] = [];
  resultR: Result[] = [];
  expiringPolicies: string[] = [];
  isLoading: boolean = true;

  constructor(
    private router: Router,
    private commonService: CommonService,
    private googleCaptchaService: GoogleCaptchaService,
    private spinner: NgxSpinnerService,
    private validationService: ValidationService,
    private authService: AuthService,
    private storageService: StorageService,
    private portalApiService: PortalApiService,
    private sessionStorage: SessionStorageService,
    private route: ActivatedRoute,
    private imtTranslateService: IMTTranslateService
  ) {
    this.spinner.show();
  }

  ngOnInit(): void {
    window.scrollTo({top: 0});
    this.name = this.sessionStorage.retrieve(AppConstants.STORAGE_CUSTOMER_NAME) as string;
    let idCard = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_IDCARD) as string;
    const passport = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_PASSPORT) as string;
    const companyTaxId = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_COMPANY_TAX_ID) as string;
    this.portalApiService
      .policies(idCard, passport, companyTaxId)
      .pipe(
        mergeMap(policies => {
          console.log('policies', policies);
          this.expiringPolicies = policies.expiringPolicies;
          console.log('policies.expiringPolicies', policies.expiringPolicies);
          if (this.expiringPolicies.length === 0) {
            this.isLoading  = false;
          }
          const apiArray = this.expiringPolicies.map(policy => {
            return this.portalApiService.offer(idCard, passport, companyTaxId, policy);
          });
          if (apiArray.length === 0) {
            this.isLoading  = false;
            return [];
          }

          return forkJoin(...apiArray).pipe(
            map((apiData: OfferResponse[]) => {
              console.log('apiData', apiData);
              console.log('expiringPolicies.forEach', this.expiringPolicies);
              this.expiringPolicies.forEach((originalvalue, index) => {
                const expiryDate = originalvalue.includes('AV') ? apiData[index]?.policyData?.policyDetails?.voluntaryEndDate :
                  apiData[index]?.policyData?.policyDetails?.compulsoryEndDate;
                this.resultR.push({
                  policy: originalvalue,
                  brand: apiData[index]?.policyData?.vehicleDetails?.make,
                  model: apiData[index]?.policyData?.vehicleDetails?.model,
                  submodel: apiData[index]?.policyData?.vehicleDetails?.subModel,
                  expireDate: expiryDate ? formatDate(expiryDate, 'dd/MM/yyyy', 'en-US') : '',
                  licenseNo: apiData[index]?.policyData?.vehicleDetails?.licenseNumber
                });
              });
              console.log('this.resultR', this.resultR);
              return this.resultR;
            }),
            catchError((e) => {
              console.log('e', e);
              return of(e);
            })
          );
        })
      ).subscribe((response) => {
        if (response) {
          this.isLoading  = false;
        }
      },
      (error) => {
        this.isLoading  = true;
        if (error.status === 400 && error.error.code === 'VALIDATION_FAILED') {
          this.router.navigate(['']);
        } else {
          this.isLoading  = false;
        }
      });
  }

  closeAlert(alert: Alert): void {
    this.showAlert = false;
  }


  isEnglishSelected(): boolean {
    return 'en' === this.imtTranslateService.getCurrentLanguage();
  }
}
