import { Directive, Input, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appValidateOnBlur]'
})
export class ValidateOnBlurDirective {

  @Input('appValidateOnBlur')
  validateFormControl: NgModel;

  constructor() { }

  @HostListener('focus') onFocus(): void {
    this.validateFormControl.control.markAsUntouched();
  }

  @HostListener('focusout') onFocusOut(): void {
    this.validateFormControl.control.markAsTouched();
  }
}
