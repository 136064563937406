import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {OfferCoverageDetail, OfferResponse} from '../../../models/response.model';
import {StorageService} from '../../../services/storage/storage.service';
import {PortalApiService} from '../../../services/rest-api/portal-api.service';
import {SessionStorageService} from 'ngx-webstorage';
import {IMTTranslateService} from 'src/app/core/services/common/imt.translation.service';
import {AppConstants} from '../../../constants/app-constants.model';
import {NgForm} from '@angular/forms';
import {CoverageDetail} from "../../../models/policy-coverage.model";
import {StorageType} from "../../../enum/storage-type.enum";

@Component({
  selector: 'app-policy-coverage',
  templateUrl: './policy-coverage.component.html',
  styleUrls: ['./policy-coverage.component.scss']
})
export class PolicyCoverageComponent implements OnInit {
  expiringPolicyNumber: string;
  offerResponse: OfferResponse;
  showAlert = false;
  disableSubmitBtn = false;
  coverageDetail: OfferCoverageDetail;
  name: string;
  grandTotalPremium: number;
  renewalType: string = 'AVAC';
  isLoading: boolean = true;
  compulsoryGrossPremium: string;
  display = "none";

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private storageService: StorageService,
    private portalApiService: PortalApiService,
    private sessionStorage: SessionStorageService,
    private route: ActivatedRoute,
    private imtTranslationService: IMTTranslateService,
    private cdr: ChangeDetectorRef
  ) {
    this.spinner.show();
  }

  openModal() {
    this.display = "block";
  }
  onCloseHandled() {
    this.display = "none";
  }
  ngOnInit(): void {
    window.scrollTo({top: 0});
    this.imtTranslationService.onLanguageChanged.subscribe(() => {
      this.cdr.detectChanges();
    });

    this.name = this.sessionStorage.retrieve(AppConstants.STORAGE_CUSTOMER_NAME) as string;
    const idCard = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_IDCARD) as string;
    const passport = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_PASSPORT) as string;
    const companyTaxId = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_COMPANY_TAX_ID) as string;
    this.expiringPolicyNumber = this.route.snapshot.paramMap.get('expiringPolicyNumber');
    this.storageService.store(StorageType.SESSION, AppConstants.STORAGE_KEY_RENEWAL_TYPE, this.renewalType);

    this.portalApiService
      .offer(idCard, passport, companyTaxId, this.expiringPolicyNumber)
      .subscribe((response: OfferResponse) => {
        if (response) {
          this.isLoading = false;
          if (response.status === 'Success') {
            this.offerResponse = response;
            this.setInput(this.offerResponse);
            if (response.policyData?.coverageDetails) {
              this.coverageDetail = response.policyData.coverageDetails;
              this.compulsoryGrossPremium = this.coverageDetail?.premiumBreakdown?.compulsoryGrossPremium;
            }
          }
        }
      },
        (error) => {
          this.isLoading  = true;
          if (error.status === 400 && error.error.code === 'VALIDATION_FAILED') {
            this.router.navigate(['']);
          } else {
            this.isLoading  = false;
          }
        });
  }

  setInput(offerResponse: OfferResponse) {
    this.grandTotalPremium = this.getGrandTotalPremium(offerResponse?.policyData?.coverageDetails?.premiumBreakdown?.grossPremium,
      offerResponse?.policyData?.coverageDetails?.premiumBreakdown?.compulsoryGrossPremium);
  }

  getSeverity(status: string): string {
    switch (status) {
      case 'INSTOCK':
        return 'success';
      case 'LOWSTOCK':
        return 'warning';
      case 'OUTOFSTOCK':
        return 'danger';
    }
  }

  submit(offerForm: NgForm): void {
    this.disableSubmitBtn = true;
    this.router.navigate(['/customer-detail', {expiringPolicyNumber : this.expiringPolicyNumber}]);
  }


  getPolicyGeneral(coverageDetail: CoverageDetail): string {
   /* if (this.policyCoverage.coverageDetails.indexOf(coverageDetail) === 0) {
      return 'policy.expiring';
    } else {
      return 'policy.renewal';
    }*/
    return ''
  }

  getGrandTotalPremium(totalPremiumAV: string, totalPremiumAC: string): number {
    if (this.renewalType === 'AV') {
      return totalPremiumAV ? parseFloat(totalPremiumAV) : 0;
    } else {
      if (totalPremiumAV && totalPremiumAC) {
        return parseFloat(totalPremiumAV) + parseFloat(totalPremiumAC);
      } else {
        if (totalPremiumAV) {
          return parseFloat(totalPremiumAV)
        } else {
          return totalPremiumAC ? parseFloat(totalPremiumAC) : 0;
        }
      }
    }
  }

  isEnglishSelected(): boolean {
    return 'en' === this.imtTranslationService.getCurrentLanguage();
  }

  renewalTypeChange(): void {
    this.compulsoryGrossPremium = this.renewalType === 'AV' ?  '-' : this.coverageDetail?.premiumBreakdown?.compulsoryGrossPremium;
    this.storageService.store(StorageType.SESSION, AppConstants.STORAGE_KEY_RENEWAL_TYPE, this.renewalType);
  }

  backToPreviousPage() {
    this.router.navigate(['/policies', ]);
  }
}
