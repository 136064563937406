export const CLIENT_SERVER_FIELDNAMES_DATA_MAP = new Map(
    [
        ['vehicleDetails.regnYear', 'Year of Registration']
    ]
);

export const CLIENT_SERVER_ERRORCODES_DATA_MAP = new Map(
    [
        ['ERR_01', ' - Required'],
        ['ERR_02', ' - Pattern criteria violated'],
        ['ERR_03', ' - Maxlength criteria violated'],
        ['ERR_04', ' - Minlength criteria violated'],
        ['ERR_05', ' - Invalid'],
        ['ERR_11', ' entered is either Invalid or Expired']
    ]
);
