<app-header></app-header>
<div *ngIf="!isLoading, else spinner">
  <div class="wrapper" [class.Font-Thai]="!isEnglishSelected">
    <figure class="mb-4">
      <img alt="Tesla Cars" src="assets/tesla-cars-banner.png" class="img-fluid">
    </figure>

    <div class="row">
      <div class="col-10 col-sm-5 col-md-10 col-lg-10 mx-sm-auto">
        <section class="mt-4 mb-3">

          <div class="text-center mx-1 mx-sm-auto">
            <div>
              <h5 class="my-3 ls-1">{{ 'common.hi' | translate }} {{name}}</h5>
            </div>
          </div>
          <div class="text-center mx-1 mx-sm-auto" *ngIf="resultR.length > 0">
            <div>
              <p class=" my-3 ls-1 mb-0">{{ 'policy.list.title1' | translate }}</p>
              <p class=" my-3 ls-1 title2 mt-0">{{ 'policy.list.title2' | translate }}</p>
            </div>
          </div>
        </section>

        <section class="mb-4 mb-3">
          <div class="main-container text-center mx-1 mx-sm-auto">
            <form #customerForm="ngForm" autocomplete="off">
              <div class="row d-flex flex-column justify-content-center">
                <ng-container *ngFor="let policy of resultR; index as i">
                  <div class="col-12 form-group text-left border rounded policy-item mx-sm-auto"
                       [routerLink]="['/policy-coverage', {expiringPolicyNumber: policy.policy }]">
                    <p><b>{{ 'policy.list.policyNumber' | translate }}: </b>{{policy.policy}}</p>
                    <p><b>{{ 'policy.list.carDetail' | translate}}: </b>{{policy.brand}} {{policy.model}} {{policy.submodel}}</p>
                    <p><b>{{ 'policy.list.license' | translate }}: </b>{{policy.licenseNo}}</p>
                    <p><b>{{ 'policy.list.expiryDate' | translate}}: </b>{{policy.expireDate}}</p>
                  </div>
                </ng-container>
              </div>
            </form>
          </div>
        </section>

        <section class="mt-4 mb-3" *ngIf="resultR.length === 0">
          <div class="text-center mx-1 mx-sm-auto">
            <div>
              <p class="my-3 ls-1">{{ 'policy.list.noExpiringPolicy' | translate }}</p>
            </div>
          </div>
        </section>

      </div>
    </div>
  </div>
</div>
<ng-template #spinner>
  <ngx-spinner type="ball-spin-clockwise-fade-rotating" size="medium"></ngx-spinner>
</ng-template>
