import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler, HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Observable, Subject, throwError} from 'rxjs';
import {environment} from 'src/environments/environment';
import {AppConstants} from 'src/app/core/constants/app-constants.model';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {PortalApiService} from '../services/rest-api/portal-api.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    refreshTokenInProgress = false;

    tokenRefreshedSource = new Subject();
    tokenRefreshed$ = this.tokenRefreshedSource.asObservable();


    constructor(private localStorage: LocalStorageService,
        private portalApiService: PortalApiService,
        private sessionStorage: SessionStorageService,) { }

    refreshToken(): Observable<any> {
        if (this.refreshTokenInProgress) {
            return new Observable(observer => {
                this.tokenRefreshed$.subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            });
        } else {
            this.refreshTokenInProgress = true;

            return this.portalApiService.authenticateUser(this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_IDCARD),
                this.sessionStorage
                  .retrieve(AppConstants.STORAGE_KEY_PASSPORT), this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_COMPANY_TAX_ID))
                  .pipe(
                    tap(() => {
                        this.refreshTokenInProgress = false;
                        this.tokenRefreshedSource.next();
                    }),
                    catchError(error => {
                        this.refreshTokenInProgress = false;
                        return throwError(error.message);
                    }));
        }
    }

    handleResponseError(error, request?, next?) {
        // Business error
        if (error.status === 400) {
            // Show message
        }
        // Invalid token error
        else if (error.status === 401) {

            return this.refreshToken().pipe(
                switchMap(() => {
                    this.refreshTokenInProgress = false;
                    request = this.addAuthHeader(request);
                    return next.handle(request);
                }),
                catchError(err => {
                    this.refreshTokenInProgress = false;
                    return throwError(error);
                })
            );
        }
        return throwError(error);
    }

    addAuthHeader(request) {
        let token = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_JWT_TOKEN);
        if (token) {
            request = request.clone({
                setHeaders: {
                    "Authorization": token
                }
            });
        }
        let xsrf_token = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_XSRF_TOKEN) as string;
        if (xsrf_token !== null && !request.headers.has("X-XSRF-TOKEN")) {
            request = request.clone({
                setHeaders: {
                    "X-XSRF-TOKEN": xsrf_token
                }
            });
        }
        let xApiKey = environment.xApiKey as string;
        if (xApiKey !== null) {
          request = request.clone({
            setHeaders: {
              "x-api-key": xApiKey
            }
          });
        }
        return request;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = this.addAuthHeader(request);
        return next.handle(request).pipe(
          map((event) => {
            if (event.type === HttpEventType.Response) {
              if (event.url?.includes(AppConstants.OTP_VERIFY)) {
                let token = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_JWT_TOKEN);
                if (token) {
                  if (token !== event.headers.get("Authorization"))
                  {
                    event = event.clone({
                        body: {
                          status: "Fail",
                          message: "OTP is not verified"
                        },
                        status: 403,
                        statusText: "403"
                      });
                  }
                }
              }

              if (event.url?.includes(AppConstants.CHECK_CUSTOMER_AUTHENTICATED)) {
                let token = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_JWT_TOKEN);
                if (token) {
                  if (token !== event.headers.get("Authorization"))
                  {
                    event = event.clone({
                      body: {
                        statusCode: "false"
                      },
                      status: 403,
                      statusText: "403"
                    });
                  }
                }
              }
            }
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            return this.handleResponseError(error, request, next);
        })) as Observable<HttpEvent<any>>;
    }

}
