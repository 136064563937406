import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IMTTranslateService} from '../../../services/common/imt.translation.service';
import {StorageService} from '../../../services/storage/storage.service';
import {AppConstants} from '../../../constants/app-constants.model';
import {PortalApiService} from '../../../services/rest-api/portal-api.service';
import {OfferResponse, PaymentStatus, ValidateCustomerResponse} from '../../../models/response.model';
import {interval, Subscription} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {StorageType} from '../../../enum/storage-type.enum';
import {SessionStorageService} from "ngx-webstorage";
import {AuthService} from "../../../services/auth/auth.service";

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss']
})
export class PaymentStatusComponent implements OnInit {

  status = false;
  success = false;
  isIssuedSuccess = false;
  isFinance = false;
  paymentStatus: PaymentStatus;
  private subscription: Subscription;
  callCount: number;
  isLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
    private sessionStorage: SessionStorageService,
    private portalApiService: PortalApiService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private imtTranslationService: IMTTranslateService) {
    this.spinner.show()
  }

  ngOnInit(): void {
    window.scrollTo({top: 0});
    this.callCount = 0;
    const paymentUuid = this.route.snapshot.paramMap.get('uuid');
    this.startPolling(paymentUuid);
  }

  isEnglishSelected(): boolean {
    return 'en' === this.imtTranslationService.getCurrentLanguage();
  }

  startPolling(paymentUuid: string): void {
    this.subscription = interval(15000).subscribe(() => {
      if (!this.success && this.callCount < 4) {
        this.callCount = this.callCount + 1;
        this.portalApiService.paymentStatus(null, null, null, paymentUuid)
          .subscribe((response) => {
            this.success = response.paymentStatus === AppConstants.PAYMENT_STATUS_COMPLETED;
            this.storageService.store(StorageType.SESSION, AppConstants.STORAGE_KEY_IDCARD, response.idCard);
            this.storageService.store(StorageType.SESSION, AppConstants.STORAGE_KEY_PASSPORT, response.passport);
            this.storageService.store(StorageType.SESSION, AppConstants.STORAGE_KEY_COMPANY_TAX_ID, response.companyTaxId);
            this.storageService.store(StorageType.SESSION, AppConstants.STORAGE_EXPIRING_POLICY, response.paymentReferenceNumber);
            this.paymentStatus = response;

            if(this.paymentStatus.newAcPolicyNumber == null || this.paymentStatus.newAcPolicyNumber =='') {
              this.status=true;
            }
            if (this.success) {
              this.isIssuedSuccess = response.policyStatus === AppConstants.SUCCESS_PROCESS;
              console.log("this.isFinance = response.isFinance;", response.isFinance);
              this.isFinance = response.isFinance;
              this.isLoading = false;
            }
          });
        if (!this.success && this.callCount === 3) {
          this.subscription.unsubscribe();
          this.isLoading = false;
        }
      } else {
        this.subscription.unsubscribe();
        this.isLoading = false;
      }
    });
  }

  retryPayment(): void {
    this.portalApiService.authenticateUser(this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_IDCARD) ? this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_IDCARD) : '',
      this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_PASSPORT) ? this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_PASSPORT) : '',
      this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_COMPANY_TAX_ID) ? this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_COMPANY_TAX_ID) : '')
      .subscribe((response: ValidateCustomerResponse) => {
        if (response) {
          console.log('response', response);
          if (response.status === 'Success' && response.code === '200') {
            this.authService.authenticate();
            this.router.navigate(['/customer-detail',
              {expiringPolicyNumber: this.storageService.retrieve(StorageType.SESSION, AppConstants.STORAGE_EXPIRING_POLICY)}]);
          } else {
            this.router.navigate(['']);
          }
        }
      });
  }

}
