export class AppConstants {
  static readonly DATEFORMAT_DDMMYYYY: string = 'ddMMyyyy';
  static readonly LIBERTY_HELPLINE_SHORT: string = '0 2302 7738';
  static readonly LIBERTY_HELPLINE_FULL_WITHOUT_DASH: string = '023027738';
  static readonly LIBERTY_CONTACT_SUPPORT_EMAIL: string = 'insuremyTesla_TH@lmginsurance.co.th';
  static readonly CAPTCHA_ACTION_HOMEPAGE: string = 'homepage';
  static readonly SESSION_DURATION_MINUTES: number = 30;
  static readonly STORAGE_KEY_JWT_TOKEN: string = 'jwtToken';
  static readonly STORAGE_KEY_IS_AUTHENTICATED: string = 'isAuthenticated';
  static readonly STORAGE_KEY_IS_OTP_AUTHENTICATED: string = 'isOtpAuthenticated';
  static readonly STORAGE_KEY_ELAPSED_SESSION_TIME: string = 'elapsedSessionTime';
  static readonly STORAGE_KEY_IDCARD: string = 'idCard';
  static readonly STORAGE_KEY_PASSPORT: string = 'passport';
  static readonly STORAGE_KEY_COMPANY_TAX_ID: string = 'companyTaxId';
  static readonly STORAGE_KEY_RENEWAL_TYPE: string = 'renewalType';
  static readonly STORAGE_KEY_XSRF_TOKEN: string = 'xsrf_token';
  static readonly STORAGE_EXPIRING_POLICY: string = 'expiringPolicy';
  static readonly STORAGE_CUSTOMER_NAME: string = 'customerName';
  static readonly STORAGE_CUSTOMER_PHONE_NUMBER: string = 'customerPhoneNumber';
  static readonly STORAGE_CUSTOMER_EMAIL: string = 'customerEmail';
  static readonly VALIDATE_CAPTCHA_ENDPOINT: string = '/validateCaptcha';
  static readonly AUTHENTICATE_ENDPOINT: string = '/renew/customer/validate';
  static readonly OTP_SEND: string = '/renew/otp/send';
  static readonly OTP_VERIFY: string = '/renew/otp/verify';
  static readonly POLICIES: string = '/renew/policies';
  static readonly OFFER: string = '/renew/policy/offer';
  static readonly PAYMENT_STATUS: string = '/renew/payment/status';
  static readonly PAYMENT_TRANSACTION: string = '/renew/payment/transaction';
  static readonly EDIT_CUSTOMER: string = '/renew/insured';
  static readonly UPDATE_PHONE_NUMBER: string = '/renew/customer/update/phoneNumber';
  static readonly UPDATE_OTHERS: string = '/renew/update/others';
  static readonly RENEWAL_QUOTATION: string = '/renew/quotation/email/send';
  static readonly CHECK_CUSTOMER_AUTHENTICATED: string = '/renew/customer/check';
  static readonly UPDATE_CUSTOMER_AUTHENTICATED: string = '/renew/customer/authenticated/update';
  static readonly PAYMENT_STATUS_COMPLETED: string = 'COMPLETED';
  static readonly SUCCESS_PROCESS = "Success";
  static readonly FAILED_PROCESS = "FAILED";
  static readonly FINANCE = "AG11127";
  static readonly TOO_MANY_REQUEST_CODE: string = "429";
}
