import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import {CustomerAuthenticatedResponse} from "../models/response.model";
import {AppConstants} from "../constants/app-constants.model";
import {map} from "rxjs/operators";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {StorageType} from "../enum/storage-type.enum";
import {StorageService} from "../services/storage/storage.service";
import {PortalApiService} from "../services/rest-api/portal-api.service";


@Injectable()
export class OtpGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private portalApiService: PortalApiService,
    private storageService: StorageService
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let check : boolean = await this.portalApiService.checkCustomerAuthenticated(this.storageService.retrieve(StorageType.SESSION, AppConstants.STORAGE_KEY_IDCARD),
    this.storageService.retrieve(StorageType.SESSION, AppConstants.STORAGE_KEY_PASSPORT),
    this.storageService.retrieve(StorageType.SESSION, AppConstants.STORAGE_KEY_COMPANY_TAX_ID));
    console.log('OtpGuard check: ', check);
    if (check===true) {
      return true;
    } else {
      await this.router.navigate(['']);
      return false;
    }
  }

}
