import { Component, OnInit } from '@angular/core';
import {Subscription} from "rxjs";
import {Alert} from "../../../interfaces/alert";
import {PortalApiService} from "../../../services/rest-api/portal-api.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {ValidationService} from "../../../services/validation/validation.service";
import {IMTTranslateService} from "../../../services/common/imt.translation.service";
import {AuthService} from "../../../services/auth/auth.service";
import {NgForm} from "@angular/forms";
import {AppConstants} from "../../../constants/app-constants.model";
import {OTPResponse, UpdatePhoneNumberResponse} from "../../../models/response.model";
import {SessionStorageService} from "ngx-webstorage";
import {UpdatePhoneNumberRequest} from "../../../models/request.model";
import {Location} from '@angular/common';

@Component({
  selector: 'app-update-phone-number',
  templateUrl: './update-phone-number.component.html',
  styleUrls: ['./update-phone-number.component.css']
})
export class UpdatePhoneNumberComponent implements OnInit {
  subscription: Subscription;
  alert: Alert;
  showAlert = false;
  enableEng = false;
  footer: any = {};
  phoneNo: string;
  vehicleNumber: string;
  disableSubmitBtn = false;
  otpExpiryTs: string;
  maskPhoneNumber: string = '';
  isLoading: boolean = false;
  constructor(
    private portalApiService: PortalApiService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private validationService: ValidationService,
    private imtTranslationService: IMTTranslateService,
    private authService: AuthService,
    private sessionStorage: SessionStorageService,
    private _location: Location
  ) {
    this.setDefaults();
  }
  setDefaults(): void {
  }


  ngOnInit(): void {
    window.scrollTo({ top: 0 });
  }

  submit(customerForm: NgForm): void {
    this.spinner.show();
    this.showAlert = false;
    let idCard = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_IDCARD) as string;
    let passport = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_PASSPORT) as string;
    let companyTaxId = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_COMPANY_TAX_ID) as string;
    let updatePhoneNumberRequest: UpdatePhoneNumberRequest = new UpdatePhoneNumberRequest();
    updatePhoneNumberRequest.idCard = idCard;
    updatePhoneNumberRequest.passport = passport;
    updatePhoneNumberRequest.companyTaxId = companyTaxId;
    updatePhoneNumberRequest.phoneNumber = this.phoneNo;
    updatePhoneNumberRequest.vehicleNumber = this.vehicleNumber;
    if (this.validationService.checkFormValidity(customerForm)) {
      this.disableSubmitBtn = true;
      this.isLoading = true;
      let reference = this.randomString(4);
      this.portalApiService
        .updatePhoneNumber(updatePhoneNumberRequest)
        .subscribe((response: UpdatePhoneNumberResponse) => {
            if (response) {
              if (response.status === 'Success') {
                  this.authService.authenticate();
                  this.portalApiService.sendOtp(idCard, passport, reference, companyTaxId).subscribe( (body: OTPResponse) => {
                    this.otpExpiryTs = body?.otpExpiryTs;
                    this.isLoading = false;
                    this.maskPhoneNumber = body?.maskPhoneNumber;
                    this.router.navigate(['/otp', {reference: reference, otpExpiryTs: this.otpExpiryTs, maskPhoneNumber: this.maskPhoneNumber}]);
                  });

              } else {
                this.handleAuthFailure();
              }
            } else {
              this.handleAuthFailure();
            }
          },
          err => {
            this.handleAuthFailure()
          }
        );
    }
  }

  randomString(length): string {
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  private handleAuthFailure(): void {
    this.showAlert = true;
    this.disableSubmitBtn = false;
    this.isLoading = false;
  }
  closeAlert(alert: Alert): void {
    this.showAlert = false;
  }

  isEnglishSelected() {
    return 'en' === this.imtTranslationService.getCurrentLanguage();

  }
  
  backToPreviousPage() {
    this._location.back();
  }
}
