import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonService} from 'src/app/core/services/common/common.service';
import {Alert} from 'src/app/core/interfaces/alert';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidationService} from 'src/app/core/services/validation/validation.service';
import {StorageService} from '../../../services/storage/storage.service';
import {PortalApiService} from '../../../services/rest-api/portal-api.service';
import {AppConstants} from '../../../constants/app-constants.model';
import {SessionStorageService} from 'ngx-webstorage';
import {IMTTranslateService} from 'src/app/core/services/common/imt.translation.service';
import {OTPResponse} from "../../../models/response.model";
import {Observable, timer} from "rxjs";
import {map, takeWhile} from "rxjs/operators";
import {StorageType} from "../../../enum/storage-type.enum";

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit{
  alert: Alert;
  showAlert = false;
  otpExpired = false;
  otp: string;
  isValidInput: boolean;
  disableSubmitBtn = false;
  referenceNo: string;
  phoneNumber: string;
  otpExpiryTs: string;
  counter: number = 0;
  disableResend = true;
  timeRemaining$: Observable<any>;
  isLoading: boolean = true;
  isOtpCountExceed: boolean = false;
  constructor(
    private router: Router,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private validationService: ValidationService,
    private storageService: StorageService,
    private portalApiService: PortalApiService,
    private sessionStorage: SessionStorageService,
    private route: ActivatedRoute,
    private imtTranslationService: IMTTranslateService
  ) {
    this.spinner.show();
    this.setDefaults();
  }

  setDefaults(): void {
  }

  ngOnInit(): void {
    // timer
    this.otpExpiryTs = this.route.snapshot.paramMap.get('otpExpiryTs');
    this.startTimer();
    this.otp = '';

    this.referenceNo = this.route.snapshot.paramMap.get('reference');
    this.phoneNumber = this.route.snapshot.paramMap.get('maskPhoneNumber');
    const idCard = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_IDCARD) as string;
    const passport = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_PASSPORT) as string;
    const companyTaxId = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_COMPANY_TAX_ID) as string;
    window.scrollTo({ top: 0 });
    this.isLoading = false;
  }

  startTimer(): void {
    console.log('this.otpExpiryTs', this.otpExpiryTs);
    const d = new Date(this.otpExpiryTs);
    const d1 = new Date;
    this.counter = Math.floor((d.getTime() - d1.getTime()) / 1000) -240;

    console.log('this.d', d);
    console.log('this.d1', d1);
    console.log('this.counter', this.counter);
    this.timeRemaining$ = timer(0, 1000).pipe(
      map((n) => {
        if (this.counter - n > 0) {
          this.disableResend = true;
/*          if (this.counter - n <= 60) {
            this.disableResend = false;
          }*/
          return this.counter - n;
        } else {
          this.disableResend = false;
          return 0;
        }
      }),
      takeWhile((n) => n >= 0)
    );
  }

  onKeyDown(event: KeyboardEvent) {
    this.isValidInput = this.commonService.sanitizeUserInputValue(event, 'number') && this.otp.match(/[^0-9]/g) === null;
  }

  submit(customerForm: NgForm): void {
    this.showAlert = false;
    if (this.validationService.checkFormValidity(customerForm)) {
      this.disableSubmitBtn = true;
      this.isLoading = true;
      this.verifyOtp();
    }

  }

  resendOtp(): void {
    this.otp ="";
    this.isLoading = true;
    const idCard = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_IDCARD) as string;
    const passport = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_PASSPORT) as string;
    const companyTaxId = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_COMPANY_TAX_ID) as string;
    const referenceNo = this.randomString(4);
    this.portalApiService
      .sendOtp(idCard, passport, referenceNo, companyTaxId)
      .subscribe( (body: OTPResponse) => {
        if(body?.status.statusCode === AppConstants.TOO_MANY_REQUEST_CODE){
            this.isOtpCountExceed = true;
        }
        this.referenceNo = referenceNo;
        this.isLoading = false;
        this.router.navigate(['/otp', {reference: referenceNo, otpExpiryTs: body.otpExpiryTs}]);
        this.otpExpiryTs = body?.otpExpiryTs;
        this.startTimer();
       });
  }

  randomString(length): string {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i) { result += chars[Math.floor(Math.random() * chars.length)]; }
    return result;
  }

  verifyOtp(): void {
    this.isLoading = true;
    const idCard = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_IDCARD) as string;
    const passport = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_PASSPORT) as string;
    const companyTaxId = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_COMPANY_TAX_ID) as string;
    this.portalApiService
      .verifyOtp(idCard, passport, companyTaxId, this.otp)
      .subscribe((response: any) => {
        if (response) {
          if (response.status === 'Success') {
           /* this.authService.optAuthenticate();*/
            this.portalApiService.updateCustomerAuthenticatedAsync(this.storageService.retrieve(StorageType.SESSION, AppConstants.STORAGE_KEY_IDCARD),
              this.storageService.retrieve(StorageType.SESSION, AppConstants.STORAGE_KEY_PASSPORT),
              this.storageService.retrieve(StorageType.SESSION, AppConstants.STORAGE_KEY_COMPANY_TAX_ID), true)
              .subscribe(response => {
                this.router.navigate(['/policies']);
              });
          } else {
            if(response.message === 'OTP Expired'){
              this.otpExpired = true;
              this.resendOtp();
              this.showAlert = true;
              this.disableSubmitBtn = false;
            }else{
              this.handleAuthFailure();
            }
          }
        } else {
          this.handleAuthFailure();
        }
      });
  }

  private handleAuthFailure(): void {
    this.alert = {
      type: 'warning',
      messageEn: 'Verification failed. Check the data entered.',
      messageTh: 'Verification failed. Check the data entered.',
      templateEn: '<div style="margin: 0;"><strong>Verification failed from data processing,<br/> click </strong> <a href="https://www.lmginsurance.co.th/insuremytesla">https://www.lmginsurance.co.th/insuremytesla</a> <strong>to discover insurance information.</strong></div>',
      templateTh: '<div style="margin: 0;"><strong>ตรวจสอบข้อมูลไม่พบอาจจะอยู่ในขั้นตอนการจัดการข้อมูล<br/> กรุณาคลิก </strong><a href="https://www.lmginsurance.co.th/insuremytesla">https://www.lmginsurance.co.th/insuremytesla</a><strong> เพื่อศึกษาข้อมูลประกันภัย</strong></div>'
    };
    this.showAlert = true;
    this.disableSubmitBtn = false;
    this.isLoading = false;
  }

  closeAlert(alert: Alert): void {
    this.showAlert = false;
  }

  isEnglishSelected() {
    return 'en' === this.imtTranslationService.getCurrentLanguage();
  }
  
  backToPreviousPage() {
    this.router.navigate(['/']);
  }
}
