import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PortalApiService } from '../rest-api/portal-api.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleCaptchaService {

  private verificationStatus = new Subject<boolean>();

  constructor(
    private reCaptchV3Service: ReCaptchaV3Service,
    private portalApiService: PortalApiService
  ) {}

  getVerificationStatus(): Observable<boolean> {
    return this.verificationStatus.asObservable();
  }

  validate(action: string): void {
    console.log('In google captcha service, validate with site key : '+environment.captchaV3sitekey);
    this.reCaptchV3Service.execute(environment.captchaV3sitekey, action, (token: string) => {
      this.portalApiService.validateCaptcha(token).subscribe((response: boolean) => {
        this.verificationStatus.next(response);
      });
    });
  }
}
