<app-header></app-header>
<div *ngIf="!isLoading, else spinner">
  <div class="wrapper" [class.Font-Thai]="!isEnglishSelected">
    <figure>
      <img alt="Tesla Cars" src="assets/tesla-cars-banner.png" class="img-fluid">
    </figure>
    
    <div *ngIf="isOtpCountExceed" class="m-1">
      <ngb-alert [type]="'custom'" class="alert-home-custom text-center message-error">
        {{ 'otp.otpCountExceedLimit' | translate}}
      </ngb-alert>
    </div>

    <div *ngIf="showAlert" class="m-1">
      <ngb-alert [type]="'custom'" class="alert-home-custom text-center message-error">
        {{ (otpExpired ? 'otp.expired' : 'otp.invalid') | translate}}
      </ngb-alert>
    </div>

    <div class="row">
      <div class="col-10 col-sm-4 col-md-4 col-lg-4 mx-sm-auto">
        <section class="mt-4 mb-3">

          <div class="text-center mx-1 mx-sm-auto">
            <div>
              <h5 class=" my-3 ls-1">{{ 'common.authentication' | translate }}</h5>
            </div>
          </div>
        </section>

        <section class="mb-4 mb-3">
          <div class="main-container  text-center mx-1 mx-sm-auto">
            <form #otpForm="ngForm" autocomplete="off" (ngSubmit)="submit(otpForm)">
              <div class="text-center mx-1 mx-sm-auto">
              </div>
              <div class="text-center mx-1 mx-sm-auto">
                <div>
                  <p
                    class=" my-2 ls-1">{{ 'otp.sentMessageNotification1' | translate }}{{phoneNumber}}</p>
                </div>
              </div>
              <div class="text-center mx-1 mx-sm-auto">
                <div>
                  <p class=" my-3 ls-1">{{ 'otp.code' | translate }}(Ref:{{referenceNo}})</p>
                </div>
              </div>
              <div class="row my-2 ls-1">
                <div class="col-12 col-md-12">
                  <div class="select-toggle p-relative">
                    <input [(ngModel)]="otp" maxlength="6" type="text" class="form-control text-center rounded-5 p-0" 
                           [ngClass]="{'ng-invalid ng-dirty': showAlert}" id="otp" name="otp" required (keydown)="onKeyDown($event)">
                    <!-- <div class="input-error" *ngIf="!isValidInput">
                      {{ 'otp.requireMessage' | translate}}
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="my-2 ls-1 mx-sm-auto d-flex align-items-center justify-content-center" style="column-gap: 1rem;">
                  <button (click)="backToPreviousPage()" id="back_btn" type="button"
                    class="btn btn-outline-secondary button br-60 submit_button">
                    {{ 'common.button.back' | translate }}
                  </button>
                  <button type="submit" id="submit_btn"
                          class="btn btn-primary button br-60 disable-button submit_button"
                          [disabled]="otp.length !== 6 || !isValidInput">
                    {{ 'common.button.submit' | translate }}
                  </button>
                </div>

                <div class="text-center d-flex align-items-center justify-content-center" style="white-space: nowrap;">
                  <p class="m-0">{{ 'otp.dontReceiveCode' | translate }}</p>
                  <button class="my-2 ls-1 transparent font-weight-bold" style="color: #0a1e33;" (click)="resendOtp()" [disabled]="disableResend">
                    {{'otp.resend' | translate }} ({{ timeRemaining$ | async }})
                  </button>
                </div>
                <button class="my-2 ls-1 transparent font-weight-bold" style="color: #0a1e33;"
                        [routerLink]="['/update-phone-number']">{{ 'otp.changePhoneNumber' | translate }}</button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
<ng-template #spinner>
  <ngx-spinner type="ball-spin-clockwise-fade-rotating" size="medium"></ngx-spinner>
</ng-template>
