import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable } from 'rxjs';
import { DeviceInfo } from '../../models/device-info.model';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private deviceDetectorService: DeviceDetectorService) {}

  public quoteSelection: BehaviorSubject<any> = new BehaviorSubject({
    voluntary: true,
    compulsory: true,
  });

  public language: BehaviorSubject<any> = new BehaviorSubject({
    enableEng: false,
  });

  sanitizeUserInputValue(event: KeyboardEvent, inputType: string): boolean {
    let isValidInput: boolean = true;
    let isInputAllowed = inputType === 'number' ? this.isNotNumbericInput(event) : this.isNotAllowedInput(event);
    if (isInputAllowed && this.isNotCommonKeyEvent(event) && this.isNotEnterKeyEvent(event)) {
      isValidInput = false;
      event.preventDefault();
    }
    return isValidInput;
  }

  isNotEnterKeyEvent(event: KeyboardEvent): boolean{
     return event.key != 'Enter';
  }

  isNotCommonKeyEvent(event: KeyboardEvent): boolean {
    return event.key !== 'Backspace' && !event.ctrlKey;
  }

  isNotNumbericInput(event: KeyboardEvent): boolean {
    return event.key < '0' || event.key > '9';
  }

  isNotAllowedInput(event: KeyboardEvent): boolean {
    const allowedCharacters = /^[a-zA-Z0-9!@#%^&*()_+\-=\[\]{}|;':",.\/<>?~`ก-๙เแโใไ๐-๙เ-ไ]+$/;
    return !allowedCharacters.test(event.key);
}

  getNumber(input: string): number {
    if (!input) {
      return NaN;
    }
    if (input.trim().length === 0) {
      return NaN;
    }
    return Number(input);
  }

  getEnumKeyForValue(myEnum: any, value: string): string {
    for (const key of Object.keys(myEnum)) {
      if (myEnum[key] === value) {
        return key.toString();
      }
    }
    return null;
  }

  getEnumValueForKey(myEnum: any, key: string): string {
    for (const enumKey of Object.keys(myEnum)) {
      if (enumKey.toString() === key) {
        return myEnum[enumKey];
      }
    }
    return null;
  }

  getDeviceInfo(): DeviceInfo {
    const deviceData = this.deviceDetectorService.getDeviceInfo();
    const deviceInfo = new DeviceInfo();

    deviceInfo.userAgent = deviceData.userAgent;
    deviceInfo.os = deviceData.os;
    deviceInfo.browser = deviceData.browser;
    deviceInfo.device = deviceData.device;
    deviceInfo.osVersion = deviceData.os_version;
    deviceInfo.browserVersion = deviceData.browser_version;
    deviceInfo.isMobile = this.deviceDetectorService.isMobile();
    deviceInfo.isTablet = this.deviceDetectorService.isTablet();
    deviceInfo.isDesktop = this.deviceDetectorService.isDesktop();

    if (deviceInfo.isMobile) {
      deviceInfo.deviceCategory = 'Mobile';
    } else if (deviceInfo.isTablet) {
      deviceInfo.deviceCategory = 'Tablet';
    } else if (deviceInfo.isDesktop) {
      deviceInfo.deviceCategory = 'Desktop';
    } else {
      deviceInfo.deviceCategory = 'Other';
    }

    return deviceInfo;
  }

  deepClone(objectToClone: any): any {
    return _.cloneDeep(objectToClone);
  }
  
}
