import {ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Alert} from 'src/app/core/interfaces/alert';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidationService} from 'src/app/core/services/validation/validation.service';
import {DriverInfo, OfferResponse, PaymentResponse, Status, UpdateOtherDetailsResponse} from '../../../models/response.model';
import {AppConstants} from '../../../constants/app-constants.model';
import {PortalApiService} from '../../../services/rest-api/portal-api.service';
import {SessionStorageService} from 'ngx-webstorage';
import {IMTTranslateService} from '../../../services/common/imt.translation.service';
import {UpdateOtherDetailsRequest} from '../../../models/request.model';
import {RenwalQuotationRequest} from '../../../models/request.model';
import SubDistrictsJson from "../../../data/sub-district.json";
import DistrictsJson from "../../../data/district.json";
import ProvincesJson from "../../../data/province.json";
import SalutationsJson from "../../../data/salutation.json";
import OccupationsJson from '../../../data/occupation.json';
import GendersJson from '../../../data/gender.json';
interface SubDistrictJson {
  key: string,
  postalCode: string,
  tumbonCode: string,
  districtCode: string,
  tumbonEngName: string,
  tumbonThaiName: string
}

interface DistrictJson {
  "districtCode": string;
  "provinceCode": string;
  "districtEngName": string;
  "districtThaiName": string
}

interface ProvinceJson {
  "id": string;
  "tidCode": string;
  "aegisCode": string;
  "provinceCode": string;
  "nameEng": string;
  "nameThai": string;
  "region": string;
}

interface SalutationJson {
  "salutation": string;
  "insuredType": string;
  "gender": string;
  "salutationDesc1": string;
  "salutationDesc2": string;
  "seq": string;
}

interface OccupationJson {
  "code": string;
  "nameEng": string;
  "nameThai": string;
}

interface GenderJson {
  "code": string;
  "nameEng": string;
  "nameThai": string;
}
@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit, OnChanges {
  alert: Alert;
  showAlert = false;
  expiringPolicyNumber: string;
  offerResponse: OfferResponse;

  salutation: string;
  insuredName: string;
  identificationNumber: string;
  phoneNumber: string;
  email: string;
  agentCode: string;
  insuredType: string;
  occupation: string;
  dateOfBirth: string;
  beneficiary: string;
  billingAddressLine1: string;
  billingAddressLine2: string;
  billingProvince: string;
  billingDistrict: string;
  billingSubDistrict: string;
  billingPostCode: string;
  billingAddress: string;
  insuredAddressLine1: string;
  insuredAddressLine2: string;
  insuredProvince: string;
  insuredDistrict: string;
  insuredSubdistrict: string;
  insuredPostCode: string;
  insuredAddress: string;
  driverName: string;
  driverLicenseNumber : String;
  driverAge: string;
  driverDateOfBirth: string;
  driverGenderCode: string;
  driverGender: GenderJson;
  driverGenderJ: GenderJson[] = GendersJson;
  driverIdCardNumber: string;
  driverPassportNumber: string;
  driverIdentificationType: string;
  driverOccupationCode: string;
  driverOccupation: OccupationJson;
  driverOccupationJ: OccupationJson[] = OccupationsJson;
  brand: string;
  model: string;
  subModel: string;
  licenseNumber: string;
  chassisNumber: string;
  engineSize: string;
  year: string;
  color: string;
  vehicleAdditionalEquipment: string;
  vehicleAccessories: string;
  baterryYear: string;
  batterySerialNumber: string;
  wallChargerSerialNumber: string;
  licenseProvinceEn: string;
  licenseProvinceTh: string;
  policyType: string;
  motorCode: string;
  garageType: string;
  usage: string;
  grandTotalPremium: number;
  postEmailSelected: string = 'EMAIL';
  paymentOption: string = 'CARD';
  idCard: string;
  passport: string;
  companytaxId: string;
  renewalType: string;
  errMsg: string;
  name: string;
  isLoading: boolean = true;
  isCompany: boolean = false;
  isBillingAddress: boolean = true;
  postSubDistrictsJson: SubDistrictJson[] = SubDistrictsJson;
  postDistrictsJson: DistrictJson[] = DistrictsJson;

  billingSubDistrictsJson: SubDistrictJson[] = SubDistrictsJson;
  billingDistrictsJson: DistrictJson[] = DistrictsJson;

  provincesJson: ProvinceJson[] = ProvincesJson;
  salutationsJson: SalutationJson[] = SalutationsJson;
  subDistrictsJson: SubDistrictJson[] = SubDistrictsJson;
  districtsJson: DistrictJson[] = DistrictsJson;

  tmpSubDistrictsJson: SubDistrictJson[] = SubDistrictsJson;
  tmpDistrictsJson: DistrictJson[] = DistrictsJson;
  driverList: DriverInfo[] = [];
  requireToEditEmail: boolean = false;
  requireToAdDriver: boolean = false;
  isEng: boolean = false;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private validationService: ValidationService,
    private portalApiService: PortalApiService,
    private sessionStorage: SessionStorageService,
    private route: ActivatedRoute,
    private imtTranslateService: IMTTranslateService,
    private cdr: ChangeDetectorRef
  ) {
    this.spinner.show();
    this.setDefaults();
  }

  setDefaults(): void {
  }


  ngOnInit(): void {
    window.scrollTo({top: 0});
    let language: string = this.imtTranslateService.getCurrentLanguage();
    this.isEng = language === 'en' ? true : false;
    this.imtTranslateService.onLanguageChanged.subscribe(() => {
      this.cdr.detectChanges();
      this.isEng = !this.isEng;
    });
    this.name = this.sessionStorage.retrieve(AppConstants.STORAGE_CUSTOMER_NAME) as string;
    this.idCard = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_IDCARD) as string;
    this.passport = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_PASSPORT) as string;
    this.companytaxId = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_COMPANY_TAX_ID) as string;
    this.expiringPolicyNumber = this.route.snapshot.paramMap.get('expiringPolicyNumber');
    this.renewalType = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_RENEWAL_TYPE) as string;

    this.portalApiService
      .offer(this.idCard, this.passport, this.companytaxId, this.expiringPolicyNumber)
      .subscribe((response: OfferResponse) => {
        this.isLoading = false;
        if (response) {
          if (response.status === 'Success') {
            this.offerResponse = response;
            this.setInput(this.offerResponse);
          }
        }
      });
  }

  setInput(offerResponse: OfferResponse) {
    this.salutation = offerResponse?.policyData?.insuredDetails?.salutation;
    this.insuredName = offerResponse?.policyData?.insuredDetails?.name;
    //this.identificationNumber = offerResponse?.policyData?.insuredDetails?.idCardNumber;
    this.identificationNumber = this.idCard !='' ? this.idCard : this.passport !='' ? this.passport :
      this.companytaxId != '' ? this.companytaxId : '';
    this.phoneNumber = offerResponse?.policyData?.insuredDetails?.phoneNumber;
    this.email = offerResponse?.policyData?.insuredDetails?.email;
    this.insuredType=offerResponse?.policyData?.insuredDetails?.insuredType;
    this.occupation = offerResponse?.policyData?.insuredDetails?.occupation;
    this.dateOfBirth = offerResponse?.policyData?.insuredDetails?.dateOfBirth;
    this.dateOfBirth = this.dateOfBirth ? this.dateOfBirth.substr(0, 10) : '';
    this.beneficiary = offerResponse?.policyData?.insuredDetails?.beneficiary;
    this.agentCode = offerResponse?.policyData?.policyDetails?.agentCode;

    this.billingAddressLine1 = offerResponse?.policyData?.insuredDetails?.billingAddress?.address1;
    this.billingAddressLine2 = offerResponse?.policyData?.insuredDetails?.billingAddress?.address2;
    this.billingProvince = offerResponse?.policyData?.insuredDetails?.billingAddress?.province?.nameThai;
    this.billingDistrict = offerResponse?.policyData?.insuredDetails?.billingAddress?.district;
    this.billingPostCode = offerResponse?.policyData?.insuredDetails?.billingAddress?.postalCode;

    let billingDistrictJson: DistrictJson = this.districtsJson.find(s => s.districtThaiName === offerResponse?.policyData?.insuredDetails?.billingAddress?.district
      && (s => s.provinceCode === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.aegisCode || s.provinceCode === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.provinceCode));
    let billingSubDistrictJson: SubDistrictJson = this.subDistrictsJson.find(s => (s.tumbonThaiName === offerResponse?.policyData?.insuredDetails?.billingAddress?.tumbon || s.tumbonCode === offerResponse?.policyData?.insuredDetails?.billingAddress?.tumbon)
      && billingDistrictJson.districtCode === s.districtCode
      && (s.key === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.aegisCode || s.key === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.provinceCode));
    this.billingSubDistrict = billingSubDistrictJson?.tumbonThaiName;
    this.billingAddress =
      (offerResponse?.policyData?.insuredDetails?.billingAddress?.salutation ? offerResponse?.policyData?.insuredDetails?.billingAddress?.salutation + ' ' : '')
      + (offerResponse?.policyData?.insuredDetails?.billingAddress?.name ? offerResponse?.policyData?.insuredDetails?.billingAddress?.name + ' ' : '')
      + (this.billingAddressLine1 ? this.billingAddressLine1 + ' ' : '')
      + (this.billingAddressLine2 ? this.billingAddressLine2 + ' ' : '')
      + (this.billingSubDistrict ? this.billingSubDistrict + ' ' : '')
      +  (this.billingDistrict ? this.billingDistrict + ' ' : '')
      +  (this.billingProvince ? this.billingProvince + ' ' : '')
      +  (this.billingPostCode ? this.billingPostCode : '')
    this.isBillingAddress = this.billingAddress === '' || this.billingAddress === null ? false : true
    //this.isCompany = offerResponse?.policyData?.billingAddress?.salutation?.toUpperCase() === 'COMPANY' ? true : false
    this.isCompany=this.insuredType === 'C' ? true : false

    this.insuredAddressLine1 = offerResponse?.policyData?.insuredDetails?.insuredAddress?.address1;
    this.insuredAddressLine2 = offerResponse?.policyData?.insuredDetails?.insuredAddress?.address2;
    this.insuredProvince = offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.nameThai;
    this.insuredDistrict = offerResponse?.policyData?.insuredDetails?.insuredAddress?.district;

    this.insuredPostCode = offerResponse?.policyData?.insuredDetails?.insuredAddress?.postalCode;
    let postDistrictJson: DistrictJson = this.districtsJson.find(s => s.districtThaiName === offerResponse?.policyData?.insuredDetails?.insuredAddress?.district
      && (s => s.provinceCode === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.aegisCode || s.provinceCode === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.provinceCode));

    let postSubDistrictJson: SubDistrictJson = this.subDistrictsJson.find(s => (s.tumbonThaiName === offerResponse?.policyData?.insuredDetails?.insuredAddress?.tumbon || s.tumbonCode === offerResponse?.policyData?.insuredDetails?.insuredAddress?.tumbon)
    && s.districtCode === postDistrictJson.districtCode
      && (s.key === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.aegisCode || s.key === offerResponse?.policyData?.insuredDetails?.insuredAddress?.province?.provinceCode));

    this.insuredSubdistrict = postSubDistrictJson?.tumbonThaiName;
    this.insuredAddress =
      /*(offerResponse?.policyData?.insuredAddress?.salutation ? offerResponse?.policyData?.insuredAddress?.salutation + ' ' : '')
      + (offerResponse?.policyData?.insuredAddress?.name ? offerResponse?.policyData?.insuredAddress?.name + ' ' : '')*/
      (this.insuredAddressLine1 ? this.insuredAddressLine1 + ' ' : '')
      + (this.insuredAddressLine2 ? this.insuredAddressLine2 + ' ' : '')
      + (this.insuredSubdistrict ? this.insuredSubdistrict + ' ' : '')
      +  (this.insuredDistrict ? this.insuredDistrict + ' ' : '')
      +  (this.insuredProvince ? this.insuredProvince + ' ' : '')
      +  (this.insuredPostCode ? this.insuredPostCode : '')

    //driver details
    this.driverName = offerResponse?.policyData?.driverDetails?.mainDriver?.name;
    this.driverAge = offerResponse?.policyData?.driverDetails?.mainDriver?.age;
    this.driverDateOfBirth = offerResponse?.policyData?.driverDetails?.mainDriver?.dateOfBirth ?
      new Date(offerResponse?.policyData?.driverDetails?.mainDriver?.dateOfBirth).toLocaleDateString() : '';
    this.driverGenderCode = offerResponse?.policyData?.driverDetails?.mainDriver?.sex;
    this.driverGender = this.driverGenderJ?.filter(i => i.code === this.driverGenderCode)?.length >=0 ?
      this.driverGenderJ?.filter(i => i.code === this.driverGenderCode)[0] : null;
    this.driverIdCardNumber = offerResponse?.policyData?.driverDetails?.mainDriver?.idCardNumber;
    this.driverPassportNumber = offerResponse?.policyData?.driverDetails?.mainDriver?.passportNumber;
    this.driverIdentificationType = offerResponse?.policyData?.driverDetails?.mainDriver?.identificationType;
    this.driverOccupationCode = offerResponse?.policyData?.driverDetails?.mainDriver?.occupationCode;
    this.driverOccupation = this.driverOccupationJ?.filter(i => i.code === this.driverOccupationCode)?.length >=0 ?
      this.driverOccupationJ?.filter(i => i.code === this.driverOccupationCode)[0] : null;
    this.driverLicenseNumber = offerResponse?.policyData?.driverDetails?.mainDriver?.licenseNumber;
    let mainDriver: DriverInfo = offerResponse?.policyData?.driverDetails?.mainDriver
    if(mainDriver?.dateOfBirth) {
      mainDriver.driverType = offerResponse?.policyData?.driverDetails?.mainDriver?.name + ' (1)';
      this.driverList.push(mainDriver);
    }
    let additionalDriver: DriverInfo = offerResponse?.policyData?.driverDetails?.additionalDriver
    if(additionalDriver?.dateOfBirth) {
      additionalDriver.driverType = offerResponse?.policyData?.driverDetails?.additionalDriver?.name + ' (2)';
      this.driverList.push(additionalDriver);
    }
    let driver3: DriverInfo = offerResponse?.policyData?.driverDetails?.driver3
    if(driver3?.dateOfBirth) {
      driver3.driverType = offerResponse?.policyData?.driverDetails?.driver3?.name + ' (3)';
      this.driverList.push(driver3);
    }
    let driver4: DriverInfo = offerResponse?.policyData?.driverDetails?.driver4
    if(driver4?.dateOfBirth) {
      driver4.driverType = offerResponse?.policyData?.driverDetails?.driver4?.name + ' (4)';
      this.driverList.push(driver4);
    }
    let driver5: DriverInfo = offerResponse?.policyData?.driverDetails?.driver5
    if(driver5?.dateOfBirth) {
      driver5.driverType = offerResponse?.policyData?.driverDetails?.driver5?.name + ' (5)';
      this.driverList.push(driver5);
    }
    if(!(this.driverList && this.driverList.length > 0) && this.insuredType ==='P'){
      mainDriver.driverType = offerResponse?.policyData?.driverDetails?.mainDriver?.name + ' (1)';
      this.driverList.push(mainDriver);
    }
    //car details
    this.brand = offerResponse?.policyData?.vehicleDetails?.make;
    this.model = offerResponse?.policyData?.vehicleDetails?.model;
    this.subModel = offerResponse?.policyData?.vehicleDetails?.subModel;
    this.licenseNumber = offerResponse?.policyData?.vehicleDetails?.licenseNumber;
    this.licenseProvinceEn = offerResponse?.policyData?.vehicleDetails?.licenseProvince?.nameEng;
    this.licenseProvinceTh = offerResponse?.policyData?.vehicleDetails?.licenseProvince?.nameThai;
    this.licenseProvinceEn = this?.licenseProvinceEn != 'NULL' ? this.licenseProvinceEn : 'Red Plate';
    this.licenseProvinceTh = this?.licenseProvinceTh != 'NULL' ? this?.licenseProvinceTh : 'Red Plate';
    this.chassisNumber = offerResponse?.policyData?.vehicleDetails?.chassisNumber;
    this.engineSize = offerResponse?.policyData?.vehicleDetails?.engineSize;
    this.year = offerResponse?.policyData?.vehicleDetails?.year;
    this.color = offerResponse?.policyData?.vehicleDetails?.color;
    this.vehicleAdditionalEquipment = offerResponse?.policyData?.vehicleDetails?.vehicleAdditionalEquipment;
    this.vehicleAccessories = offerResponse?.policyData?.vehicleDetails?.vehicleAccessories;
    //battery details
    this.baterryYear = offerResponse?.policyData?.batteryDetails?.batteryYear;
    this.batterySerialNumber = offerResponse?.policyData?.batteryDetails?.newBatterySerialNumber;
    this.wallChargerSerialNumber = offerResponse?.policyData?.batteryDetails?.wallChargerSerialNumber;

    //renewal offering
    this.policyType = offerResponse?.policyData?.coverageDetails?.policyType;
    this.garageType = offerResponse?.policyData?.coverageDetails?.garageType;
    this.usage = offerResponse?.policyData?.vehicleDetails?.vehicleUse;
    this.grandTotalPremium = this.getGrandTotalPremium(offerResponse?.policyData?.coverageDetails?.premiumBreakdown?.grossPremium,
      offerResponse?.policyData?.coverageDetails?.premiumBreakdown?.compulsoryGrossPremium);
    this.motorCode = offerResponse?.policyData?.coverageDetails?.motorCode;
  }

  submit(customerForm: NgForm): void {
    if (this.validationService.checkFormValidity(customerForm)) {
      //
      if (this.isCompany) {
        if (this.postEmailSelected === 'EMAIL/POSTMAIL' || this.postEmailSelected === 'EMAIL') {
          if ((this.email === '' || !this.email)) {
            this.requireToEditEmail = true;
          }
        }
        if(this.motorCode === '110E' || this.motorCode === 'E11') {
          if(this.driverList.length === 0){
            this.requireToAdDriver = true;
          }
          else{
            for (const driver of this.driverList) {
              if(driver.licenseNumber === null)
                this.requireToAdDriver = true;
                //break;
            }
          }
        }
      } else {
        if (this.postEmailSelected === 'EMAIL/POSTMAIL' || this.postEmailSelected === 'EMAIL') {
          if ((this.email === '' || !this.email) || (this.dateOfBirth === '' || !this.dateOfBirth)) {
            this.requireToEditEmail = true;
          }
        }
        if(this.motorCode === '110E' || this.motorCode === 'E11') {
          for (const driver of this.driverList) {
            if(driver.licenseNumber === null)
              this.requireToAdDriver = true;
              //break;
          }
        }
      }
      if(!this.requireToEditEmail && !this.requireToAdDriver){
        this.initiatePaymentTransaction();
      //  this.sendRenewalQuotationEmail();
      }else if(this.requireToEditEmail && !this.requireToAdDriver){
        this.router.navigate(['/customer-edit', {expiringPolicyNumber : this.expiringPolicyNumber,
          postEmailSelected : this.postEmailSelected, requireToAdDriver:""}]);
      }else if(!this.requireToEditEmail && this.requireToAdDriver){
        this.router.navigate(['/customer-edit', {expiringPolicyNumber : this.expiringPolicyNumber,
          postEmailSelected : "", requireToAdDriver:this.requireToAdDriver}]);
      }else{
        this.router.navigate(['/customer-edit', {expiringPolicyNumber : this.expiringPolicyNumber,
          postEmailSelected : this.postEmailSelected, requireToAdDriver:this.requireToAdDriver}]);
      }
    }
  }

  getGrandTotalPremium(totalPremiumAV: string, totalPremiumAC: string): number {
    if (this.renewalType === 'AV') {
      return totalPremiumAV ? parseFloat(totalPremiumAV) : 0;
    } else {
      if (totalPremiumAV && totalPremiumAC) {
        return parseFloat(totalPremiumAV) + parseFloat(totalPremiumAC);
      } else {
        if (totalPremiumAV) {
          return parseFloat(totalPremiumAV)
        } else {
          return totalPremiumAC ? parseFloat(totalPremiumAC) : 0;
        }
      }
    }
  }

  editData(customerForm: NgForm): void {
    if (this.validationService.checkFormValidity(customerForm)) {
      this.router.navigate(['/customer-edit', {expiringPolicyNumber : this.expiringPolicyNumber , postEmailSelected : this.postEmailSelected}]);
    }

  }

  updateDriverDetails(driver :DriverInfo) {
    this.driverName = driver.name;
    this.driverAge = driver.age;
    this.driverDateOfBirth = driver.dateOfBirth ? new Date(driver.dateOfBirth).toLocaleDateString() : '';
    this.driverGenderCode = driver.sex;
    this.driverIdCardNumber = driver.idCardNumber;
    this.driverPassportNumber = driver.passportNumber;
    this.driverIdentificationType = driver.identificationType;
    this.driverOccupationCode = driver.occupation;
    this.driverLicenseNumber = driver.licenseNumber;
  }

  closeAlert(alert: Alert): void {
    this.showAlert = false;
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
    this.spinner.hide();
  }

  isEnglishSelected(): boolean {
    return 'en' === this.imtTranslateService.getCurrentLanguage();
  }

  initiatePaymentTransaction(): void {
    console.log("initiatePaymentTransaction trigger");
    const idCard = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_IDCARD) as string;
    const passport = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_PASSPORT) as string;
    const companyTaxId = this.sessionStorage.retrieve(AppConstants.STORAGE_KEY_COMPANY_TAX_ID) as string;
    this.isLoading = true;

    let updateOtherDetailsRequest: UpdateOtherDetailsRequest = new UpdateOtherDetailsRequest();
    updateOtherDetailsRequest.idCard = idCard;
    updateOtherDetailsRequest.passport = passport;
    updateOtherDetailsRequest.companyTaxId = companyTaxId;
    updateOtherDetailsRequest.coverageType = this.renewalType;
    updateOtherDetailsRequest.policyReceiveMethod = this.postEmailSelected;
    updateOtherDetailsRequest.policyNumber = this.expiringPolicyNumber;

    let renwalQuotationRequest: RenwalQuotationRequest = new RenwalQuotationRequest();
    renwalQuotationRequest.agentCode = this.agentCode;
    renwalQuotationRequest.customerEmail = this.email;
    renwalQuotationRequest.expiringPolicyNumber = this.expiringPolicyNumber;

    this.portalApiService.updateOtherDetails(updateOtherDetailsRequest).subscribe(
      (response: UpdateOtherDetailsResponse) => {
        if (response?.status?.toUpperCase() === 'SUCCESS') {

          this.portalApiService.sendRenewalQuotationEmail(renwalQuotationRequest).subscribe(
            (response: Status) => {
              console.log("sendRenewalQuotationEmail status =" +  Status.toString)
          });

          this.portalApiService.initiatePayment(idCard, passport, companyTaxId, this.expiringPolicyNumber, 'N', this.paymentOption)
            .subscribe(
              (response: PaymentResponse) => {
                this.isLoading = false;
                if (response.status === 'Success') {
                  window.location.href = response.paymentAppUrl + '?uuid=' + response.uuid;
                }
              },
              (error) => {
                console.log(error);
                this.isLoading = false;
              });
        } else {
          this.isLoading = false;
          this.showAlert = true;
          this.errMsg = response.message;
        }
      },
      (error) => {
        this.isLoading  = true;
        if (error.status === 400 && error.error.code === 'VALIDATION_FAILED') {
          this.router.navigate(['']);
        } else {
          this.isLoading  = false;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  backToPreviousPage() {
    this.router.navigate(['/policy-coverage', {expiringPolicyNumber: this.expiringPolicyNumber}]);
  }

}
