<header class="pt-3" [class.Font-Thai]="!isEnglishLanguage">
    <div class="container">
      <div class="d-none d-print-block text-center pt-4">
        <img alt="InsureMyTesla" src="assets/images/header/imt-logo.png" class="img-fluid">
      </div>
      <div class="row d-print-none">
        <div class="col-4 col-md-3">
          <img alt="InsureMyTesla" src="assets/images/header/imt-logo.png" class="img-fluid imt-logo">
        </div>
        <div class="col-8 col-md-9">
          <div class="text-right mt-1 ls-point3">
            <div class="f-12 sm-f-13 md-f-14 mb-2 mr-1 clr-off-white">{{ 'header.contact' | translate }}</div>
            <div class="d-inline-block text-center mr-1 cursor-pointer" id="call_btn_container" (click)="callSupport()">
              <span class="clr-off-white rounded d-inline-block contact-icon-holder" id="call_btn_bg">
                <img src="assets/images/header/call-icon.png" class="img-fluid contact-icon-style" id="call_btn_icon">
                <span class="contact-icon-style hidden-text ml-2" id="call_btn_text">{{libertyHelplineShort}}</span>
              </span>
            </div>
            <div class="d-inline-block text-center mr-1" id="mail_btn_container">
              <span id="mail_btn_bg"
                (click)="mailSupport()" placement="bottom" popoverClass="mail-popover-class"
                class="clr-off-white rounded d-inline-block contact-icon-holder"
                style="border-radius: 1rem !important;">
                <img src="assets/images/header/mail-icon.png" id="mail_btn_icon" class="img-fluid contact-icon-style">
                <span id="mail_btn_text" class="contact-icon-style hidden-text ml-2">{{ 'header.email' | translate }}</span>
              </span>
            </div>
            <div class="d-inline-block text-center mr-1" id="mail_btn_container">
              <span id="mail_btn_bg"
                (click)="changeLanguage()" placement="bottom" popoverClass="mail-popover-class"
                class="clr-off-white rounded d-inline-block contact-icon-holder"
                style="border-radius: 1rem !important;">
                <img src="assets/images/header/language.png" style="width: 20px ; height: 20px" id="mail_btn_icon" class="img-fluid contact-icon-style">
                <span id="mail_btn_text" class="contact-icon-style hidden-text ml-2">{{ 'header.language' | translate }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
