// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'local',
  captchaV3sitekey: '6LcVrwUjAAAAAH8rdxWyIr-kp4kOY6BfzN_58kNn',
  xApiKey: 'SFVvN8u1MgZyHzfGZMxdsjtNcMBAEQhTa2b978A6oXLlUpgd',
  captchaProtectionEnabled: true,
  //apiBaseUrl : 'https://test-apis.ap-southeast-1.libertymutual.com/dev/th/imt/api/v1'
  apiBaseUrl : 'https://api-tst.ap.libertymutual.com/dev/th/imt/api/v1'
 // apiBaseUrl : 'http://localhost:8522/api/v1'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
