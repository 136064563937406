import { EventEmitter, Injectable, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../../models/language';

@Injectable({
  providedIn: 'root'
})
export class IMTTranslateService {

  @Output() onLanguageChanged = new EventEmitter();

  constructor(private translate: TranslateService) {
    translate.setDefaultLang('th');
    translate.use('th');
    
    document.querySelector('html').lang = "th";
  }

  updateLanguage(language: Language): void {
    this.translate.use(Language[language]);
    this.onLanguageChanged.emit(Language[language]);

    document.querySelector('html').lang = Language[language];
  }

  getCurrentLanguage(): string {
    return this.translate.currentLang;
  }

  getTranslation(key: string) {
    return this.translate.get(key);
  }
}
