import { Directive, HostListener, Input, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';

@Directive({
  selector: '[appFocus]'
})
export class FocusDirective {

  constructor(private el: ElementRef) { }

  @Input() formGroup: NgForm;

  @HostListener('submit', ['$event'])
  public onSubmit(event): void {
    console.log('FocusDirective submit', event);
    const invalidElements = this.el.nativeElement.querySelectorAll('.ng-invalid');
    console.log('FocusDirective invalidElements', invalidElements);
    if (invalidElements.length > 0) {
      console.log(invalidElements[0]);

      invalidElements[0].focus();
    }
  }

  @HostListener('editData', ['$event'])
  public editData(event): void {
    console.log('editData', event);
    const invalidElements = this.el.nativeElement.querySelectorAll('.ng-invalid');
    if (invalidElements.length > 0) {
      console.log(invalidElements[0]);

      invalidElements[0].focus();
      invalidElements[0].addClass('')
    }
  }
}
