import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgxCaptchaModule} from 'ngx-captcha';
import {HomeComponent} from './core/components/main/home/home.component';
import {HeaderComponent} from './core/components/main/header/header.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {OtpComponent} from './core/components/main/otp/otp.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ValidateOnBlurDirective} from './core/directives/validate-on-blur.directive';
import {PolicyListComponent} from './core/components/main/policy-list/policy-list.component';
import {PolicyCoverageComponent} from './core/components/main/policy-coverage/policy-coverage.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CarouselModule} from 'primeng-lts/carousel';
import {CustomerDetailComponent} from './core/components/main/customer-detail/customer-detail.component';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {DeviceDetectorService} from 'ngx-device-detector';
import {AuthGuard} from './core/guards/auth.guard';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {ModalModule} from 'ngx-bootstrap/modal';
import {OtpGuard} from './core/guards/otp.guard';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {OthersGuard} from './core/guards/others.guard';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {PaymentStatusComponent} from './core/components/main/payment/payment-status.component';
import { UpdatePhoneNumberComponent } from './core/components/main/update-phone-number/update-phone-number.component';
import { CustomerEditComponent } from './core/components/main/customer-edit/customer-edit.component';
import {MatRadioModule} from "@angular/material/radio";
import {MatSelectModule} from "@angular/material/select";
import {MatOptionModule} from "@angular/material/core";
import { LoadingComponent } from './core/components/main/loading/loading.component';
import {BsDatepickerModule, BsLocaleService} from "ngx-bootstrap/datepicker";
import {BsDatepickerActions} from "ngx-bootstrap/datepicker/reducer/bs-datepicker.actions";
import {FocusDirective} from "./core/directives/focus.directive";
import {DateFormatterPipe} from "./core/pipes/date-formatter.pipe";
import {HomeGuard} from "./core/guards/home.guard";
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    OtpComponent,
    ValidateOnBlurDirective,
    PolicyListComponent,
    PolicyCoverageComponent,
    CustomerDetailComponent,
    PaymentStatusComponent,
    UpdatePhoneNumberComponent,
    CustomerEditComponent,
    LoadingComponent,
    FocusDirective,
    DateFormatterPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxCaptchaModule,
    NgxSpinnerModule,
    FormsModule,
    NgbModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    CarouselModule,
    NgxWebstorageModule.forRoot(),
    HttpClientModule,
    NgxCaptchaModule,
    ModalModule.forRoot(),
    CarouselModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'th'
    }),
    MatRadioModule,
    MatSelectModule,
    MatOptionModule,
    MatIconModule,
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule
  ],
  providers: [
    HomeGuard,
    AuthGuard,
    OtpGuard,
    OthersGuard,
    DeviceDetectorService,
    DateFormatterPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
