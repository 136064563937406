  <app-header></app-header>
  <div *ngIf="!isLoading, else spinner">
    <div class="wrapper" [class.Font-Thai]="!isEnglishSelected" xmlns="http://www.w3.org/1999/html">
      <figure class="mb-4">
        <img
          alt="Tesla Cars"
          src="assets/tesla-cars-banner.png"
          class="img-fluid"
        />
      </figure>

      <div *ngIf="success && isIssuedSuccess" class="px-2">
        <h4 class="text-center">
          {{ 'payment.success.title' | translate }}
        </h4>
        <div class="text-center">
          {{ 'payment.success.firstDescription' | translate }}
        </div>
        <div class="text-center">
          {{ 'payment.success.secondDescription' | translate }}
        </div>
        <div class="text-center">
          {{ 'payment.success.thirdDescription' | translate }}
        </div>
        <br>

        <div class="text-center fw-bold">
          {{ 'payment.success.voluntaryPolicy' | translate }}
        </div>
        <div class="text-center">
          {{ paymentStatus.newAvPolicyNumber}}
        </div>
        <div class="text-center">
          <span class="fw-bold">{{ 'payment.success.effectiveDate' | translate }}:</span> {{ paymentStatus.newAvPolicyEffectiveDate}}
        </div>
        <div class="text-center">
          <span class="fw-bold">{{ 'payment.success.expiryDate' | translate }}:</span> {{ paymentStatus.newAvPolicyExpiryDate }}
        </div>

        <br>
      <div *ngIf="!status">
        <div class="text-center fw-bold">
          {{ 'payment.success.compulsoryPolicy' | translate }}
        </div>
        <div class="text-center">
          {{ paymentStatus.newAcPolicyNumber}}
        </div>
        <div class="text-center">
          <span class="fw-bold">{{ 'payment.success.effectiveDate' | translate }}:</span> {{ paymentStatus.newAvPolicyEffectiveDate}}
        </div>
        <div class="text-center">
          <span class="fw-bold">{{ 'payment.success.expiryDate' | translate }}:</span> {{ paymentStatus.newAvPolicyExpiryDate }}
        </div>
      </div>
      </div>

      <div *ngIf="!success" class="px-2">
        <h4 class="text-center">
          {{ 'payment.failed.title' | translate }}
        </h4>
        <div class="text-center">
          {{ 'payment.failed.firstDescription' | translate }}
        </div>
        <div class="text-center">
          {{ 'payment.failed.secondDescription' | translate }}
        </div>
        <br>

        <div class="col-4 col-sm-4 col-md-4 col-lg-4 mx-sm-auto text-center">
          <button class="btn btn-primary button"
                  (click)="retryPayment()"> {{ 'payment.failed.retry' | translate }}</button>
        </div>
        <br>
      </div>

      <div *ngIf="success && !isIssuedSuccess" class="px-2">
        <h4 class="text-center">
          {{ 'policyIssueStatus.title' | translate }}
        </h4>
        <div class="text-center">
          {{ 'policyIssueStatus.content' | translate }}
        </div>
        <br/>
        <ng-container *ngIf="!isFinance">
        <div class="text-center">
          {{ 'policyIssueStatus.directCase1' | translate }}
        </div>
        <div class="text-center">
          {{ 'policyIssueStatus.directCase2' | translate }}
        </div>
        </ng-container>
        <ng-container *ngIf="isFinance">
        <div class="text-center">
          {{ 'policyIssueStatus.financeCase1' | translate }}
        </div>
        <div class="text-center">
          {{ 'policyIssueStatus.financeCase2' | translate }}
        </div>
        </ng-container>
        <br>
      </div>

    </div>
  </div>
  <ng-template #spinner>
    <ngx-spinner type="ball-spin-clockwise-fade-rotating" size="medium"></ngx-spinner>
  </ng-template>
