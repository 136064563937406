<app-header></app-header>
<div *ngIf="!isLoading, else spinner">
  <div class="wrapper" [class.Font-Thai]="!isEnglishSelected" xmlns="http://www.w3.org/1999/html">
    <figure class="mb-4">
      <img
        alt="Tesla Cars"
        src="assets/tesla-cars-banner.png"
        class="img-fluid"
      />
    </figure>

    <div class="row margin-left-right-zero">
      <section class="mt-4 mb-3 col-10 col-sm-4 col-md-4 col-lg-4 mx-sm-auto">

        <div class="text-center mx-1 mx-sm-auto">
          <div>
            <h5 class="my-3 ls-1">{{ 'customer.hi' | translate }} {{name}}</h5>
          </div>
        </div>
        <div class="text-center mx-1 mx-sm-auto">
          <div>
            <p class=" my-3 ls-1">{{ 'policy.coverage.title' | translate }}</p>
          </div>
        </div>
      </section>
    </div>
    <div class="container">
      <form #offerForm="ngForm" autocomplete="off" (ngSubmit)="submit(offerForm)">
        <div class="row">
          <div class="offset-lg-3 col-lg-6">
            <div>
              <p class="text-start font-weight-bold margin-bottom-custom">{{ 'policy.renewal' | translate}}</p>
            </div>
            <div
              class="row renewal-policy-item renewal-policy-block col-12 col-md-12 form-group text-left border rounded">
              <mat-radio-group class="radio-group" [(ngModel)]="renewalType" (ngModelChange)="renewalTypeChange()"
                               name="postEmailSelected">
                <mat-radio-button class="radio-button" value="AVAC">
                  {{ 'policy.VoluntaryAndCompulsory' | translate}}
                </mat-radio-button>
                <mat-radio-button class="radio-button" value="AV">
                  {{ 'policy.VoluntaryOnly' | translate}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>

        <div class="coverage-detail-body">
          <div class="offset-lg-3 col-lg-6">
            <div class="row renewal-policy-block col-15 col-md-15 form-group text-left border rounded">
              <mat-accordion>
                <mat-expansion-panel #generalPanel hideToggle="true">
                  <mat-expansion-panel-header class="disable-ripple fw-bold mat-select-min-line ps-2 pr-3 pt-2 pb-1">
                    <mat-panel-title>
                      <div>{{ 'policy.coverage.general' | translate }}</div>
                    </mat-panel-title>
                    <mat-panel-description class="justify-content-end m-0">
                      <mat-icon *ngIf="!generalPanel.expanded">arrow_drop_down</mat-icon>
                      <mat-icon *ngIf="generalPanel.expanded">arrow_drop_up</mat-icon>
                    </mat-panel-description> 
                  </mat-expansion-panel-header>
                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div class="ps-custom-0 pb-1 text-title">{{ 'policy.coverage.coverage' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0">
                      <div class="pb-1">{{'policy.coverageName' | translate}}</div>
                    </td>
                  </tr>
                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div class="ps-custom-0 pb-1 text-title">{{ 'policy.coverage.policyType' | translate }}</div>
                    </td>
                    <ng-container *ngIf="coverageDetail?.policyType==='AV1'">
                      <td class="d-grid col-6 ps-1-25 py-0">
                        <div class="pb-1">{{'policy.coverage.policyType1' | translate}}</div>
                      </td>
                    </ng-container>
                    <ng-container *ngIf="coverageDetail?.policyType==='AV2'">
                      <td class="d-grid col-6 ps-1-25 py-0">
                        <div class="pb-1">{{'policy.coverage.policyType2' | translate}}</div>
                      </td>
                    </ng-container>
                    <ng-container *ngIf="coverageDetail?.policyType==='AV3'">
                      <td class="d-grid col-6 ps-1-25 py-0">
                        <div class="pb-1">{{'policy.coverage.policyType3' | translate}}</div>
                      </td>
                    </ng-container>
                    <ng-container *ngIf="coverageDetail?.policyType==='AV5'">
                      <td class="d-grid col-6 ps-1-25 py-0">
                        <div class="pb-1">{{'policy.coverage.policyType5' | translate}}</div>
                      </td>
                    </ng-container>
                  </tr>
                  <ng-container *ngIf="coverageDetail?.garageType==='Dealer'">
                    <tr class="row">
                      <td class="d-grid col-6 pe-1-25 py-0">
                        <div class="ps-custom-0 pb-1 text-title">{{ 'policy.coverage.garageType' | translate }}</div>
                      </td>
                      <td class="d-grid col-6 ps-1-25 py-0">
                        <div class="pb-1">{{ 'policy.coverage.dealerGarage' | translate }}</div>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="offerResponse?.policyData?.vehicleDetails?.vehicleUse==='Personal'">
                    <tr class="row">
                      <td class="d-grid col-6 pe-1-25 py-0">
                        <div class="ps-custom-0 pb-1 text-title">{{ 'policy.coverage.usage' | translate }}</div>
                      </td>
                      <td class="d-grid col-6 ps-1-25 py-0">
                        <div
                          class="pb-1 text-break">{{ 'policy.coverage.personalUse' | translate }}</div>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="offerResponse?.policyData?.vehicleDetails?.vehicleUse==='Commercial'">
                    <tr class="row">
                      <td class="d-grid col-6 pe-1-25 py-0">
                        <div class="ps-custom-0 pb-1 text-title">{{ 'policy.coverage.commercialUse' | translate }}</div>
                      </td>
                      <td class="d-grid col-6 ps-1-25 py-0">
                        <div
                          class="pb-1 text-break">{{ 'policy.coverage.usage' | translate }}</div>

                      </td>
                    </tr>
                  </ng-container>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>

        <div class="coverage-detail-body">
          <div class="offset-lg-3 col-lg-6">
            <div class="row renewal-policy-block col-15 col-md-15 form-group text-left border rounded">
              <mat-accordion>
                <mat-expansion-panel #thirdPartyCoverPanel hideToggle="true">
                  <mat-expansion-panel-header class="disable-ripple mat-select-min-line fw-bold ps-2 pr-3 pt-2 pb-1">
                    <mat-panel-title>
                      <div>{{ 'policy.coverage.thirdPartyCover' | translate }}</div>
                    </mat-panel-title>
                    <mat-panel-description class="justify-content-end m-0">
                      <mat-icon *ngIf="!thirdPartyCoverPanel.expanded">arrow_drop_down</mat-icon>
                      <mat-icon *ngIf="thirdPartyCoverPanel.expanded">arrow_drop_up</mat-icon>
                    </mat-panel-description> 
                  </mat-expansion-panel-header>
                  <tr class="row">
                    <td class="d-grid col-6 ps-1-25 py-0">
                      <div class="pb-1"></div>
                    </td>
                  </tr>
                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div class="ps-custom-0 pb-1 text-title">{{ 'policy.coverage.bodyInjuryOrDeath' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0">
                      <div class="d-flex flex-wrap">
                        <div class="col-6 text-end px-0">
                          <div class="text-break">
                            {{ coverageDetail?.coverageBreakdown?.limitTPBI || 0 | number}}
                          </div>
                        </div>
                        <div class="col-6 px-0 text-end text-break unit-text-small ps-1">
                          {{ 'common.unit.bahtPerPerson' | translate}}
                        </div>
                        <div class="col-6 text-end px-0">
                          <div class="pb-1 text-break">
                            {{ coverageDetail?.coverageBreakdown?.limitDeathOverCTOL || 0 | number}}
                          </div>
                        </div>
                        <div class="col-6 px-0 text-end text-break unit-text-small ps-1">
                          {{ 'common.unit.bahtPerTime' | translate}}
                        </div>
                      </div>
                      <!-- </div> -->
                    </td>
                  </tr>
                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div class="ps-custom-0 pb-1 text-title">{{ 'policy.coverage.propertyDamage' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0">
                      <div class="d-flex flex-wrap">
                        <div class="col-6 text-end px-0">
                          <div class="pb-1 text-break">
                            {{ coverageDetail?.coverageBreakdown?.limitTPPD || 0 | number}}
                          </div>
                        </div>
                        <div class="col-6 px-0 text-end text-break unit-text-small ps-1">
                          {{ 'common.unit.bahtPerTime' | translate}}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div class="ps-3 pb-1 text-title">{{ 'policy.coverage.propertyDamageDeductible' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0">
                      <div class="d-flex flex-wrap">
                        <div class="col-6 text-end px-0">
                          <div class="pb-1 text-break">
                            {{ coverageDetail?.coverageBreakdown?.limitODDeductible || 0 | number }}
                          </div>
                        </div>
                        <div class="col-6 px-0 text-end text-break unit-text-small ps-1">
                          {{ 'common.unit.bahtPerTime' | translate}}
                        </div>
                      </div>
                    </td>
                  </tr>

                </mat-expansion-panel>
              </mat-accordion>

            </div>
          </div>
        </div>

        <div class="coverage-detail-body">
          <div class="offset-lg-3 col-lg-6">
            <div class="row renewal-policy-block col-15 col-md-15 form-group text-left border rounded">
              <mat-accordion>
                <mat-expansion-panel #ownDamagePanel hideToggle="true">
                  <mat-expansion-panel-header class="disable-ripple mat-select-min-line fw-bold ps-2 pr-3 pt-2 pb-1">
                    <mat-panel-title>
                      <div>{{ 'policy.coverage.ownDamage' | translate }}</div>
                    </mat-panel-title>
                    <mat-panel-description class="justify-content-end m-0">
                      <mat-icon *ngIf="!ownDamagePanel.expanded">arrow_drop_down</mat-icon>
                      <mat-icon *ngIf="ownDamagePanel.expanded">arrow_drop_up</mat-icon>
                    </mat-panel-description> 
                  </mat-expansion-panel-header>
                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div class="ps-custom-0 pb-1 text-title">{{ 'policy.coverage.ownDamageCoverage' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0">
                      <div class="d-flex flex-wrap">
                        <div class="col-6 text-end px-0">
                          <div class="pb-1 text-break">
                            {{ coverageDetail?.coverageBreakdown?.limitOwnDamage || 0 | number }}
                          </div>
                        </div>
                        <div class="col-6 px-0 text-end text-break unit-text-small ps-1">
                          {{ 'common.unit.bahtPerTime' | translate}}
                        </div>
                      </div>
                    </td>
                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div class="ps-3 pb-1 text-title">{{ 'policy.coverage.ownDamageCoverageDeductible' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0">
                      <div class="d-flex flex-wrap">
                        <div class="col-6 text-end px-0">
                          <div class="pb-1 text-break">
                            {{ coverageDetail?.coverageBreakdown?.limitODDeductible || 0 | number }}
                          </div>
                        </div>
                        <div class="col-6 px-0 text-end text-break unit-text-small ps-1">
                          {{ 'common.unit.bahtPerTime' | translate}}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div class="ps-custom-0 pb-1 text-title">{{ 'policy.coverage.lostOrFireDamage' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0">
                      <div class="d-flex flex-wrap">
                        <div class="col-6 text-end px-0">
                          <div class="pb-1 text-break">
                            {{ coverageDetail?.coverageBreakdown?.limitFireTheft || 0 | number }}
                          </div>
                        </div>
                        <div class="col-6 px-0 text-end text-break unit-text-small ps-1">
                          {{ 'common.unit.bahtPerTime' | translate}}
                        </div>
                      </div>
                    </td>
                  </tr>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>

        <div class="coverage-detail-body">
          <div class="offset-lg-3 col-lg-6">
            <div class="row renewal-policy-block col-15 col-md-15 form-group text-left border rounded">
              <mat-accordion>
                <mat-expansion-panel #additionalCoveragePanel hideToggle="true">
                  <mat-expansion-panel-header class="disable-ripple mat-select-min-line fw-bold ps-2 pr-3 pt-2 pb-1">
                    <mat-panel-title>
                      <div>{{ 'policy.coverage.additionalCoverage' | translate }}</div>
                    </mat-panel-title>
                    <mat-panel-description class="justify-content-end m-0">
                      <mat-icon *ngIf="!additionalCoveragePanel.expanded">arrow_drop_down</mat-icon>
                      <mat-icon *ngIf="additionalCoveragePanel.expanded">arrow_drop_up</mat-icon>
                    </mat-panel-description> 
                  </mat-expansion-panel-header>
                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div
                        class="ps-custom-0 pb-1 text-break text-title">{{ 'policy.coverage.personalAccident' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0">
                      <div class="d-flex flex-wrap">
                        <div class="col-6 text-end px-0">
                          <div class="pb-1 text-break">
                            {{ coverageDetail?.coverageBreakdown?.limitMedicalExpensePersons || 0 | number }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div class="ps-3 pb-1 text-title">{{ 'policy.coverage.personalAccidentDisability' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0 ">
                      <div class="d-flex flex-wrap">
                        <div class="col-6 text-end px-0">
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div
                        class="ps-custom-2 pb-1 d-grid text-title">{{ 'policy.coverage.personalAccidentDisabilityDriver' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0">
                      <div class="d-flex flex-wrap">
                        <div class="col-6 text-end px-0">
                          <div class="pb-1 text-break">
                            {{ coverageDetail?.coverageBreakdown?.limitPADriver || 0 | number }}
                          </div>
                        </div>
                      </div>
                      <!-- </div> -->
                    </td>
                  </tr>
                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div
                        class="ps-custom-2 pb-1 text-title">{{ 'policy.coverage.personalAccidentDisabilityPassenger' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0">
                      <div class="d-flex flex-wrap">
                        <div class="col-6 text-end px-0">
                          <div class="pb-1 text-break">
                            {{ coverageDetail?.coverageBreakdown?.limitPAPassengers || 0 | number }}
                          </div>
                        </div>
                        <div class="col-6 text-end px-0">
                          <div class="pb-1 text-break unit-text-small ps-1">
                            {{ 'common.unit.bahtPerPerson' | translate}}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div
                        class="ps-3 pb-1 text-title">{{ 'policy.coverage.personalAccidentTemporaryDisability' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0 ">
                      <div class="d-flex flex-wrap">
                        <div class="col-6 text-end px-0">
                        </div>
                      </div>
                      <!-- </div> -->
                    </td>
                  </tr>
                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div
                        class="ps-custom-2 pb-1 text-title">{{ 'policy.coverage.personalAccidentDisabilityDriver' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0">
                      <div class="d-flex flex-wrap">
                        <div class="col-6 text-end px-0">
                          <div class="pb-1 text-break">
                            {{ coverageDetail?.coverageBreakdown?.limitTotalDisabilityPerWeekDriver || 0 | number }}
                          </div>
                        </div>
                        <div class="col-6 text-end px-0">
                          <div class="pb-1 text-break unit-text-small ps-1">
                            {{ 'common.unit.bahtPerWeek' | translate}}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div
                        class="ps-custom-2 pb-1 text-title">{{ 'policy.coverage.personalAccidentTemporaryDisabilityPassenger' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0">
                      <div class="d-flex flex-wrap">
                        <div class="col-6 text-end px-0">
                          <div class="pb-1 text-break">
                            {{ coverageDetail?.coverageBreakdown?.limitTotalDisabilityPerWeekPassenger || 0 | number }}
                          </div>
                        </div>
                        <div class="col-6 text-end px-0 ps-1">
                          <div class="pb-1 text-break unit-text-small">
                            {{ 'common.unit.bahtPerPersonPerWeek' | translate}}
                          </div>
                        </div>
                      </div>
                      <!-- </div> -->
                    </td>
                  </tr>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>

        <div class="coverage-detail-body">
          <div class="offset-lg-3 col-lg-6">
            <div
              class="row renewal-policy-block renewal-policy-item-medical-expense-bail-bond col-15 col-md-15 form-group text-left border rounded">
              <tr class="row">
                <td class="d-grid col-6 pe-1-25 py-0">
                  <div class="ps-2 pb-1 fw-bold">{{ 'policy.coverage.medicalExpense' | translate }}</div>
                </td>
                <td class="d-grid col-6 ps-1-25 py-0">
                  <div class="d-flex flex-wrap">
                    <div class="col-12 text-end px-0">
                      <div class="pb-1 text-break">
                        {{ coverageDetail?.coverageBreakdown?.limitMedicalExpense || 0 | number }}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>

            </div>
          </div>
        </div>

        <div class="coverage-detail-body">
          <div class="offset-lg-3 col-lg-6">
            <div
              class="row renewal-policy-block renewal-policy-item-medical-expense-bail-bond col-15 col-md-15 form-group text-left border rounded">
              <tr class="row">
                <td class="d-grid col-6 pe-1-25 py-0">
                  <div class="ps-2 pb-1 fw-bold">{{ 'policy.coverage.bailBond' | translate }}</div>
                </td>
                <td class="d-grid col-6 ps-1-25 py-0">
                  <div class="d-flex flex-wrap">
                    <div class="col-12 text-end px-0">
                      <div class="pb-1 text-break">
                        {{ coverageDetail?.coverageBreakdown?.limitBailBond || 0 | number }}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>

            </div>
          </div>
        </div>

        <div class="coverage-detail-body">
          <div class="offset-lg-3 col-lg-6">
            <div class="row renewal-policy-block col-15 col-md-15 form-group text-left border rounded">
              <mat-accordion>
                <mat-expansion-panel #premiumDetailsPanel hideToggle="true">
                  <mat-expansion-panel-header class="disable-ripple mat-select-min-line fw-bold ps-2 pr-3 pt-2 pb-1">
                    <mat-panel-title>
                      <div>{{'policy.coverage.premiumDetails' | translate }}</div>
                    </mat-panel-title>
                    <mat-panel-description class="justify-content-end m-0">
                      <mat-icon *ngIf="!premiumDetailsPanel.expanded">arrow_drop_down</mat-icon>
                      <mat-icon *ngIf="premiumDetailsPanel.expanded">arrow_drop_up</mat-icon>
                    </mat-panel-description> 
                  </mat-expansion-panel-header>
                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div class="ps-custom-0 pb-1 text-title">{{ 'policy.coverage.netPremiumAV' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0">
                      <div class="d-flex flex-wrap">
                        <div class="col-12 text-end px-0">
                          <div class="pb-1 text-break">
                            {{ coverageDetail?.premiumBreakdown?.netPremium || 0 | number }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div class="ps-custom-0 pb-1 text-title">{{ 'policy.coverage.stampDuty' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0">
                      <div class="d-flex flex-wrap">
                        <div class="col-12 text-end px-0">
                          <div class="pb-1 text-break">
                            {{ coverageDetail?.premiumBreakdown?.stampDuty || 0 | number }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div class="ps-custom-0 pb-1 text-title">{{ 'policy.coverage.vat' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0">
                      <div class="d-flex flex-wrap">
                        <div class="col-12 text-end px-0">
                          <div class="pb-1 text-break">
                            {{ coverageDetail?.premiumBreakdown?.valueAddedTax || 0 | number }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div class="ps-custom-0 pb-1 text-title">{{ 'policy.coverage.totalPremiumAV' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0">
                      <div class="d-flex flex-wrap">
                        <div class="col-12 text-end px-0">
                          <div class="pb-1 text-break">
                            {{ coverageDetail?.premiumBreakdown?.grossPremium || 0 | number }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div class="ps-custom-0 pb-1 text-title">{{ 'policy.coverage.totalPremiumAC' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0">
                      <div class="d-flex flex-wrap">
                        <div class="col-12 text-end px-0">
                          <div class="pb-1 text-break">
                            {{ compulsoryGrossPremium }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr class="row">
                    <td class="d-grid col-6 pe-1-25 py-0">
                      <div class="ps-custom-0 pb-1 text-title">{{ 'policy.coverage.grandTotalPremium' | translate }}</div>
                    </td>
                    <td class="d-grid col-6 ps-1-25 py-0">
                      <div class="d-flex flex-wrap">
                        <div class="col-12 text-end px-0">
                          <div class="pb-3 text-break">
                            {{ getGrandTotalPremium(coverageDetail?.premiumBreakdown?.grossPremium, coverageDetail?.premiumBreakdown?.compulsoryGrossPremium) || 0 | number:'1.2-2' }}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
        <div class="coverage-detail-body">
          <div class="offset-lg-3 col-lg-6">
            <div class="row renewal-policy-block renewal-policy-item-medical-expense-bail-bond col-15 col-md-15 form-group text-left border rounded">

              <td class="d-grid col-6 pe-1-25 py-0">
                <div class="ps-2 pb-1 fw-bold md-f-17">{{ 'policy.coverage.renewalPolicyPrice' | translate }}</div>
              </td>
              <ng-container *ngIf="renewalType==='AVAC'">
                <div class="renewal-policy-price">
                  <p class="renewal-policy-price-header ps-2">{{ 'policy.coverage.voluntaryAndCompulsory' | translate}}</p>
                  <p class="renewal-policy-price-content pr-2">{{ grandTotalPremium || 0 | number:'1.2-2' }} {{ 'customer.baht' | translate}}</p>
                </div>
              </ng-container>
              <ng-container *ngIf="renewalType==='AV'">
                <div class="renewal-policy-price">
                  <p class="renewal-policy-price-header ps-2">{{ 'policy.coverage.voluntaryOnly' | translate}}</p>
                  <p class="renewal-policy-price-content pr-2">
                    {{ offerResponse?.policyData?.coverageDetails?.premiumBreakdown?.grossPremium || 0 | number }} {{ 'customer.baht' | translate}}
                  </p>
                </div>
              </ng-container>
            </div>
            <b>{{'policy.coverage.policyAnnouncement' | translate}}<a href="javascript:void(0);" (click)="openModal()">{{'policy.coverage.readMore' | translate}}</a></b>
            <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}">
              <div class="modal-dialog" style="padding: 0px 10px;" role="document">
                <div class="modal-content">
                  <div class="modal-body padding-zero modal-document">
                    <button type="button" class="close" aria-label="Close" (click)="onCloseHandled()"><span
                      aria-hidden="true">&times;</span></button>
                    <img
                      src="assets/evcar.png"
                      style="max-width:100%;cursor:pointer"
                      class="modal-hover-opacity"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row margin-left-right-zero align-items-center p-0">
          <div class="text-center d-flex align-items-center justify-content-center p-3">
            <button type="button" (click)="backToPreviousPage()" id="desktop-back_btn"
                    class="btn btn-outline-primary button br-60 submit_button m-3">
              {{ 'common.button.back' | translate }}
            </button>
            <button type="submit" id="desktop-submit_btn"
                    class="btn btn-primary button br-60 disable-button submit_button m-3">
              {{ 'common.button.next' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #spinner>
  <ngx-spinner type="ball-spin-clockwise-fade-rotating" size="medium"></ngx-spinner>
</ng-template>
