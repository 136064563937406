import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {CommonService} from 'src/app/core/services/common/common.service';
import {Alert} from 'src/app/core/interfaces/alert';
import {GoogleCaptchaService} from 'src/app/core/services/google/google-captcha.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidationService} from 'src/app/core/services/validation/validation.service';
import {StorageService} from '../../../services/storage/storage.service';
import {PortalApiService} from '../../../services/rest-api/portal-api.service';
import {AuthService} from '../../../services/auth/auth.service';
import {IMTTranslateService} from 'src/app/core/services/common/imt.translation.service';
import {environment} from "../../../../../environments/environment";
import {AppConstants} from "../../../constants/app-constants.model";
import {OTPResponse, ValidateCustomerResponse} from "../../../models/response.model";
import {StorageType} from "../../../enum/storage-type.enum";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  alert: Alert;
  showAlert = false;
  enableEng = false;
  footer: any = {};
  idCard: string;
  disableSubmitBtn = false;
  otpExpiryTs: string;
  isLoading: boolean = false;
  maskPhoneNumber: string = '';
  constructor(
    private storageService: StorageService,
    private portalApiService: PortalApiService,
    private router: Router,
    private commonService: CommonService,
    private googleCaptchaService: GoogleCaptchaService,
    private spinner: NgxSpinnerService,
    private validationService: ValidationService,
    private imtTranslationService: IMTTranslateService,
    private authService: AuthService
  ) {
    this.spinner.show();
    this.setDefaults();
  }
  setDefaults(): void {
  }


  ngOnInit(): void {
    this.authService.clear();
    window.scrollTo({ top: 0 });

    this.subscription = this.googleCaptchaService
      .getVerificationStatus()
      .subscribe((status: boolean) => {
        if (status) {
          console.log("ngOnInit() ", status);
          this.authenticateUser();
        } else {
          this.alert = {
            type: 'danger',
            messageEn: 'Bot Attack Identified. Cannot Proceed.',
            messageTh: 'Bot Attack Identified. Cannot Proceed.',
            templateEn: '',
            templateTh: ''
          };
          this.showAlert = true;
          this.disableSubmitBtn = false;
        }
      });
  }

  submit(customerForm: NgForm): void {
    console.log("submit(vehicleForm: NgForm): void");
    this.showAlert = false;

    if (this.validationService.checkFormValidity(customerForm)) {
      this.disableSubmitBtn = true;
      this.isLoading = true;

      if (environment.captchaProtectionEnabled) {
        this.googleCaptchaService.validate(
          AppConstants.CAPTCHA_ACTION_HOMEPAGE
        );
      } else {
        this.authenticateUser();
      }
    }
  }

  authenticateUser(): void {
    const referenceNo = this.randomString(4);
    this.portalApiService
      .authenticateUser(this.idCard, '', '')
      .subscribe((response: ValidateCustomerResponse) => {
        if (response) {
          console.log('response', response);
          if (response.status === 'Success' && response.code === '200') {
            this.authService.authenticate();
            if (response?.phoneNumberAvailable) {
              this.portalApiService.sendOtp(this.idCard, '', referenceNo, '').subscribe( (body: OTPResponse) => {
                this.otpExpiryTs = body?.otpExpiryTs;
                this.portalApiService.updateCustomerAuthenticatedAsync(this.idCard,this.idCard, this.idCard, false)
                  .subscribe(response => {
                    this.isLoading = false;
                    this.maskPhoneNumber = body?.maskPhoneNumber;
                    this.router.navigate(['/otp', {reference: referenceNo, otpExpiryTs: this.otpExpiryTs, maskPhoneNumber: this.maskPhoneNumber}]);
                  });
              });
            } else {
              this.isLoading = false;
              console.log('update-phone-number');
              this.router.navigate(['/update-phone-number']);
            }
            // check passport
          } else {
            this.portalApiService
              .authenticateUser('', this.idCard, '')
              .subscribe((response: ValidateCustomerResponse) => {
                if (response) {
                  console.log('response', response);
                  if (response.status === 'Success' && response.code === '200') {
                    this.authService.authenticate();
                    if (response?.phoneNumberAvailable) {
                      this.portalApiService.sendOtp('', this.idCard, referenceNo, '').subscribe( (body: OTPResponse) => {
                        this.otpExpiryTs = body?.otpExpiryTs;
                        this.maskPhoneNumber = body?.maskPhoneNumber;
                        this.isLoading = false;
                        this.router.navigate(['/otp', {reference: referenceNo, otpExpiryTs: this.otpExpiryTs, maskPhoneNumber: this.maskPhoneNumber}]);
                      });
                    } else {
                      this.isLoading = false;
                      console.log('update-phone-number');
                      this.router.navigate(['/update-phone-number']);
                    }
                  } else {
                    // check companyTaxId
                    this.portalApiService
                      .authenticateUser('', '', this.idCard)
                      .subscribe((response: ValidateCustomerResponse) => {
                        if (response) {
                          console.log('response', response);
                          if (response.status === 'Success' && response.code === '200') {
                            this.authService.authenticate();
                            if (response?.phoneNumberAvailable) {
                              this.portalApiService.sendOtp('', '', referenceNo, this.idCard).subscribe( (body: OTPResponse) => {
                                this.otpExpiryTs = body?.otpExpiryTs;
                                this.maskPhoneNumber = body?.maskPhoneNumber;
                                this.isLoading = false;
                                this.router.navigate(['/otp', {reference: referenceNo, otpExpiryTs: this.otpExpiryTs, maskPhoneNumber: this.maskPhoneNumber}]);
                              });
                            } else {
                              this.isLoading = false;
                              console.log('update-phone-number');
                              this.router.navigate(['/update-phone-number']);
                            }
                          } else {
                            this.handleAuthFailure();
                          }
                        } else {
                          this.handleAuthFailure();
                        }
                      }, err => {
                        this.handleAuthFailure()
                      })
                  }
                } else {
                  this.handleAuthFailure();
                }
              }, err => {
                this.handleAuthFailure()
              })
          }
        } else {
          this.handleAuthFailure();
        }
      },
        err => {
          this.handleAuthFailure();
        }
        );
  }

  private handleAuthFailure(): void {
    this.showAlert = true;
    this.disableSubmitBtn = false;
    this.isLoading = false;
  }

  closeAlert(alert: Alert): void {
    this.showAlert = false;
  }

  randomString(length): string {
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  isEnglishSelected() {
    return 'en' === this.imtTranslationService.getCurrentLanguage();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.spinner.hide();
  }
}
