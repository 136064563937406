<app-header></app-header>
<div *ngIf="!isLoading, else spinner">
<div class="wrapper" [class.Font-Thai]="!isEnglishSelected">
  <figure class="mb-4">
    <img alt="Tesla Cars" src="assets/tesla-cars-banner.png" class="img-fluid">
  </figure>

  <div class="row">
    <div class="col-10 col-sm-4 col-md-4 col-lg-4 mx-sm-auto">
      <section class="mt-4 mb-3">

        <div class="text-center mx-1 mx-sm-auto">
          <div>
            <h5 class="my-3 ls-1">{{ 'updatePhoneNumber.title' | translate}}</h5>
          </div>
        </div>
        <div class="text-center mx-1 mx-sm-auto">
          <div>
            <p class=" my-3 ls-1">{{ 'updatePhoneNumber.description' | translate }}</p>
          </div>
        </div>
      </section>

      <section class="mb-4">
        <div class="main-container  text-center mx-1 mx-sm-auto">
          <form #updatePhoneNumberForm="ngForm" autocomplete="off" (ngSubmit)="submit(updatePhoneNumberForm)">
            <div *ngIf="showAlert" class="mt-3 mb-4">
              <ngb-alert [type]="'custom'" class="alert-home-custom">
                <ng-container>
                  <div class="input-error">
                    {{ 'updatePhoneNumber.incorrectValidation' | translate}}
                  </div>
                </ng-container>
              </ngb-alert>
            </div>
            <div class="row my-3 ls-1">
              <p class=" my-3 ls-1">{{ 'updatePhoneNumber.phone.phoneNo' | translate}}</p>
              <div class="col-12 col-md-12 form-group">
                <div class="select-toggle p-relative">
                  <input type="text" class="form-control text-center" id="phoneNo" name="phoneNo" #phoneNoControl="ngModel"
                         [(ngModel)]="phoneNo" required pattern="^0[0-9]{9}$"  [appValidateOnBlur]="phoneNoControl">
                </div>
                <ng-container *ngIf="phoneNoControl.touched && phoneNoControl?.errors">
                  <div class="input-error" *ngIf="phoneNoControl.invalid">
                    {{ 'updatePhoneNumber.phone.specialCharacter' | translate}}
                  </div>
                </ng-container>
              </div>

              <p class=" my-3 ls-1">{{ 'updatePhoneNumber.vin.chassisNo' | translate}}</p>
              <div class="col-12 col-md-12 form-group">
                <div class="select-toggle p-relative">
                  <input type="text" class="form-control text-center" id="vehicleNumber" name="vehicleNumber" #vehicleNumberControl="ngModel"
                         [(ngModel)]="vehicleNumber" [required]="true" maxlength="25" pattern="^[A-Z0-9]{15,25}$">
                </div>
                <ng-container *ngIf="vehicleNumberControl.touched && vehicleNumberControl?.errors">
                  <div class="input-error" *ngIf="vehicleNumberControl.invalid">
                    {{ 'updatePhoneNumber.vin.specialCharacter' | translate}}
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="row mt-4">
              <div class="d-flex align-items-center justify-content-center mx-sm-auto form-group">
                <button (click)="backToPreviousPage()" id="back_btn" type="button"
                  class="btn btn-outline-secondary button br-60 submit_button m-3">
                  {{ 'common.button.back' | translate }}
                </button>
                <button type="submit" id="submit_btn" [disabled]="!phoneNoControl.valid || !vehicleNumberControl.valid || disableSubmitBtn"
                        class="btn btn-primary button br-60 disable-button submit_button m-3">
                  {{ 'common.button.submit' | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</div>
</div>
  <ng-template #spinner>
    <ngx-spinner type="ball-spin-clockwise-fade-rotating" size="medium"></ngx-spinner>
  </ng-template>
