import { Injectable } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { CLIENT_SERVER_ERRORCODES_DATA_MAP, CLIENT_SERVER_FIELDNAMES_DATA_MAP } from '../../data/client-server-data-error-map';
import { StatusDesc } from '../../models/response.model';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  clientServerFieldnamesMap: Map<string, string>;
  clientServerErrorcodesMap: Map<string, string>;

  constructor() {
    this.clientServerFieldnamesMap = CLIENT_SERVER_FIELDNAMES_DATA_MAP;
    this.clientServerErrorcodesMap = CLIENT_SERVER_ERRORCODES_DATA_MAP;
  }

  checkFormValidity(myForm: NgForm): boolean {
    if (myForm.invalid) {
      for (const control in myForm.controls) {
        if (myForm.controls[control].invalid) {
          console.log("Invalid Control: ", control);
        }
        if (control != null) {
          myForm.controls[control].markAsTouched();
        }
      }
      return false;
    }
    return true;
  }

  checkModelValidity(myModel: NgModel): boolean {
    if (myModel.invalid) {
      myModel.control.markAsTouched();
      return false;
    }
    return true;
  }

  getErrorList(statusDescription: StatusDesc[]): string[] {
    const errorList: string[] = [];
    if (statusDescription && statusDescription.length > 0) {
      statusDescription.forEach((statusDesc) => {
        if (this.clientServerFieldnamesMap.has(statusDesc.fieldName) && this.clientServerErrorcodesMap.has(statusDesc.errorCode)) {
          errorList.
             push(`${this.clientServerFieldnamesMap.get(statusDesc.fieldName)}${this.clientServerErrorcodesMap.get(statusDesc.errorCode)}`);
        }
      });
    }
    return errorList;
  }
}
